import CloseIcon from '@mui/icons-material/Close';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { ButtonsPrimary, ButtonsTertiary } from '../ComponentApp/buttoms/ButtonsXcala';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const DialogBox = ({
  open,
  setOpen,
  className,
  modal_title,
  modal_content,
  action_btn2,
  action_btn,
  handleBtn,
}) => {
  const [checked, setCheck] = React.useState(false);

  const onChange = () => {
    setCheck(!checked);
  };

  const handleClose = () => {
    setCheck(false);
    setOpen(false);
  };
  const [scroll, setScroll] = React.useState('paper');
  return (
    <>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'>
        <DialogTitle
          sx={{ m: 0, p: 2, fontSize: '1.2rem', color: '#182849' }}
          id='customized-dialog-title'>
          {modal_title}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 26,
          }}>
          <CloseIcon />
        </IconButton>

        <DialogContent dividers={scroll === 'paper'}>
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
            {action_btn2 && <Checkbox checked={checked} onChange={onChange} />}
            <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: '300', color: '#182849' }}>
              <span dangerouslySetInnerHTML={{ __html: modal_content }} />
            </Typography>
          </Stack>
        </DialogContent>

        <DialogActions>
          {action_btn2 && <ButtonsTertiary onClick={handleClose} name={action_btn2} />}
          <ButtonsPrimary
            name={action_btn}
            onClick={action_btn2 ? handleBtn : handleClose}
            disabled={action_btn2 ? !checked : false}
            className='button-primary'
          />
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default DialogBox;
