import { Stack, styled } from '@mui/material';

export const BackgroundDashboard = styled(Stack)({
  background: 'url(assets/images/dashboard/Background-fondo.png)',
  backgroundOrigin: 'top',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  top: '0',
  right: '0',
  width: 'calc(100%-300px)',
  objectFit: 'cover',
  alignItems: 'center',
  gap: '16px',
  margin: '0px',
  padding: '0px',
});
