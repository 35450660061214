import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetSecurityPriceRangePortfolio } from '../../hooks/useGetSecurityPriceRangePortfolio';
import { getCurrentDateFormatMMDDYYYY, setMonthsDateFormatMMDDYYYY } from '../../utils/dates';
import Spinner from '../Spinner';
import Charts from '../products/Chart';

const ChartComponentInvestmentContainer = () => {
  const [active, setActive] = useState(12);
  const [dataCharts, setDataCharts] = useState({
    data: [],
    dividends: null,
  });

  const portfolioSelectedById = useSelector(state => state.portfolio.portfolioSelected);

  const data = {
    accountId: portfolioSelectedById,
    endDate: getCurrentDateFormatMMDDYYYY(),
    startDate: setMonthsDateFormatMMDDYYYY(-active).replaceAll('-', '/'),
  };

  const {
    data: dataGetSecurityPriceRangePortfolio,
    error: errorGetSecurityPriceRangePortfolio,
    loading: loadingGetSecurityPriceRangePortfolio,
    refetch,
  } = useGetSecurityPriceRangePortfolio({
    data: data,
  });

  const successGetSecurityPriceRangePortfolio =
    dataGetSecurityPriceRangePortfolio?.getSecurityPriceRangePortfolio;

  const onChangeMonths = months => {
    setActive(months);

    const data = {
      securityId: portfolioSelectedById,
      startDate: setMonthsDateFormatMMDDYYYY(-months).replaceAll('-', '/'),
      endDate: getCurrentDateFormatMMDDYYYY(),
    };
    refetch({
      variables: { data },
    });
  };

  useEffect(() => {
    onChangeMonths(12);
  }, []);

  useEffect(() => {
    if (successGetSecurityPriceRangePortfolio && !loadingGetSecurityPriceRangePortfolio) {
      const dataOrdered = successGetSecurityPriceRangePortfolio
        .map(item => {
          return {
            ...item,
            ClosePrice: item.closePrice,
            EffectiveDate: new Date(item.effectiveDate),
          };
        })
        .sort((a, b) => new Date(b.EffectiveDate) - new Date(a.EffectiveDate));
      const dividendsFiltered = dataOrdered.filter(item => item.dividend > 0);

      const dividends = dividendsFiltered.map(item => {
        return {
          quotaFactor: item.dividend,
          provisionDate: new Date(item.effectiveDate),
        };
      });

      setDataCharts({
        data: dataOrdered,
        dividends: dividends,
      });
    }
  }, [refetch, loadingGetSecurityPriceRangePortfolio]);

  if (errorGetSecurityPriceRangePortfolio) {
  }

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        padding: '8px',
      }}>
      <CardHeader
        title={
          <Typography
            sx={{
              fontSize: '24px',
            }}>
            Evolución del Portfolio
          </Typography>
        }
      />
      <CardContent
        sx={{
          padding: '4px',
        }}>
        <Box display='flex' flexDirection='row' sx={{ ml: 1 }}>
          <ButtonGroup
            sx={{
              width: '100%',
              '& .MuiButton-outlined': {
                borderColor: '#1E22AA',
                '&:hover': {
                  borderColor: '#1E22AA',
                },
                '&:focus': {
                  borderColor: '#1E22AA',
                },
              },
            }}
            variant='outlined'
            aria-label='outlined primary button group'
            size='small'>
            <Button
              onClick={() => onChangeMonths(12)}
              style={{
                backgroundColor: active === 12 ? '#1E22AA' : 'initial',
                color: active === 12 ? 'white' : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              12 meses
            </Button>
            <Button
              onClick={() => onChangeMonths(3)}
              style={{
                backgroundColor: active === 3 ? '#1E22AA' : 'initial',
                color: active === 3 ? 'white' : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              3 meses
            </Button>
            <Button
              onClick={() => onChangeMonths(1)}
              style={{
                backgroundColor: active === 1 ? '#1E22AA' : 'initial',
                color: active === 1 ? 'white' : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              1 mes
            </Button>
          </ButtonGroup>
        </Box>
      </CardContent>

      <CardContent
        sx={{
          height: '100%',
          maxHeight: '425px',
          minHeight: { md: '', xs: '300px' },
        }}>
        {loadingGetSecurityPriceRangePortfolio ? (
          <Spinner />
        ) : (
          <>
            <Charts
              productdetail={'productdetail'}
              data={dataCharts.data}
              dividends={dataCharts.dividends}
              serieName={'Saldo'}
              showLabels={true}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};
export default ChartComponentInvestmentContainer;
