import { Stack, Typography, styled } from '@mui/material';

const ContainerDivider = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  alignItems: {
    xs: 'center',
    ms: 'center',
    md: 'flex-start',
    lg: 'flex-start',
  },
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
  marginTop: '20px',
});
const TitleDividerContent = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: '627px',
  margin: '0px',
});

const IconSectionDivider = styled('img')({
  height: '20px',
  width: '20px',
  margin: '0px',
  marginRight: '16px',
});

const TextSectionFoundSpan = styled(Typography)({
  whiteSpace: 'pre-wrap',
  color: 'rgba(16, 24, 32, 1)',
  fontStyle: 'normal',
  fontFamily: 'Museo',
  fontWeight: '300',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
});

const TextSectionFoundSpanResalt = styled(Typography)({
  whiteSpace: 'pre-wrap',
  color: 'rgba(30, 34, 170, 1)',
  fontStyle: 'normal',
  fontFamily: 'Museo',
  fontWeight: '300',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
});

const DivideSectionText = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
  margin: '17px 0px 0px 0px',
  height: '14px',
});

const TextPromo = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(128, 128, 128, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '20px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: '100%',
  margin: '0px',
  flex: '1 0',
});

const ContentTime = styled(Typography)({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(128, 128, 128, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '14px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: '100%',
  margin: '0px',
  flex: '1 1',
});

export const DividerSectionText = () => {
  return (
    <ContainerDivider>
      <TitleDividerContent
        justifyContent={{
          xs: 'center',
          ms: 'center',
          md: 'flex-start',
          lg: 'flex-start',
        }}>
        <IconSectionDivider src='/assets/images/icon-section.svg' alt='icon-section' />
        <TextSectionFoundSpan component={'p'} fontSize={'clamp(0.6em, 4vw, 1.4em)'}>
          Te proponemos estas opciones{' '}
          <TextSectionFoundSpanResalt component={'span'} fontSize={'clamp(0.6em, 4vw, 1.3em)'}>
            para ti
          </TextSectionFoundSpanResalt>
        </TextSectionFoundSpan>
      </TitleDividerContent>
      <DivideSectionText
        flexDirection={{ xs: 'column', ms: 'column', md: 'row', lg: 'row' }}
        justifyContent={{
          xs: 'center',
          ms: 'center',
          md: 'flex-start',
          lg: 'flex-start',
        }}
        alignItems={{
          xs: 'center',
          ms: 'center',
          md: 'flex-start',
          lg: 'flex-start',
        }}
        mt={{ xs: '50px', ms: '0px', md: '0px', lg: '0px' }}
        spacing={{ xs: '0px', ms: '0px', md: '0px', lg: '0px' }}
        sx={{ marginBottom: '50px', marginTop: '0px' }}>
        <TextPromo>En Xcala, invierte como los que saben</TextPromo>
      </DivideSectionText>
    </ContainerDivider>
  );
};
