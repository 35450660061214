import { Box, IconButton, Stack, Tooltip, Typography, styled, useMediaQuery } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../../../../redux/auth';
import {
  Avatar,
  ButtonAcount,
  Icon,
  SupportingText,
  Text,
  TextEmailTooltip,
  TextSection,
  TextUserTooltip,
  TooltipContent,
} from './StyledComponentsMenu';

const AccountContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 8px',
  boxSizing: 'border-box',
});

const StackUser = styled(Stack)({
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '8px',
});

const BoxAvatar = styled(Box)(({ open }) => ({
  height: '40px',
  width: '40px',
  margin: '0px',
  '&:hover': {
    ...(open
      ? {}
      : {
          boxShadow: '0 0 0 6px rgba(255, 255, 255, 0.1)',
          borderRadius: '50%',
        }),
  },
}));

export const AcountItem = ({ user, open }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleCloseUserMenu = () => {
    dispatch(signOut());
  };

  const iconsLogout = '../../../assets/images/log-out.svg';

  return (
    <AccountContainer sx={{ flexDirection: isMobile ? 'row' : open ? 'row' : 'column' }}>
      <StackUser sx={{ flexDirection: isMobile ? 'row' : open ? 'row' : 'column' }}>
        {!isMobile && !open ? (
          <Tooltip
            title={
              <TooltipContent>
                <TextUserTooltip>{`${user?.name} ${user?.['custom:paternal-surname']}`}</TextUserTooltip>
                <TextEmailTooltip>{user?.email}</TextEmailTooltip>
              </TooltipContent>
            }
            placement='right'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: '#fff',
                  color: '#21130d',
                },
              },
            }}>
            <BoxAvatar open={open}>
              <Avatar
                src={'/assets/images/dashboard/Avatar.svg'}
                loading='lazy'
                alt={'Avatar Icons'}
              />
            </BoxAvatar>
          </Tooltip>
        ) : (
          <BoxAvatar open={open}>
            <Avatar
              src={'/assets/images/dashboard/Avatar.svg'}
              loading='lazy'
              alt={'Avatar Icons'}
            />
          </BoxAvatar>
        )}
        <TextSection>
          {!isMobile && open && (
            <>
              <Text>{`${user?.name} ${user?.['custom:paternal-surname']}`}</Text>
              <SupportingText>{user?.email}</SupportingText>
            </>
          )}
          {isMobile && (
            <>
              <Text>{`${user?.name} ${user?.['custom:paternal-surname']}`}</Text>
              <SupportingText>{user?.email}</SupportingText>
            </>
          )}
        </TextSection>
      </StackUser>
      <Stack>
        <Icon>
          <ButtonAcount onClick={handleCloseUserMenu}>
            <img src={iconsLogout} alt='logout' />
          </ButtonAcount>
        </Icon>
      </Stack>
    </AccountContainer>
  );
};
