import emailjs from '@emailjs/browser';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Container, Dialog, Stack, Typography } from '@mui/material';
import { wait } from '@testing-library/user-event/dist/utils';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import capitalize from '../../utils/capitalize';
import { ButtonXcalaBlue, ButtonXcalaGray } from './ButtonXcala';

const ModalErrorFetch = ({ open, onClose, refresh, message = '', error }) => {
  const { user } = useSelector(state => state.auth);
  const navigator = window.navigator.appVersion;
  const platform = window.navigator.platform;

  const paternalName = user?.['custom:paternal-surname'] || '';
  const maternalName = user?.['custom:maternal-surname'] || '';
  const fullName =
    user && `${capitalize(user.name)} ${capitalize(paternalName)} ${capitalize(maternalName)}`;
  const navigate = useNavigate();
  const location = useLocation();
  const currentTime = new Date().toLocaleString();

  const report = {
    name: fullName || '',
    securityId: user?.username || 'error de login',
    email: user?.email || '',
    currentTime: currentTime,
    errorMessage: error,
    location: location.pathname,
    navigator: navigator,
    platform: platform,
  };

  const handleSubmitReport = () => {
    emailjs
      .send(
        'service_7utjird',
        'template_v0a6xsc',
        {
          message: JSON.stringify(report),
          from_name: fullName,
          reply_to: user?.email,
        },
        'EFAYuCkTVHLBFWEFQ',
      )
      .then(
        result => {
          toast.success('Reporte enviado con exito');
        },
        error => {
          toast.error('Ups ha ocurrido un error');
        },
      );

    onClose();
    navigate('/profilerisk');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center' },
            px: 3,
            marginBottom: 1,
          }}>
          <ErrorOutlineIcon
            sx={{
              color: 'error.main',
              fontSize: { xs: '60px', sm: '100px' },
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            pb: 2,
            px: 3,
          }}>
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '16px' },
                marginBottom: '5px',
              }}>
              {message}
            </Typography>
            {error && (
              <details>
                <summary style={{ color: 'red' }}>Detalles del error</summary>
                {error.message}
              </details>
            )}
          </Box>
        </Box>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          justifyContent='center'
          alignItems='center'
          spacing={1}
          sx={{
            px: 3,
            py: 1.5,
          }}>
          <ButtonXcalaGray fullWidth={true} variant='outlined' onClick={handleSubmitReport}>
            Enviar Error
          </ButtonXcalaGray>
          <ButtonXcalaBlue fullWidth={true} onClick={() => refresh()}>
            Reintentar
          </ButtonXcalaBlue>
        </Stack>
      </Container>
    </Dialog>
  );
};

export default ModalErrorFetch;
