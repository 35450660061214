import { gql, useLazyQuery, useQuery } from '@apollo/client';

const GET_CLIENT_PROFIT_PORTFOLIO = gql`
  query GetClientProfitPortfolio {
    getClientProfitPortfolio {
      fmtMarketValue
      marketValue
      fmtNetChange
      netChange
      fmtCapitalAppreciation
      capitalAppreciation
      fmtProfitPercent
      profitPercent
    }
  }
`;
{
  /*Retorna un objeto con la información de la caja del cliente en portfolio*/
}
export const useGetClientProfitPortfolio = () => {
  return useQuery(GET_CLIENT_PROFIT_PORTFOLIO, { fetchPolicy: 'no-cache' });
};
