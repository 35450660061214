import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';
import { ButtonXcalaBlue } from '../UI/ButtonXcala';

const GoToCompleteObAndInvestment = ({ loadingBalance, balanceData, dataInvestedMarket }) => {
  const { user } = useSelector(state => state.auth);

  const [accessWallet, setAccessWallet] = useState(false);
  const navigate = useNavigate();

  const handleRedirect = () => {
    if (!accessWallet) {
      return navigate('/completeregistration');
    }
    if (cashBalance?.marketValue === 0) {
      return navigate('/payments');
    }
    if (cashBalance?.marketValue >= 1) {
      return navigate('/wallet');
    }

    return navigate('/payments');
  };
  const cashBalance = balanceData?.getCashBalance?.balance;

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setAccessWallet(true);
    }
  }, [user]);

  if (loadingBalance) {
    return <Spinner />;
  }

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        boxShadow: 'none',
        padding: '8px',
      }}>
      <CardHeader
        title={
          <Typography
            sx={{
              fontSize: '24px',
            }}>
            Evolución de la Inversión
          </Typography>
        }
      />

      <CardContent>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent='space-between'
          width='70%'
          padding={{ xs: '0px 16px', md: '16px' }}>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={1}>
            <Box
              style={{
                width: '7px',
                height: '7px',
                background: '#49A197',
                display: 'inline',
                borderRadius: '100%',
              }}
            />
            <Typography
              component='p'
              sx={{
                color: '#182849',
                fontWeight: '600',
                fontSize: '16px',
              }}>
              Balance
            </Typography>
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={1}>
            <Box
              style={{
                width: '7px',
                height: '7px',
                background: '#7F56D9',
                display: 'inline',
                borderRadius: '100%',
              }}
            />
            <Typography
              component='p'
              sx={{
                color: '#182849',
                fontWeight: '600',
                fontSize: '16px',
              }}>
              Depositado
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardContent
        sx={{
          padding: '4px',
        }}>
        <Box display='flex' flexDirection='row'>
          <ButtonGroup
            disabled={true}
            sx={{
              width: '100%',
              '& .MuiButton-outlined': {
                borderColor: '#1E22AA',
                '&:hover': {
                  borderColor: '#1E22AA',
                },
                '&:focus': {
                  borderColor: '#1E22AA',
                },
              },
            }}
            variant='outlined'
            aria-label='outlined primary button group'
            size='small'>
            <Button
              sx={{
                textTransform: 'none',
              }}>
              12 meses
            </Button>
            <Button
              sx={{
                textTransform: 'none',
              }}>
              3 meses
            </Button>
            <Button
              sx={{
                textTransform: 'none',
              }}>
              1 mes
            </Button>
          </ButtonGroup>
        </Box>
      </CardContent>
      <Box
        display='flex'
        padding={{ md: 2, xs: '4px' }}
        justifyContent='center'
        alignItems='center'
        alignContent='center'
        sx={{
          backdropFilter: 'blur(0px)',
          backgroundColor: 'rgba(246, 246, 246, 0.15)',
          backgroundImage: 'url(/assets/images/chart.png)',
          backgroundRepeat: 'no-repeat',
          zIndex: 2,
        }}>
        <Card
          height={{ xs: '200px' }}
          sx={{
            padding: '10px 20px',
          }}>
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: { xs: 0 },
            }}>
            <Stack width='50px' height='50px'>
              <img
                src='/assets/images/targetIcon.svg'
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'inline-block',
                }}
              />
            </Stack>

            <Typography style={{ textAlign: 'center', marginBottom: '10px' }}>
              Para poder seguir invirtiendo en Xcala por favor recarga tu caja
            </Typography>

            <ButtonXcalaBlue onClick={handleRedirect} style={{ margin: '10px' }}>
              Realiza tu primera inversión
            </ButtonXcalaBlue>
          </CardContent>
        </Card>
      </Box>
    </Card>
  );
};

export default GoToCompleteObAndInvestment;
