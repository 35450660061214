import { Chip, styled } from '@mui/material';
import { useEffect, useState } from 'react';

const ContentLabel = styled(Chip)({
  backgroundColor: 'rgba(237, 252, 249, 1)',
  color: 'rgba(73, 161, 151, 1)',
  padding: '2.421052932739258px 4.263158798217773px',
  height: '23px',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '12px',
  '&:hover': {
    backgroundColor: 'rgba(196, 222, 217, 1)',
    color: 'rgba(73, 161, 151, 1)',
  },
});

const AvatarLabel = styled('img')({
  height: '16px',
  width: '16px',
  objectFit: 'contain',
});

export const InterestLabel = ({ intereses, interestsId }) => {
  return (
    <>
      <ContentLabel
        label={intereses}
        avatar={
          <AvatarLabel
            src={`/assets/images/iconProducts/${interestsId}.svg`}
            sx={{ width: '16px', height: '16px', fontSize: 8 }}
            style={{ width: '16px', height: '16px' }}
          />
        }
      />
    </>
  );
};
