import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Spinner from '../../Spinner';
import Charts from '../Chart';

const ChartnvestmentContainerProducts = ({
  dataCharts,
  dividends,
  active,
  onChangeMonths,
  loadingGetSecurityPriceRange,
}) => {
  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        padding: '8px',
      }}>
      <CardHeader
        title={
          <Typography
            sx={{
              fontSize: '24px',
            }}>
            Evolución del Fondo
          </Typography>
        }
      />
      <CardContent
        sx={{
          padding: '4px',
        }}>
        <Box display='flex' flexDirection='row' sx={{ ml: 1 }}>
          <ButtonGroup
            sx={{
              width: '100%',
              '& .MuiButton-outlined': {
                borderColor: '#1E22AA',
                '&:hover': {
                  borderColor: '#1E22AA',
                },
                '&:focus': {
                  borderColor: '#1E22AA',
                },
              },
            }}
            variant='outlined'
            aria-label='outlined primary button group'
            size='small'>
            <Button
              onClick={() => onChangeMonths(12)}
              style={{
                backgroundColor: active === 12 ? '#1E22AA' : 'initial',
                color: active === 12 ? 'white' : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              12 meses
            </Button>
            <Button
              onClick={() => onChangeMonths(3)}
              style={{
                backgroundColor: active === 3 ? '#1E22AA' : 'initial',
                color: active === 3 ? 'white' : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              3 meses
            </Button>
            <Button
              onClick={() => onChangeMonths(1)}
              style={{
                backgroundColor: active === 1 ? '#1E22AA' : 'initial',
                color: active === 1 ? 'white' : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              1 mes
            </Button>
          </ButtonGroup>
        </Box>
      </CardContent>

      <CardContent
        sx={{
          height: '100%',
          maxHeight: '425px',
          minHeight: { md: '', xs: '300px' },
        }}>
        {loadingGetSecurityPriceRange ? (
          <Spinner />
        ) : (
          <Charts
            productdetail={'productdetail'}
            data={dataCharts}
            dividends={dividends}
            serieName={'Saldo'}
            showLabels={true}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default ChartnvestmentContainerProducts;
