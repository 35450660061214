import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Stack, styled } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import useGetModelsPortfolio from '../../hooks/useGetModelsPortfolio';
import { portfolioNameAndDetails } from '../../utils/portfolioNameAndDetails';
import { ButtonsPrimary, ButtonsSecundary, ButtonsTertiary } from '../ComponentApp/buttoms';
import Spinner from '../Spinner';
import { AvatarInvertedPortfolio } from './AvatarInvertedPortfolio';
import { IconInfo, RentabilidadUltimo, RetornoEsperado } from './PorfolioComponents';
import { ProfileValueInverted } from './ProfileValueInverted';
import WrapperBackgroundBanner from './WrapperBackgroundBanner';
import WrapperContentApp from './WrapperContentApp';

const $minHeigth = '407px';

const style = {
  marginLeft: '2px',
};

const BtnGroupContainer = styled(Stack)({
  position: 'absolute',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'flex-end',
  boxSizing: 'border-box',
  bottom: '30px',
  right: '0',
});
const BtnGroup = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '8px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '12px',
  boxSizing: 'border-box',
  width: '100%',
  margin: '0px',
});
const BtnContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
});
const ButtonPrimary = styled(Button)({
  flex: '1',
  height: '50px',
  margin: '0px',
  color: 'rgba(30, 34, 170, 1)',
  borderColor: 'rgba(30, 34, 170, 1)',
  textTransform: 'none',
  '&:hover': {
    borderColor: 'rgba(30, 34, 170, 1)',
  },
});
const ButtonSecondary = styled(Button)({
  flex: '1',
  height: '50px',
  backgroundColor: 'rgba(30, 34, 170, 1)',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(30, 34, 170, 1)',
  },
});

const ButtonReInvestment = styled(Button)({
  border: '2px solid rgba(30, 34, 170, 1)',
  flex: '1',
  height: '50px',
  marginTop: '0px',
  color: 'rgba(30, 34, 170, 1)',
  borderColor: 'rgba(30, 34, 170, 1)',
  textTransform: 'none',
  '&:hover': {
    borderColor: 'rgba(30, 34, 170, 1)',
    border: '2px solid rgba(30, 34, 170, 1)',
  },
});

export const HeaderProducts = ({
  rescueValue = '10,5',
  handleInvestMore,
  handleRescueInvestment,
  idSelected,
  fileAs,
  ModelId,
  visible,
  statusDividendFinfolio,
  handleClose,
  handleOpen,
  simulation = ' Rescatar Inversión',
  investmentMore = ' Invertir Más',
  goToProductDetail,
  details,
}) => {
  return (
    <>
      <WrapperBackgroundBanner $minHeigth={$minHeigth}>
        <WrapperContentApp fileAs={fileAs} $minHeigth={$minHeigth}>
          <AvatarInvertedPortfolio
            porfolioDetailHeader={fileAs}
            goToProductDetail={goToProductDetail}
            details={details}
          />
          {fileAs.profit && (
            <Stack direction={'column'}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={{ md: 'end', xs: 'center' }}
                marginTop={{ md: '-40px', xs: '8px' }}>
                <RentabilidadUltimo fontSize={{ md: '40px', xs: '30px' }}>
                  {fileAs.profit} %{' '}
                </RentabilidadUltimo>
                <IconInfo src='/assets/images/perfiles/info.svg' />
              </Stack>
              <RetornoEsperado textAlign={{ md: 'end', xs: 'center' }}>
                Rentabilidad Esperada
              </RetornoEsperado>
            </Stack>
          )}
        </WrapperContentApp>
      </WrapperBackgroundBanner>
      <BtnGroupContainer zIndex={10}>
        <Stack
          width={{ md: '90%', xs: '90%' }}
          maxWidth={{ md: '1380px', xs: '100%' }}
          marginX={'auto'}
          justifyContent={'center'}
          alignItems={{ md: 'flex-end', xs: 'center' }}
          flex={'1 1 auto'}>
          <BtnGroup
            maxWidth={{ md: '650px', xs: '80%' }}
            flexDirection={{ md: 'column', xs: 'column-reverse' }}
            gap={{ md: 0, xs: 2 }}>
            <BtnContainer
              flexDirection={{ md: 'row', xs: 'column' }}
              gap={2}
              marginBottom={{ md: 0, xs: 0.5 }}>
              <ButtonsSecundary
                name={simulation}
                onClick={() => handleRescueInvestment(idSelected)}
                fullWidth
              />
              {/* <ButtonPrimary
                variant="outlined"
                onClick={() => handleRescueInvestment(idSelected)}
                sx={{width:{md:0, xs:"100%"}}}
              >
                {simulation}
              </ButtonPrimary> */}
              <ButtonsPrimary
                name={investmentMore}
                onClick={() => handleInvestMore(ModelId)}
                fullWidth
              />
              {/* <ButtonSecondary
                variant="contained"
                onClick={() => handleInvestMore(ModelId)}
                sx={{width:{md:0, xs:"100%"}}}
              >
                {investmentMore}
              </ButtonSecondary> */}
            </BtnContainer>
            {statusDividendFinfolio && (
              <Stack width={'100%'} marginTop={{ md: 1, xs: 0.5 }}>
                {fileAs.products === true &&
                  (!visible && statusDividendFinfolio.length > 0 ? (
                    <ButtonsTertiary
                      name='Reinvertir Dividendos'
                      endIcon={<KeyboardArrowRightIcon />}
                      fullWidth
                      onClick={handleOpen}
                      imgUrl='/assets/images/edit.svg'
                      sx={{ whiteSpace: 'nowrap' }}
                      style={style}
                    />
                  ) : /*  <ButtonReInvestment
                      variant="outlined"
                      endIcon={<KeyboardArrowRightIcon />}
                      fullWidth
                      style={{ textTransform: "none" }}
                      onClick={handleOpen}
                      sx={{
                        borderRadius: "4px",
                        borderColor: "#1e22aa",
                        color: "#1e22aa",
                        background: "#F4F4FB",
                        textTransform: "none",
                        fontSize:{
                          md:"16px",
                          xs:"14px",  
                        } ,
                        ":hover": {
                          background: "#F4F4FB",
                          borderColor: "#1e22aa",
                        },
                      }}
                    >
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginRight: "5px",
                          color: "red",
                        }}
                        src="/assets/images/edit.svg"
                        alt="edit"
                      />
                      Reinvertir Dividendos
                    </ButtonReInvestment> */
                  statusDividendFinfolio.length > 0 ? (
                    <ButtonsTertiary
                      name='Cancelar'
                      startIcon={<CloseIcon />}
                      endIcon={<KeyboardArrowDownIcon />}
                      fullWidth
                      onClick={handleClose}
                    />
                  ) : /* <ButtonReInvestment
                      startIcon={<CloseIcon />}
                      endIcon={<KeyboardArrowDownIcon />}
                      variant="outlined"
                      fullWidth
                      style={{ textTransform: "none" }}
                      onClick={handleClose}
                      sx={{
                        borderRadius: "4px",
                        borderColor: "#1e22aa",
                        color: "#1e22aa",
                        background: "#F4F4FB",
                        textTransform: "none",
                        fontSize: "16px",
                        ":hover": {
                          background: "#F4F4FB",
                          borderColor: "#1e22aa",
                        },
                      }}
                    >
                      Cancelar
                    </ButtonReInvestment> */
                  null)}
              </Stack>
            )}
          </BtnGroup>
        </Stack>
      </BtnGroupContainer>
    </>
  );
};
