import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const AvatarSection1 = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  height: '100%',
  maxHeight: '120px',
  minHeight: '90px',
  width: '100%',
  maxWidth: '122px',
  minWidth: '92.83px',
});

const Avatar = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
  minHeight: '90px',
  minWidth: '92.83px',
  margin: '0px',
});

const Avatar1 = styled('img')({
  width: '96px',
  height: '96px',
});

const IconosPerfiles = styled('img')({
  width: '45px',
  height: '45px',
  position: 'absolute',
  right: '8px',
  bottom: '-6px',
  filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
});

const handelPerfil = perfil => {
  return perfil === 'conservador'
    ? '/assets/images/perfiles/conservador.svg'
    : perfil === 'moderado'
      ? '/assets/images/perfiles/moderado.svg'
      : '/assets/images/perfiles/agresivo.svg';
};

function AvatarSection({ userRisk }) {
  return (
    <AvatarSection1>
      <Avatar>
        <Avatar1 src={'/assets/images/dashboard/Avatar.svg'} loading='lazy' alt={'Avatar Icons'} />

        <IconosPerfiles src={handelPerfil(userRisk)} loading='lazy' alt={'Iconos Perfile'} />
      </Avatar>
    </AvatarSection1>
  );
}

export default AvatarSection;
