import { Button, Stack, Typography, styled } from '@mui/material';

export const TitleSectionContainer = styled(Stack)({
  background:
    'linear-gradient(106.49deg, rgba(112, 151, 217, 1) -29.020488665609555%, rgba(30, 34, 170, 1) 50.092131130721704%)',
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  height: '33px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '4px 10px',
  boxSizing: 'border-box',
});
export const TitleSection = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(231, 243, 255, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '16px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
  margin: '0px',
});

/*Caja */
export const Card = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 0px 12px rgba(61, 64, 75, 0.18)',
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  height: '135.63px',
  width: '100%',
  minWidth: '140px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 20px',
  boxSizing: 'border-box',
  overflow: 'hidden',
});

export const CardBody = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '97px',
  width: '100%',
});
export const Icons = styled('img')({
  position: 'relative',
  isolation: 'isolate',
  padding: '0px',
  boxSizing: 'content-box',
  minHeight: '50px',
  width: '50px',
  margin: '0px',
  borderRadius: '4px',
});

export const CardBodyText = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '8px 0px 0px 0px',
  height: '100%',
  width: '100%',
  backgroundColor: '#fff',
});

export const Title = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 0.54)',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '12px',
  letterSpacing: '-0.4742029309272766px',
  textDecoration: 'none',
  lineHeight: '105%',
  textTransform: 'none',
  margin: '0px',
});

export const AmountText = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontFamily: 'Poppins',
  fontWeight: '700',
  letterSpacing: '-0.4742029309272766px',
  textDecoration: 'none',
  lineHeight: '26.081161499023438px',
  textTransform: 'none',
  margin: '4px 0px 0px 0px',
});
export const ListProduct = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '4px',
  boxSizing: 'border-box',
  margin: '0px 0px 0px 0px',
  overflow: 'hidden',
  width: '100%',
  borderRadius: '8px',
  overflowY: 'scroll',
});

export const IteractionAssetsFilt = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  height: '20px',
  width: '172px',
});
export const BtnFilterFound = styled(Button)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',

  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '16px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
  margin: '0px',
  '&:hover': {
    color: 'rgba(148, 191, 225, 1)',
  },
});
export const Divider = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(255, 255, 255, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '16px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
});
