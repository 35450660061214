import { Box, Container, Stack, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonsPrimary,
  ButtonsTertiary,
} from '../../../components/ComponentApp/buttoms/ButtonsXcala';
import Spinner from '../../../components/Spinner';
import { NotificationsXcala } from '../../../components/XcalaComponents/Notifications/NotificationsXcala';
import HeaderObjectivesInterests from '../../../components/myObjectivesInterests/HeaderObjectivesInterests';
import ObjectivesAndInterestsPanel from '../../../components/myObjectivesInterests/ObjectivesAndInterestsPanel';
import useModal from '../../../hooks/custom/useModal';
import useGetInterests from '../../../hooks/useGetInterests';
import useGetMotivations from '../../../hooks/useGetMotivations';
import useGetObjetives from '../../../hooks/useGetObjetives';
import useGetUserPreferences from '../../../hooks/useGetUserPreferences';
import useRegisterUserSelectedPreferences from '../../../hooks/useRegisterUserSelectedPreferences';
import {
  errorGetMyInteresets,
  initGetMyInteresets,
  successGetMyInteresets,
} from '../../../redux/myinteresets';
import { onRefresh } from '../../../utils/refreshPage';

const ComponentPages = styled(Stack)({
  width: { md: '80%', xs: '100%' },
  maxWidth: '1380px',
  flexDirection: 'row',
  margin: '0 auto',
  gap: '24px',
  flexWrap: 'wrap',
});

export const ComponentsSection = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  flexDirection: 'column',
  borderRadius: '8px',
  flex: '1 1 560px',
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'start',
  alignItems: 'center',
  // padding: "18px",
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: { md: '600px' },
  margin: '0px',
  boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.081)',
});

const MyNewObjectivesInterests = () => {
  const { isShowing, toggle } = useModal();
  const dispatch = useDispatch();
  const { suceessMyInteresets, loadingMyInteresets, errorMyInteresets } = useSelector(
    state => state.myinteresets,
  );
  const { user } = useSelector(state => state.auth);
  const [objetivesInterestData, setObjetivesInterestData] = useState(null);
  const [discard, setDisctard] = useState([]);
  const [toggleState, setToggleState] = useState([
    { id: 1, value: true },
    { id: 2, value: false },
    { id: 3, value: false },
  ]);

  //data to header
  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  const {
    name: nameUser,
    email: emailUser,
    'custom:dni': userDni,
    'custom:paternal-surname': lastNameUser,
  } = user;

  //mutate hook
  const [
    doRegisterUserSelectedPreferences,
    { data: dataMotatehook, error: errorMutateHook, loading: loadingMotateHook },
  ] = useRegisterUserSelectedPreferences();

  const { data: dataUserPreferences, error, loading } = useGetUserPreferences();

  const {
    data: dataGetInterests,
    error: errorGetInterests,
    loading: loadingGetInterests,
  } = useGetInterests();

  const {
    data: dataGetMotivations,
    error: errorGetMotivations,
    loading: loadingGetMotivations,
  } = useGetMotivations();

  const {
    data: dataGetObjetives,
    error: errorGetObjetives,
    loading: loadingGetObjetives,
  } = useGetObjetives();

  const successGetMotivations = dataGetMotivations?.getMotivations;
  const successGetObjetives = dataGetObjetives?.getObjetives;
  const successGetInterests = dataGetInterests?.getInterests;

  const handleDiscardData = () => {
    dispatch(successGetMyInteresets(discard));
  };

  const handleToggleChange = id => {
    setToggleState(prevState => {
      return prevState.map(item => {
        if (item.id === id) {
          return { ...item, value: !item.value };
          // biome-ignore lint/style/noUselessElse: <explanation>
        } else {
          return { ...item, value: false };
        }
      });
    });
  };

  const handleSubmit = () => {
    const data = [];
    // biome-ignore lint/complexity/noForEach: <explanation>
    Object.values(suceessMyInteresets).forEach(item => {
      data.push({
        preference_detail_id: Number.parseInt(item.preference_detail_id, 10),
        text: item.text,
      });
    });

    doRegisterUserSelectedPreferences({ variables: { data } })
      .then(() => {
        setDisctard(data);
        toast.success('Cambios realizados con exito!');
      })
      .catch(error => {
        dispatch(successGetMyInteresets(discard));
      });
  };

  useEffect(() => {
    if (successGetMotivations && successGetObjetives && successGetMyInteresets) {
      setObjetivesInterestData([
        {
          id: 1,
          title: 'Mis motivaciones',
          data: successGetMotivations,
          userPreference: suceessMyInteresets,
        },
        {
          id: 2,
          title: 'Mis objetivos',
          data: successGetObjetives,
          userPreference: suceessMyInteresets,
        },
        {
          id: 3,
          title: 'Mis intereses',
          data: successGetInterests,
          userPreference: suceessMyInteresets,
        },
      ]);
    }
  }, [successGetMotivations, successGetInterests, successGetObjetives, suceessMyInteresets]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    dispatch(initGetMyInteresets());
  }, []);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (error && !loading) {
      dispatch(errorGetMyInteresets());
    }
  }, [error, loading]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (dataUserPreferences?.getUserPreferences && !loading) {
      dispatch(successGetMyInteresets(dataUserPreferences.getUserPreferences));
      setDisctard(dataUserPreferences.getUserPreferences);
    }
  }, [JSON.stringify(dataUserPreferences?.getUserPreferences), loading]);

  if (loadingMyInteresets || loadingGetInterests || loadingGetMotivations || loadingGetObjetives) {
    return <Spinner />;
  }

  if (
    errorMyInteresets ||
    errorGetInterests ||
    errorGetMotivations ||
    errorGetObjetives ||
    errorMutateHook
  ) {
    return (
      <NotificationsXcala
        activation={!isShowing}
        message='Lo sentimos, ha ocurrido un error inesperado....'
        nameBtnTwo='Enviar error'
        toggle={toggle}
        refresh={onRefresh}
        error={errorMyInteresets || errorGetInterests || errorGetMotivations || errorGetObjetives}
      />
    );
  }

  return (
    <>
      <HeaderObjectivesInterests
        userRisk={userRisk}
        nameUser={nameUser}
        emailUser={emailUser}
        userDni={userDni}
        lastNameUser={lastNameUser}
      />
      <Box marginTop='-120px'>
        <Container
          maxWidth='1380px'
          sx={{
            marginTop: '0',
            alignItems: 'center',
            background: '#F2F2F2',
            paddingY: '60px',
          }}>
          <Stack
            sx={{
              width: { md: '100%', xs: '100%' },
              maxWidth: '1200px',
              isolation: 'isolate',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.081)',
              bgcolor: '#fff',
              borderRadius: '8px',
              padding: '32px 16px',
              gap: 6,
              margin: '0 auto',
            }}>
            <ComponentPages>
              {objetivesInterestData?.map(item => {
                return (
                  <ObjectivesAndInterestsPanel
                    key={item.id}
                    toggleState={toggleState}
                    id={item?.id}
                    title={item?.title}
                    userPreference={item?.userPreference}
                    data={item?.data}
                    handleToggleChange={handleToggleChange}
                  />
                );
              })}
            </ComponentPages>
            <Stack flexDirection={{ md: 'row', xs: 'column-reverse' }} gap={2} mb={3}>
              <ButtonsTertiary
                name='Descartar'
                onClick={handleDiscardData}
                disabled={discard?.length === suceessMyInteresets?.length || loadingMotateHook}
              />

              <ButtonsPrimary
                name='Guardar Cambios'
                onClick={handleSubmit}
                disabled={discard?.length === suceessMyInteresets?.length || loadingMotateHook}
              />
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default MyNewObjectivesInterests;
