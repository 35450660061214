import { Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import {
  BtnTitle,
  IconsCopy,
  IconsWarnning,
  MsnAlert,
  ParrafoAlert,
  SectionDescription,
  SectionPrintView,
  SectionRechargeCash,
  SoloFaltaQueRealices,
  TextImportante,
  TitleAlert,
  WarningAmber,
} from '../XcalaComponents/ComponentsWallet';
import InfoCardWallet from '../XcalaComponents/InfoCardWallet';

const WalletTransfer = ({ amount }) => {
  const [copied, setCopied] = useState(false);
  const [data, setData] = useState(`
      XCALA CL SPA
      77.513.567-0
      Banco de Chile
      Cuenta corriente
      8005743700
      depositos@xcala.com
      `);
  const navigate = useNavigate();

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Stack paddingBottom={'80px'}>
      <SectionRechargeCash
        sx={{
          padding: { md: '32px', xs: '16px' },
          width: { md: '60%', xs: '90%' },
        }}>
        <SectionPrintView>
          <SoloFaltaQueRealices sx={{ fontSize: 'clamp(0.75rem, 0.5673rem + 0.7692vw, 1rem)' }}>
            {`Solo falta que realices la transferencia de $ ${amount} a:`}
          </SoloFaltaQueRealices>
        </SectionPrintView>
        <SectionDescription>
          <InfoCardWallet Title={'Nombre:'} value={'Xcala CL SpA.'} />
          <InfoCardWallet Title={'Banco:'} value={'Banco de Chile'} />
          <InfoCardWallet Title={'Tipo de Cuenta:'} value={'Cuenta Corriente'} />
          <InfoCardWallet Title={'Cuenta:'} value={'8005743700'} />
          <InfoCardWallet Title={'RUT:'} value={'77.513.567-0'} />
          <InfoCardWallet Title={'Email:'} value={'depositos@xcala.com'} />
        </SectionDescription>
        <CopyToClipboard text={data} onCopy={() => handleCopy()}>
          <Button
            disabled={copied === true}
            startIcon={<IconsCopy />}
            sx={{ width: '80%', padding: '16px' }}>
            <BtnTitle>{copied ? 'Copiado' : 'Copiar datos bancarios'}</BtnTitle>
          </Button>
        </CopyToClipboard>

        <MsnAlert>
          <TitleAlert>
            <WarningAmber>
              <IconsWarnning />
            </WarningAmber>
            <TextImportante>Importante</TextImportante>
          </TitleAlert>
          <ParrafoAlert
            sx={{
              fontSize: 'clamp(0.75rem, 0.5673rem + 0.7692vw, 1rem)',
              width: '90%',
              margin: '0 auto',
            }}>
            Una vez validada tu transferencia, verás reflejado tu saldo como disponible en caja en
            un lapso de 24 horas
          </ParrafoAlert>
        </MsnAlert>
        <Button sx={{ width: '80%', padding: '16px' }}>
          <BtnTitle onClick={() => navigate('/myprofilerisk')}>Volver al Inicio</BtnTitle>
        </Button>
      </SectionRechargeCash>
    </Stack>
  );
};

export default WalletTransfer;
