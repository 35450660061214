import { Stack, Typography, styled } from '@mui/material';

export const ContentCheckBox = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  //height: '24px',
  width: '100%',
});

export const DeclarationSpan = styled(Typography)({
  whiteSpace: 'pre-wrap',
  color: 'rgba(73, 161, 151, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
});

export const TermsAndConditions = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '22px 0px 0px 0px',
  //height: '24px',
  width: '100%',
});
export const TextDeclaration = styled(Typography)({
  whiteSpace: 'pre-wrap',
  color: 'rgba(128, 128, 128, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
});
