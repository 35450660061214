import { Stack, styled } from '@mui/material';

export const WalletSection = styled(Stack)({
  width: { md: '100%', sm: '100%', lg: '100%' },
  height: '100%',
  // margin: "0px 0px 0px 0px",
  borderRadius: '8px',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  overflow: 'hidden',
});
