import { useEffect, useState } from 'react';

export const TidioChat = ({ show }) => {
  const [tidioScriptAdded, setTidioScriptAdded] = useState(false);
  //   useEffect(() => {
  //     if (!show) {
  //       return null;
  //     }

  //     const script = document.createElement("script");
  //     script.src = "https://code.tidio.co/m63ept2mlkexfti9gmyj2hrybqckzufc.js";
  //     script.async = true;
  //     document.body.appendChild(script);
  //     console.log(script);
  //     return () => {
  //       document.body.removeChild(script);
  //       console.log("removiendo");
  //     };
  //   }, [show]);

  //   useEffect(() => {
  //     if (!show) {
  //       const existingScript = document.getElementById("tidio-chat-script");
  //       if (existingScript) {
  //         document.body.removeChild(existingScript);
  //       }
  //       return;
  //     }

  //     const script = document.createElement("script");
  //     script.id = "tidio-chat-script";
  //     script.src = "https://code.tidio.co/m63ept2mlkexfti9gmyj2hrybqckzufc.js";
  //     script.async = true;

  //     const existingScript = document.getElementById("tidio-chat-script");
  //     if (existingScript) {
  //       document.body.removeChild(existingScript);
  //     }

  //     document.body.appendChild(script);

  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }, [show]);

  useEffect(() => {
    if (show && !tidioScriptAdded) {
      const script = document.createElement('script');
      script.src = 'https://code.tidio.co/m63ept2mlkexfti9gmyj2hrybqckzufc.js';
      script.async = true;
      script.id = 'tidio-chat-script';

      document.body.appendChild(script);
      setTidioScriptAdded(true);
    } else if (!show && tidioScriptAdded) {
      const script = document.getElementById('tidio-chat-script');
      if (script) {
        document.body.removeChild(script);
        script.remove();
        const frame = document.querySelector('#tidio-chat-code');
        const iFrame = document.querySelector('#tidio-chat');
        // console.log({ frame, iFrame });
        if (frame && iFrame) {
          // Si existe #tidio-chat, busca y remueve el script con el src especificado

          // Remueve el script del DOM
          frame.remove();
          iFrame.remove();
          //   console.log("Script removido correctamente.");
        } else {
          // Si no existe #tidio-chat, muestra un mensaje
          //   console.log(
          //     "No hay ningún elemento con el ID #tidio-chat en el documento."
          //   );
        }
      }
      setTidioScriptAdded(false);
    }
  }, [show, tidioScriptAdded]);
  return null;
};
