import { Box, Stack, Typography } from "@mui/material";
import { message } from "antd";
import OTPInput from "otp-input-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SignInSignUpLayout from "../../components/Layout/SignInSignUpLayout/SignInSignUpLayout";
import {
  forgotPasswordSubmit,
  resetForgotPasswordSubmit,
  updateEmailTemp,
  updatePasswordTemp,
} from "../../redux/auth";


const ForgotPasswordConfirm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");

  const {
    emailTemp,
    passwordTemp,
    loadingForgotPasswordSubmit,
    successForgotPasswordSubmit,
    errorForgotPasswordSubmit,
  } = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (errorForgotPasswordSubmit && !loadingForgotPasswordSubmit) {
      message.error(errorForgotPasswordSubmit);
      dispatch(resetForgotPasswordSubmit());
    }
  }, [loadingForgotPasswordSubmit, errorForgotPasswordSubmit]);

  const handleResendSignUp = () => {};

  const onChangeOtp = (values) => {
    setOTP(values);
    if (values.length === 6) {
      dispatch(
        forgotPasswordSubmit({
          username: emailTemp,
          code: values,
          password: passwordTemp,
        })
      );
    }
  };

  useEffect(() => {
    if (successForgotPasswordSubmit && !loadingForgotPasswordSubmit) {
      dispatch(resetForgotPasswordSubmit());
      dispatch(updateEmailTemp(null));
      dispatch(updatePasswordTemp(null));
      navigate("/changeforgetpasswordsuccess");
    }
  }, [successForgotPasswordSubmit, loadingForgotPasswordSubmit]);


  return (
    <Stack
      sx={{
        boxSizing: "border-box",
        width: "100%",
        minHeight: "100vh",
        position: "relative",
        overflow: "hidden",
        padding: 0,
        //overscrollBehaviorX: 'contain',
      }}
    >
      <SignInSignUpLayout>
        <Stack
          sx={{
            background: "#fff",
            boxSizing: "border-box",
            //width: '80%',
            borderRadius: "24px",
            padding: "32px",
            position: "relative",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.15)",
            minHeight: "300px",
            zIndex: 4,
            justifyContent: "center",
            alignItems: "center",
          }}
          width={{ xs: "90%", sm: "80%", md: "80%" }}
          marginTop="clamp(8.375rem, 14.3617rem + -21.2766vw, 1rem)"
          maxWidth={{ xs: "90%", md: "400px" }}
          minWidth={{ xs: "40%" }}
        >
          <Stack
            textAlign={"center"}
            gap={1}
            sx={{
              width: "90%",
              background: "#96bde41a",
              padding: "20px",
              borderRadius: "8px",
              marginBottom: "16px",
              color: "#182849",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "90%",
              }}
            >
              <Typography
                component="p"
                sx={{ fontWeight: 300, textAlign: "left" }}
              >
                Ingresa el código de verificación que enviamos al correo{" "}
                <Typography component={"span"} sx={{ fontWeight: 600 }}>
                  {emailTemp}
                </Typography>
              </Typography>
            </Box>
          </Stack>

          <Stack
            sx={{
              width: "100%",
              background: "#fff",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",

              gap: 5,
              //padding: '8px'
            }}
            minWidth={"80%"}
          >
            <OTPInput
              className="custom-otp-input"
              value={OTP}
              onChange={onChangeOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              secure
            />
          </Stack>

          <Stack
            textAlign={"center"}
            gap={1}
            sx={{
              width: "90%",

              padding: "20px",
              borderRadius: "8px",
              marginBottom: "16px",
              color: "#182849",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "90%",
              }}
            >
              <Typography
                component="p"
                sx={{ fontWeight: 300, textAlign: "left", color: "gray", fontSize:"14px"}}
              >
                ¿No has recibido nuestro correo? ¿Revisaste tu spam? Puede que
                hayamos llegado allá...
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </SignInSignUpLayout>
    </Stack>
  );
};

export default ForgotPasswordConfirm;
