import { Stack } from '@mui/material';
import useModal from '../../hooks/custom/useModal';
import { onRefresh } from '../../utils/refreshPage';
import Spinner from '../Spinner';
import ModalErrorFetch from '../UI/ModalErrorFetch';
import { ComponentsSection, DivisionSectionFrom } from '../XcalaComponents/MyNewAccount';
import { InputFiled } from './InputFiled';
import { InputSelectFiled } from './InputSelectFiled';

export const FormDatas = ({
  contactField,
  handleChangeContactFields,
  isEditContactData,
  errorPhone,
  errorAddress,
  isEditPersonalData,
  handleChangePersonalFields,
  handleOpenEditPersonalData,
  handleOpenEditContactData,
  personalFields,
  errorRegisterUserDataRegistration,
  loadingRegisterUserDataRegistration,
}) => {
  const { isShowing, toggle } = useModal();

  if (errorRegisterUserDataRegistration) {
    return (
      <ModalErrorFetch
        message='Oh! parece que esto no funcionó bien...'
        error={errorRegisterUserDataRegistration}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  }
  if (loadingRegisterUserDataRegistration) {
    return (
      <ComponentsSection>
        <Spinner />
      </ComponentsSection>
    );
  }

  return (
    <ComponentsSection>
      <Stack sx={{ width: '100%', flexDirection: 'column', boxSizing: 'border-box' }}>
        <DivisionSectionFrom
          isEdit={isEditPersonalData}
          handleOpenEdit={handleOpenEditPersonalData}
          textTitle='Datos Personales'
        />

        <Stack flexDirection='row' gap={4} flexWrap={'wrap'} sx={{ paddingBottom: '32px' }}>
          <InputSelectFiled
            isEditPersonalData={isEditPersonalData}
            nameFiled='Género'
            selet={personalFields.gender.name}
            funtion={handleChangePersonalFields}
          />
          <InputSelectFiled
            isEditPersonalData={isEditPersonalData}
            nameFiled='Nacionalidad'
            selet={personalFields.Nacionalidad.name}
            funtion={handleChangePersonalFields}
          />
          <InputSelectFiled
            isEditPersonalData={isEditPersonalData}
            nameFiled='Ingresos mensuales'
            selet={personalFields.Ingresos_mensuales.name}
            funtion={handleChangePersonalFields}
          />
          <InputSelectFiled
            isEditPersonalData={isEditPersonalData}
            nameFiled='Origen de fondos'
            selet={personalFields.Origen_de_fondos.name}
            funtion={handleChangePersonalFields}
          />
          <InputSelectFiled
            isEditPersonalData={isEditPersonalData}
            nameFiled='Estado civil'
            selet={personalFields.estado_civil.name}
            funtion={handleChangePersonalFields}
          />
          <InputSelectFiled
            isEditPersonalData={isEditPersonalData}
            nameFiled='Profesión'
            selet={personalFields.Profesion.name}
            funtion={handleChangePersonalFields}
          />
        </Stack>

        <DivisionSectionFrom
          isEdit={isEditContactData}
          handleOpenEdit={handleOpenEditContactData}
          textTitle='Datos de Contacto'
        />

        <Stack flexDirection='row' gap={4} flexWrap={'wrap'} sx={{ paddingBottom: '32px' }}>
          <InputFiled
            isEditContactData={isEditContactData}
            nameFiled='email'
            info={contactField.email}
            funtion={handleChangeContactFields}
          />
          <InputFiled
            isEditContactData={isEditContactData}
            nameFiled='Telefono'
            errorPhone={errorPhone}
            info={contactField.telephone}
            funtion={handleChangeContactFields}
          />
          <InputFiled
            isEditContactData={isEditContactData}
            nameFiled='Calle y número'
            errorAddress={errorAddress}
            info={contactField.address}
            funtion={handleChangeContactFields}
          />
          {/*  <InputFiled nameFiled='Casa/Depto' />
          <InputFiled nameFiled='Comuna' />
          <InputFiled nameFiled='Región' /> */}
        </Stack>
      </Stack>
    </ComponentsSection>
  );
};
