import { TextField } from '@mui/material';

export const InputFiled = ({
  isEditContactData,
  errorPhone,
  nameFiled,
  info,
  funtion,
  errorAddress,
}) => {
  return (
    <>
      {nameFiled === 'email' ? (
        <TextField
          disabled={true}
          label={nameFiled}
          variant='filled'
          name='email'
          value={info}
          onChange={e => funtion(e)}
          sx={{
            width: { sm: '48%' },
            flex: { sm: '1 1 308px', xs: '1 1 100%' },
            boxSizing: 'border-box',
            bgcolor: 'rgba(255, 255, 255, 1)',
            'label[data-shrink=false]+.MuiInputBase-formControl .css-10botns-MuiInputBase-input-MuiFilledInput-input':
              {
                background: '#fff',
              },
          }}
        />
      ) : nameFiled === 'Telefono' ? (
        <TextField
          disabled={!isEditContactData}
          label={nameFiled}
          variant='filled'
          name='telephone'
          helperText={errorPhone ? 'Número invalido' : ''}
          error={errorPhone}
          value={info}
          onChange={e => funtion(e)}
          sx={{
            width: { sm: '48%' },
            flex: { sm: '1 1 308px', xs: '1 1 100%' },
            boxSizing: 'border-box',
            bgcolor: 'rgba(255, 255, 255, 1)',
            'label[data-shrink=false]+.MuiInputBase-formControl .css-10botns-MuiInputBase-input-MuiFilledInput-input':
              {
                background: '#fff',
              },
          }}
        />
      ) : nameFiled === 'Calle y número' ? (
        <TextField
          helperText={errorAddress ? ' Ingresa tu dirección' : ''}
          error={errorAddress}
          disabled={!isEditContactData}
          label={nameFiled}
          variant='filled'
          name='address'
          value={info}
          onChange={e => funtion(e)}
          sx={{
            width: { sm: '48%' },
            flex: { sm: '1 1 308px', xs: '1 1 100%' },
            boxSizing: 'border-box',
            bgcolor: 'rgba(255, 255, 255, 1)',
            'label[data-shrink=false]+.MuiInputBase-formControl .css-10botns-MuiInputBase-input-MuiFilledInput-input':
              {
                background: '#fff',
              },
          }}
        />
      ) : null}
    </>
  );
};
