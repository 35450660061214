import { Button, Stack, Typography, styled } from '@mui/material';
import React from 'react';

const SectionNoProduct = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 0px 6.828890800476074px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2px 11.706669807434082px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  flex: '1',
  margin: '0px',
  height: '100%',
  width: '100%',
  minHeight: '218px',
});

const ContentNoProduct = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  height: '120px',
  width: '100%',
});
const IconsNoProduct = styled('img')({
  height: '120px',
  width: '140px',
  objectFit: 'cover',
  margin: '0px',
});
const BodyTextNoProduct = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px 0px 0px 6px',
});

const TitleNoProduct = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: '#182849',
  fontFamily: 'Poppins',
  fontWeight: '700',
  letterSpacing: '-0.39022237062454224px',
  textDecoration: 'none',
  lineHeight: '30px',
  textTransform: 'none',
  margin: '0px',
});

const ParagraphNoProduct = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(143, 141, 141, 1)',
  fontFamily: 'Poppins',
  fontWeight: '500',
  letterSpacing: '-0.39022237062454224px',
  textDecoration: 'none',
  lineHeight: '12.962230682373047px',
  textTransform: 'none',
  margin: '7.804447174072266px 0px 0px 0px',
});

const BtnLoadWallet = styled(Button)({
  backgroundColor: 'rgba(30, 34, 170, 1)',
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  boxSizing: 'border-box',
  margin: '7.804447174072266px 0px 0px 0px',
  height: '100%',
  width: '100%',
  color: '#fff',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#4449D8',
  },
});

const SectionNoProductComponent = ({ handleRedirect }) => {
  return (
    <SectionNoProduct>
      <ContentNoProduct>
        <IconsNoProduct src='/assets/images/dashboard/noProducts.gif' alt={'Cargando Product'} />
        <BodyTextNoProduct>
          <TitleNoProduct fontSize={'clamp(12px, 5vw, 32px)'}>
            Invierte como los que saben
          </TitleNoProduct>
          <ParagraphNoProduct fontSize={'clamp(12px, 2vw, 16px)'}>
            En Xcala, los activos alternativos son para todos
          </ParagraphNoProduct>
          <BtnLoadWallet
            onClick={() => handleRedirect()}
            variant='text'
            sx={{ fontSize: 'clamp(12px, 3vw, 16px)' }}>
            Visita nuestro Blog
          </BtnLoadWallet>
        </BodyTextNoProduct>
      </ContentNoProduct>
    </SectionNoProduct>
  );
};

export default SectionNoProductComponent;
