import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';

const AvatarContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  height: '100%',
  maxHeight: '120px',
  minHeight: '90px',
  width: '100%',
  maxWidth: '122px',
  minWidth: '92.83px',
});

const AvatarWrap = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
  minHeight: '90px',
  minWidth: '92.83px',
  margin: '0px',
});

const AvatarIcon = styled('img')({
  width: '96px',
  height: '96px',
});

const ProfileIcons = styled('img')({
  width: '50px',
  height: '50px',
  position: 'absolute',
  left: '70px',
  top: '55px',
});

const handlePerfil = riskProfile => {
  return riskProfile === 'conservador'
    ? '/assets/images/dashboard/perfiles/conservador.svg'
    : riskProfile === 'moderado'
      ? '/assets/images/dashboard/perfiles/moderado.svg'
      : '/assets/images/dashboard/perfiles/agresivo.svg';
};

function AvatarDashboard({ riskProfile }) {
  return (
    <AvatarContainer>
      <AvatarWrap>
        <AvatarIcon src='/assets/images/dashboard/Avatar.svg' loading='lazy' alt='avatar' />
        <ProfileIcons src={handlePerfil(riskProfile)} loading='lazy' alt={'Iconos Perfile'} />
      </AvatarWrap>
    </AvatarContainer>
  );
}

export default AvatarDashboard;
