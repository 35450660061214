import { Button, Stack, Typography, styled } from '@mui/material';

const BtnPrimary = styled(Button)({
  borderRadius: '8px',
  padding: '8px 32px',
  backgroundColor: '#1E22AA',
  color: '#fff',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#151774',
  },
});

const IconsDone = styled('img')({
  width: '24px',
  height: '24px',
  margin: '0px',
});

const btnPadding = (size = 'md') => (size === 'md' ? '16px 32px' : size === 'xs' && '8px 16px');

export const ButtonsPrimary = ({
  name,
  sx,
  onClick,
  src,
  fontSize = '16px',
  disabled = false,
  size = 'md',
  ...props
}) => {
  return (
    <Button
      disableElevation
      variant='contained'
      onClick={onClick}
      disabled={disabled}
      sx={{
        ...sx,
        borderRadius: '8px',
        padding: btnPadding(size),
        color: '#fff',
        textTransform: 'none',
        transition: 'all 0.5s ease-out',
      }}
      {...props}>
      <Stack
        flexDirection={'row'}
        gap={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {src && <IconsDone src={src} />}
        <Typography color='inherit' fontSize={fontSize}>
          {name}
        </Typography>
      </Stack>
    </Button>
  );
};

export const ButtonsSecundary = ({
  name,
  sx,
  onClick,
  src,
  fontSize = '16px',
  disabled = false,
  size = 'md',
  ...props
}) => {
  return (
    <Button
      disableElevation
      variant='outlined'
      onClick={onClick}
      disabled={disabled}
      sx={{
        ...sx,
        borderRadius: '8px',
        padding: btnPadding(size),
        //color: '#fff',
        //backgroundColor: '#1E22AA',
        textTransform: 'none',
        transition: 'all 0.5s ease-out',
        '&:hover': {
          backgroundColor: '#4449D8',
          color: '#fff',
        },
      }}
      {...props}>
      <Stack
        flexDirection={'row'}
        gap={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {src && <IconsDone src={src} />}
        <Typography color='inherit' fontSize={fontSize}>
          {name}
        </Typography>
      </Stack>
    </Button>
  );
};

export const ButtonsTertiary = ({
  name,
  sx,
  onClick,
  src,
  fontSize = '16px',
  disabled = false,
  size = 'md',
  imgUrl,
  style,
  ...props
}) => {
  return (
    <Button
      disableElevation
      variant='text'
      onClick={onClick}
      disabled={disabled}
      sx={{
        ...sx,
        borderRadius: '8px',
        padding: btnPadding(size),
        color: '#1E22AA',
        backgroundColor: '#F4F4FB',
        textTransform: 'none',
        transition: 'all 0.5s ease-out',
        '&:hover': {
          backgroundColor: '#DDDDF5',
        },
      }}
      {...props}>
      <Stack
        flexDirection={'row'}
        gap={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {src && <IconsDone src={src} />}
        {name === 'Reinvertir Dividendos' && <img src={imgUrl} alt={name} style={{ ...style }} />}
        <Typography color='inherit' fontSize={fontSize}>
          {name}
        </Typography>
      </Stack>
    </Button>
  );
};
