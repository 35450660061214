import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Stack, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleRiskProfile } from '../../../utils/riskProfile';
import { ButtonsPrimary, ButtonsSecundary } from '../../ComponentApp/buttoms';
import { AvatarInvertedPortfolio } from '../../XcalaComponents/AvatarInvertedPortfolio';
import {
  AreaButtons,
  BackgroundColor,
  BottonsIconosPerfile,
  ButtonsInvestment,
  ButtonsInvestmentSecondary,
  ContentApp,
  IconInfo,
  RentabilidadUltimo,
  RetornoEsperado,
  SectionButtons,
} from '../../XcalaComponents/PorfolioComponents';
import WrapperBackgroundBanner from '../../XcalaComponents/WrapperBackgroundBanner';
import WrapperContentApp from '../../XcalaComponents/WrapperContentApp';
import { HeadContainerCardPorfolio } from '../HeadContainerCardPorfolio';

const $minHeigth = '407px';

const BtnGroupContainer = styled(Stack)({
  position: 'absolute',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'flex-end',
  boxSizing: 'border-box',
  bottom: '30px',
  right: '0',
});

const BtnGroup = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '8px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '16px',
  boxSizing: 'border-box',
  width: '100%',
  margin: '0px',
});
const BtnContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
});
const ButtonPrimary = styled(Button)({
  flex: '1',
  height: '50px',
  margin: '0px',
  color: 'rgba(30, 34, 170, 1)',
  borderColor: 'rgba(30, 34, 170, 1)',
  textTransform: 'none',
  '&:hover': {
    borderColor: 'rgba(30, 34, 170, 1)',
  },
});
const ButtonSecondary = styled(Button)({
  flex: '1',
  height: '50px',
  backgroundColor: 'rgba(30, 34, 170, 1)',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(30, 34, 170, 1)',
  },
});

const ButtonReInvestment = styled(Button)({
  border: '2px solid rgba(30, 34, 170, 1)',
  flex: '1',
  height: '50px',
  margin: '0px',
  color: 'rgba(30, 34, 170, 1)',
  borderColor: 'rgba(30, 34, 170, 1)',
  textTransform: 'none',
  '&:hover': {
    borderColor: 'rgba(30, 34, 170, 1)',
    border: '2px solid rgba(30, 34, 170, 1)',
  },
});

const HeaderDetailsPorfolio = ({
  version = { md: 'start', xs: 'center' },
  textAlination = { md: 'left', xs: 'center' },
  iconAlineation = { md: 'left', xs: 'center' },
  dataDetailsPortfolioId,
  portfolioDetailsFileAs,
  handleInvestMore,
  handleRescueInvestment,
  idSelected,
  fileAs,
  ModelId,
  visible,
  statusDividendFinfolio,
  handleClose,
  handleOpen,
  simulation = ' Rescatar Inversión',
  investmentMore = ' Invertir Más',
}) => {
  const [userNotOB, setUserNotOB] = useState(false);
  const user = useSelector(state => state.auth.user);
  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';
  const navigate = useNavigate();

  const goToSimulator = id => {
    navigate(`/portfolio-simulation/${id}`);
  };

  const goToInvestment = id => {
    userNotOB
      ? navigate(`/portfolio-simulation/${id}/investment`)
      : navigate('/completeregistration');
  };

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setUserNotOB(true);
    }
  }, [user]);
  return (
    <>
      <WrapperBackgroundBanner $minHeigth={$minHeigth}>
        <WrapperContentApp $minHeigth={$minHeigth} fileAs={fileAs}>
          <AvatarInvertedPortfolio porfolioDetailHeader={fileAs} />
          {fileAs.profit && (
            <Stack direction={'column'}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={{ md: 'end', xs: 'center' }}
                marginTop={{ md: '-40px', xs: '8px' }}>
                <RentabilidadUltimo fontSize={{ md: '40px', xs: '30px' }}>
                  {fileAs.profit} %{' '}
                </RentabilidadUltimo>
                <IconInfo src='/assets/images/perfiles/info.svg' />
              </Stack>
              <RetornoEsperado textAlign={{ md: 'end', xs: 'center' }}>
                Rentabilidad Esperada
              </RetornoEsperado>
            </Stack>
          )}
        </WrapperContentApp>
      </WrapperBackgroundBanner>

      <BtnGroupContainer zIndex={10}>
        <Stack
          width={{ md: '90%', xs: '90%' }}
          maxWidth={{ md: '1380px', xs: '100%' }}
          marginX={'auto'}
          justifyContent={'center'}
          alignItems={{ md: 'flex-end', xs: 'center' }}
          flex={'1 1 auto'}>
          <BtnGroup maxWidth={{ md: '650px', xs: '80%' }}>
            <BtnContainer flexDirection={{ md: 'row', xs: 'column' }} gap={2}>
              <ButtonsSecundary
                name={simulation}
                onClick={() => goToSimulator(dataDetailsPortfolioId[0].idModels)}
                fullWidth
              />
              <ButtonsPrimary
                name={investmentMore}
                onClick={() => goToInvestment(dataDetailsPortfolioId[0].idModels)}
                fullWidth
              />
            </BtnContainer>
            {statusDividendFinfolio && (
              <Stack width={'100%'} marginTop={1}>
                {fileAs.products === true &&
                  (!visible && statusDividendFinfolio.length > 0 ? (
                    <ButtonReInvestment
                      variant='outlined'
                      endIcon={<KeyboardArrowRightIcon />}
                      fullWidth
                      style={{ textTransform: 'none' }}
                      onClick={handleOpen}
                      sx={{
                        borderRadius: '4px',
                        borderColor: '#1e22aa',
                        color: '#1e22aa',
                        background: '#F4F4FB',
                        textTransform: 'none',
                        fontSize: '16px',
                        ':hover': {
                          background: '#F4F4FB',
                          borderColor: '#1e22aa',
                        },
                      }}>
                      <img
                        style={{
                          verticalAlign: 'middle',
                          marginRight: '5px',
                          color: 'red',
                        }}
                        src='/assets/images/edit.svg'
                        alt='edit'
                      />
                      Reinvertir Dividendos
                    </ButtonReInvestment>
                  ) : statusDividendFinfolio.length > 0 ? (
                    <ButtonReInvestment
                      startIcon={<CloseIcon />}
                      endIcon={<KeyboardArrowDownIcon />}
                      variant='outlined'
                      fullWidth
                      style={{ textTransform: 'none' }}
                      onClick={handleClose}
                      sx={{
                        borderRadius: '4px',
                        borderColor: '#1e22aa',
                        color: '#1e22aa',
                        background: '#F4F4FB',
                        textTransform: 'none',
                        fontSize: '16px',
                        ':hover': {
                          background: '#F4F4FB',
                          borderColor: '#1e22aa',
                        },
                      }}>
                      Cancelar
                    </ButtonReInvestment>
                  ) : null)}
              </Stack>
            )}
          </BtnGroup>
        </Stack>
      </BtnGroupContainer>
    </>
  );
};

export default HeaderDetailsPorfolio;
