import { Divider, Stack, Typography, styled } from '@mui/material';
import { handleRiskProfile } from '../../../utils/riskProfile';
import {
  ButtonsIconsProfile,
  CardsInfo,
  ExpectedTime,
  ReantabilityTitle,
  RentabilityValue,
  TitleCardContainer,
} from '../../XcalaComponents';
import { CardsButtomGroup } from '../../XcalaComponents/CardsButtomGroup';
import { TypeOfCurrency } from '../../XcalaComponents/TypeOfCurrency';
import { CardsDescription } from './CardsDescription';
import { InterestLabel } from './InterestLabel';
import { MotivationLabel } from './MotivationLabel';

const BodyContainer = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '8px 16px 16px 16px',
  boxSizing: 'border-box',
  height: '100%',
});

export const BodyCardsContainer = ({
  profileCards,
  typeCurrency,
  intereses,
  motivation,
  rentabilityFound,
  foundTerm,
  motivationsId,
  interestsId,
  goToProductDetail,
  goToSimulator,
}) => {
  return (
    <BodyContainer gap={2}>
      <Stack gap={1} width={'100%'}>
        <TitleCardContainer sx={{ marginTop: '20px' }}>
          <ButtonsIconsProfile src={handleRiskProfile(profileCards.toLowerCase())} />
          <CardsInfo>
            <ReantabilityTitle fontSize={'clamp(0.96rem, 2vw, 1.2rem)'}>
              Retorno esperado
            </ReantabilityTitle>
            <RentabilityValue>{`${rentabilityFound}%`}</RentabilityValue>
            <ExpectedTime>(Anualizado a largo plazo)</ExpectedTime>
          </CardsInfo>
        </TitleCardContainer>
        <Divider variant='fullWidth' width='100%' />
        <Stack flexDirection={'row'} gap={1} flexWrap={'wrap'}>
          <InterestLabel intereses={intereses} interestsId={interestsId} />
          <MotivationLabel motivation={motivation} motivationsId={motivationsId} />
        </Stack>
      </Stack>
      <TypeOfCurrency typeCurrency={typeCurrency} />

      <CardsButtomGroup
        goToSimulator={goToSimulator}
        goToInvestment={goToSimulator}
        goToProductDetail={goToProductDetail}
        marginTop={'20px'}
      />
    </BodyContainer>
  );
};
