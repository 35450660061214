import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ButtonsPrimary } from "../../components/ComponentApp/buttoms/ButtonsXcala";
import SignInSignUpLayout from "../../components/Layout/SignInSignUpLayout/SignInSignUpLayout";
import Spinner from "../../components/Spinner";
import { confirmSignUp } from "../../redux/auth";

const ConfirmAccountSuccess = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const code = searchParams.get("code");
  const email = searchParams.get("email");

  const { errorConfirmSignUp,loadingConfirmSignUp } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (code && email) {
      dispatch(confirmSignUp({ username: email, confirmationCode: code }));
    } 
    
  }, [code, email]);

  const navigate = useNavigate();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "confirmAccountSuccess",
        email: email,
        path: "/confirmAccountSuccess",
        link: {
          url: '/confirmAccountSuccess',
          title: "confirmAccountSuccess",
        },
      },
    });
  }, []);


  if(loadingConfirmSignUp) {
    return  <Spinner />
  }

  return (
    <Stack
      sx={{
        boxSizing: "border-box",
        width: "100%",
        minHeight: "100vh",
        position: "relative",
        overflow: "hidden",
        padding: 0,
        //overscrollBehaviorX: 'contain',
      }}
    >
      <SignInSignUpLayout>
        <Stack
          sx={{
            background: "#fff",
            boxSizing: "border-box",
            //width: '80%',
            borderRadius: "24px",
            padding: {xs:"32px", md:"50px 80px"},
            position: "relative",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.15)",
            minHeight: "300px",
            zIndex: 4,
            justifyContent: "center",
            alignItems: "center",
            gap: 5,
          }}
          width={{ xs: "90%", sm: "80%", md: "80%" }}
          maxWidth={{ xs: "90%", md: "650px" }}
          minWidth={{ xs: "40%" }}
        >
          <Stack
            textAlign={"center"}
            width={"90%"}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Stack width={"100px"} height={"100px"} position={"relative"}>
              <img
                src= {errorConfirmSignUp === "El dni ya está registrado" ? "/assets/images/error.gif" :"/assets/images/sucess-gif.gif"}
                alt="success"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  top: "50%",
                  left: "50%",
                  objectFit: "cover",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </Stack>
            <Typography
              component="h3"
              sx={{
                fontSize: "1.7rem",
                fontWeight: 300,
                color: errorConfirmSignUp === "El dni ya está registrado" ? "#f44336" :"#347856",               
              }}
            >
             {errorConfirmSignUp === "El dni ya está registrado"? "Ups parece que ocurrio un error" : "¡Cuenta confirmada exitosamente!"}
            </Typography>

            <Typography
              textAlign={"left"}
              variant="body1"
              sx={{ color: "#182849", fontWeight: 300, fontSize: "16px" }}
            >
             {errorConfirmSignUp === "El dni ya está registrado" ? <>         
             El rut ingresado ya está registrado. Por favor revísalo o ingresa a tu cuenta ya creada. Si tienes problemas para recuperar tu cuenta, envíanos un correo a <Typography fontWeight={500} color={"primary"}>contacto@xcala.com</Typography></>:  "Ahora puedes ingresar a tu cuenta de Xcala."}
            </Typography>
          </Stack>
          
          <ButtonsPrimary
            name={errorConfirmSignUp === "El dni ya está registrado" ? "Volver al registro" :"Ingresar a Xcala"}
            fullWidth
            onClick={() => navigate(errorConfirmSignUp === "El dni ya está registrado"? "/signup" : "/signin", { replace: true})}
          />
        </Stack>
      </SignInSignUpLayout>
    </Stack>
  );
};

export default ConfirmAccountSuccess;
