import { Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ChartDoughnutXcala from '../../XcalaComponents/ChartDoughnutXcala';
import {
  ChartsPorfolio,
  ContentApp,
  DataCharts,
  DescriptionDataPorfo,
  InfoPorfolio,
  SectionDataPorfolio,
  TitelDataPorfolio,
} from '../../XcalaComponents/PorfolioComponents';
import CardsItemDescription from '../CardsItemDescription';
import DescriptionComponentPortfolio from './DescriptionComponentPortfolio';
import DistributionsValue from './DistributionsValue';
import { BtnGroup } from './distributionFunds/BtnGroup';

const CardsContentDocumentacion = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '16px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  minWidth: '310px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  overflow: 'hidden',
  height: '188px',
});
const HeaderComponent = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '8px 16px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '80px',
  width: '100%',
  flexDirection: 'column',
});
const TitleCardsDocumentation = styled(Typography)({
  whiteSpace: 'pre-wrap',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '24px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '105%',
  textTransform: 'none',
});
const SubTitleCardsDocumentation = styled(Typography)({
  whiteSpace: 'pre-wrap',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '14px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '105%',
  textTransform: 'none',
});

const BodyComponent = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  height: '104px',
  margin: '8px 0px 0px 0px',
  width: '100%',
});

const BodyDetailsPorfolio = ({ dataDetailsPortfolioId, portfolioDetailsFileAs }) => {
  const handleHexToRgba = hex => {
    const r = Number.parseInt(hex.substring(1, 3), 16);
    const g = Number.parseInt(hex.substring(3, 5), 16);
    const b = Number.parseInt(hex.substring(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.2)`;
  };
  const [userNotOB, setUserNotOB] = useState(false);
  const { user } = useSelector(state => state.auth);

  const navigate = useNavigate();
  const dataDetailsPortfolio = dataDetailsPortfolioId;

  const goToSimulator = id => {
    navigate(`/portfolio-simulation/${id}`);
  };
  const goToInvestment = id => {
    userNotOB
      ? navigate(`/portfolio-simulation/${id}/investment`)
      : navigate('/completeregistration');
  };

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setUserNotOB(true);
    }
  }, [user]);
  return (
    // <ContentApp sx={{ flexDirection: "column", gap: "50px" }}>

    <Stack
      sx={{
        width: '90%',
        // marginTop: "48px",
        borderRadius: '16px',
        position: 'relative',
        marginX: 'auto',
        isolation: 'isolate',
        marginBottom: '4rem',
      }}
      gap={4}>
      <Stack marginBottom={2}>
        <Typography
          sx={{
            color: 'rgba(24, 40, 73, 1)',
            fontSize: 'clamp(1.4rem, 0.5813rem + 1.5vw, 1.5rem)',
            fontWeight: '500',
            marginBottom: 1,
          }}>
          Descripción
        </Typography>
        <Typography
          variant='body1'
          component='p'
          margin={{ md: '0', xs: '0' }}
          style={{ textAlign: 'left' }}>
          Este portfolio fue generado mediante la metodología de Resampled efficient frontier, con
          el objetivo de buscar la combinación óptima de fondos de acuerdo al perfil de riesgo.
        </Typography>
      </Stack>

      <SectionDataPorfolio
        minHeight={'400px'}
        gap={4}
        flexDirection={{ md: 'row', sm: 'column', xs: 'column' }}
        justifyContent={{ md: 'space-between', xs: 'center' }}
        alignItems={{ md: 'center', xs: 'center' }}
        padding={{ md: '0', xs: '0 2rem' }}>
        <ChartsPorfolio height={'100%'} width={'50%'} maxWidth={'450px'} maxHeight={'450px'}>
          <DataCharts height={'100%'} width={'100%'}>
            <ChartDoughnutXcala dataChart={dataDetailsPortfolio[0].dataChart} />
          </DataCharts>
        </ChartsPorfolio>

        <DistributionsValue dataDetailsPortfolio={dataDetailsPortfolio} />
      </SectionDataPorfolio>

      <InfoPorfolio width={{ md: '100%', xs: '90%' }}>
        <TitelDataPorfolio>Condiciones generales</TitelDataPorfolio>
        <DescriptionDataPorfo gap={2}>
          <DescriptionComponentPortfolio
            KeyDescriptions='Moneda:'
            KeyValues={portfolioDetailsFileAs.currency}
          />
          <DescriptionComponentPortfolio KeyDescriptions='Duración:' KeyValues='Indefinido' />
          <DescriptionComponentPortfolio
            KeyDescriptions='Plazo de rescate:'
            KeyValues='Según reglamento de los fondos'
          />
          <DescriptionComponentPortfolio
            KeyDescriptions='Comisión de salida:'
            KeyValues='Según reglamento de los fondos'
          />
        </DescriptionDataPorfo>
      </InfoPorfolio>

      <Stack
        width={'100%'}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingY: { md: '40px', xs: '20px' },
        }}>
        <BtnGroup
          goToSimulator={() => goToSimulator(dataDetailsPortfolioId[0].idModels)}
          goToInvestment={() => goToInvestment(dataDetailsPortfolioId[0].idModels)}
        />
      </Stack>

      <Stack
        padding={2}
        width={{ xs: '90%', md: '100%' }}
        borderRadius={2}
        marginBottom={18}
        flexDirection={'row'}
        gap={2}
        flexWrap={'wrap'}
        justifyContent={'flex-start'}
        alignItems={'center'}>
        {dataDetailsPortfolio[0].fund.map((fundName, index) => {
          const colores = dataDetailsPortfolio[0].dataChart.datasets[0].backgroundColor;
          const color = colores[index];
          return (
            <CardsContentDocumentacion
              width={{
                xs: '100%',
                sm: '100%',
                md: '48%',
                lg: '31.5%',
                xl: '32.5%',
              }}>
              <HeaderComponent
                flexDirection={'column'}
                gap={0}
                p={2}
                backgroundColor={handleHexToRgba(color)}>
                <TitleCardsDocumentation>{fundName.name}</TitleCardsDocumentation>

                <SubTitleCardsDocumentation>Documentación de interés</SubTitleCardsDocumentation>
              </HeaderComponent>

              <BodyComponent>
                <CardsItemDescription width={'100%'} customData={fundName} />
              </BodyComponent>
            </CardsContentDocumentacion>
          );
        })}
      </Stack>
    </Stack>

    //</ContentApp>
  );
};

export default BodyDetailsPorfolio;
