import {
  Box,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { numberWithPoint, formatLossGainValue } from '../../../utils/utils';
import Spinner from '../../Spinner';
import ChartComponentInvestmentContainer from '../../XcalaComponents/ChartComponentInvestmentContainer';
import {
  CondicionesGenerales,
  ContentTitleTable,
  DistributionValue,
  Divaider,
  GeneralConditionBody,
  GeneralConditionCard,
  GeneralConditionFooter,
  GeneralConditionHead,
  HeadContainer,
  HeadTitleDistribution,
  HeadTitleDistributionSpan,
  ItemContentDescription,
  ItemDescription,
  SectionDistribucion,
  SectionGeneralCondit,
  TitleItemContent,
  TitleItemFooter,
  TitleTableName,
  ValueItemContent,
  ValueItemFooter,
} from './PortfolioInvestmentComponents';
import { TabletInverted } from './TabletInverted';

const colores = {
  'HMC Portafolio Óptimo': '#8612FF',
  'HMC Deuda Privada Pesos': '#160BE3',
  'HMC Renta Global Pesos': '#0060FA',
  'HMC Global Real Estate Income': '#0BBAE3',
  'HMC US Venture Opportunities': '#0DFBBD',
  'HMC Deuda Privada Global': '#D98DEB',
  'HMC Renta Global USD': '#FFAF9C',
  Caja: 'rgba(30, 34, 170, 1)',
  // ...asocia cada security con un color
};
const BodyInvertedPortfolio = ({
  successGetPortfolioById,
  loadingGetSecurityPriceRangePortfolio,
  successGetSecurityPriceRangePortfolio,
  doGetSecurityPriceRangePortfolio,
  dataGetSecurityPriceRangePortfolio,
  successGetPortfolioTransaction,
  loadingPortfolioTransaction,
}) => {
  const [value, setValue] = useState(0);
  const summaryDetails = successGetPortfolioById.find(
    porfolio => porfolio.id === '' && porfolio.name === '' && porfolio.security === '',
  );

  const distributioInvestmentProducts = successGetPortfolioById.filter(porfolio =>
    porfolio.security.includes('HMC'),
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loadingPortfolioTransaction) {
    return <Spinner />;
  }
  const completedTransactions = successGetPortfolioTransaction
    .filter(item => item.Status === 4 && item.SubType !== 'LO' && item.SubType !== 'LI')
    .sort((a, b) => new Date(b.tradeDate) - new Date(a.tradeDate));

  const pendingTransactions = successGetPortfolioTransaction
    .filter(
      item =>
        item.Status === 2 || (item.Status === 1 && item.SubType !== 'LO' && item.SubType !== 'LI'),
    )
    .sort((a, b) => new Date(b.tradeDate) - new Date(a.tradeDate));

  return (
    <Stack
      sx={{
        width: '90%',
        marginTop: '48px',
        borderRadius: '16px',
        position: 'relative',
        marginX: 'auto',
        isolation: 'isolate',
        marginBottom: '4rem',
      }}
      gap={4}>
      <Stack sx={{ width: '100%' }} gap={2} flexDirection={{ md: 'row', xs: 'column' }}>
        <Stack
          flex={'1 0.5 auto'}
          sx={{
            width: '100%',
            borderRadius: '8px',
          }}>
          <ChartComponentInvestmentContainer
            doGetSecurityPriceRangePortfolio={doGetSecurityPriceRangePortfolio}
            loadingGetSecurityPriceRangePortfolio={loadingGetSecurityPriceRangePortfolio}
            successGetSecurityPriceRangePortfolio={successGetSecurityPriceRangePortfolio}
            dataGetSecurityPriceRangePortfolio={dataGetSecurityPriceRangePortfolio}
          />
        </Stack>

        <Stack
          flex={'1 1 auto'}
          sx={{
            backgroundColor: '#fff',
            width: '100%',
            height: '100%',
            minWidth: { md: '400px', xs: '100%' },
          }}>
          <SectionGeneralCondit>
            <GeneralConditionCard>
              <GeneralConditionHead>
                <CondicionesGenerales>Condiciones Generales</CondicionesGenerales>
              </GeneralConditionHead>

              <GeneralConditionBody>
                <ItemContentDescription>
                  <TitleItemContent>Tienes</TitleItemContent>
                  <ValueItemContent>
                    $ {numberWithPoint(Math.round(Number.parseFloat(summaryDetails.marketValue)))}
                  </ValueItemContent>
                </ItemContentDescription>
                <ItemContentDescription>
                  <TitleItemContent>Dividendos</TitleItemContent>
                  <ValueItemContent>
                    $ {numberWithPoint(Math.round(summaryDetails.dividends))}
                  </ValueItemContent>
                </ItemContentDescription>
                <ItemContentDescription>
                  <TitleItemContent>Rescates</TitleItemContent>
                  <ValueItemContent>
                    {summaryDetails.sell === 0
                      ? '$ 0'
                      : `- $ ${numberWithPoint(Math.abs(Math.round(summaryDetails.sell)))}`}
                  </ValueItemContent>
                </ItemContentDescription>
                <Divaider />
                <ItemContentDescription>
                  <TitleItemContent>Total Depositado</TitleItemContent>
                  <ValueItemContent>
                    $ {numberWithPoint(Math.round(summaryDetails.buy))}
                  </ValueItemContent>
                </ItemContentDescription>
                <ItemContentDescription>
                  <TitleItemContent>Comisión de salida:</TitleItemContent>
                  <ValueItemContent>Según reglamento fondos</ValueItemContent>
                </ItemContentDescription>
                <ItemContentDescription>
                  <TitleItemContent>Plazo de rescate:</TitleItemContent>
                  <ValueItemContent>Según reglamento fondos</ValueItemContent>
                </ItemContentDescription>
              </GeneralConditionBody>

              <GeneralConditionFooter>
                <ItemContentDescription>
                  <TitleItemFooter
                    color={'#fff'}
                    fontSize={'clamp(0.75rem, 0.5813rem + 1.5vw, 1.125rem'}>
                    Ganancia / Pérdida:
                  </TitleItemFooter>
                  <ValueItemFooter
                    color={'#fff'}
                    fontSize={'clamp(0.75rem, 0.5813rem + 1.5vw, 1.125rem'}>
                    {formatLossGainValue(summaryDetails.capitalAppreciation)}
                  </ValueItemFooter>
                </ItemContentDescription>
              </GeneralConditionFooter>
            </GeneralConditionCard>
          </SectionGeneralCondit>
        </Stack>
      </Stack>

      {/*Distribución de % de fondos Portafolio  */}
      <Stack
        sx={{
          width: '100%',
          overflow: 'hidden',
          borderRadius: '8px',
        }}>
        <DistributionValue>
          <HeadContainer sx={{ maxWidth: { xs: '300px', md: '370px' } }}>
            <HeadTitleDistribution>
              Distribución de tus{' '}
              <HeadTitleDistributionSpan variant='span'>inversiones</HeadTitleDistributionSpan>
            </HeadTitleDistribution>
          </HeadContainer>
          <SectionDistribucion>
            {distributioInvestmentProducts.map(product => {
              return <ItemDescription key={product.id} product={product} colores={colores} />;
            })}
          </SectionDistribucion>
        </DistributionValue>
      </Stack>

      <Stack
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: '8px',
          display: 'flex',
          position: 'relative',
          isolation: 'isolate',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '32px',
          boxSizing: 'border-box',
          boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
        }}>
        <ContentTitleTable>
          <TitleTableName variant='h2'>Cartola de movimientos</TitleTableName>
        </ContentTitleTable>
        <Box sx={{ width: '100%', my: 2 }}>
          <Tabs
            onChange={handleChange}
            value={value}
            aria-label='Tabs where selection follows focus'
            selectionFollowsFocus>
            <Tab sx={{ flex: '1 1 auto' }} label='Realizados' />
            <Tab sx={{ flex: '1 1 auto' }} label='Pendientes' />
          </Tabs>
        </Box>
        {value === 0 && <TabletInverted transactions={completedTransactions} colores={colores} />}

        {value === 1 && <TabletInverted transactions={pendingTransactions} colores={colores} />}
      </Stack>
    </Stack>
  );
};

export default BodyInvertedPortfolio;
