import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SignInSignUpLayout from '../../../components/Layout/SignInSignUpLayout/SignInSignUpLayout';
import Spinner from '../../../components/Spinner';
import useRegisterUserQuestionsWithAnswer from '../../../hooks/useRegisterUserQuestionsWithAnswer';
import {
  resetUpdateUserAttributesSubmit,
  updateUserAtributes,
  updateUserAttributesSubmit,
} from '../../../redux/auth';
import capitalize from '../../../utils/capitalize';
import { contentAgresivo, contentConservador, contentModerado } from '../../../utils/constants';
import { IconsRiskProfile } from './ProfileSelect';

const TitlePrincipalMuseo = styled(Typography)({
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(255, 255, 255, 1)',
  fontStyle: 'normal',
  fontFamily: 'Museo-Bold',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});

const ProfileResultQuestions = () => {
  const [
    doRegisterUserQuestions,
    { data, error: errorRegisterUserQuestions, loading: loadingRegisterUserQuestions },
  ] = useRegisterUserQuestionsWithAnswer();

  const successRegisterUserQuestions = data?.registerUserQuestionsWithAnswer;
  const maxWidthContainer = '1200px';

  const { user, successUpdateUserAttributes, loadingUpdateUserAttributes } = useSelector(
    state => state.auth,
  );
  const { questions } = useSelector(state => state.question);
  const [onboardingSuccess, setOnboardingSuccess] = useState(false);
  const [responseQuestion, setResponseQuestion] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (successUpdateUserAttributes && !loadingUpdateUserAttributes) {
      dispatch(resetUpdateUserAttributesSubmit());
    }
  }, [loadingUpdateUserAttributes, successUpdateUserAttributes]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (questions) {
      doRegisterUserQuestions({
        variables: {
          data: Object.values(questions),
        },
      })
        .then(response => {
          const responseData = response.data;
          setResponseQuestion(responseData);
          dispatch(
            updateUserAttributesSubmit({
              name: 'custom:profile',
              value: responseData.registerUserQuestionsWithAnswer,
            }),
          );
        })
        .catch(error => {});
    } else {
      navigate('/questions');
    }
  }, [JSON.stringify(questions)]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (successRegisterUserQuestions && !loadingRegisterUserQuestions) {
      dispatch(
        updateUserAtributes({
          'custom:profile': successRegisterUserQuestions,
        }),
      );
    }
  }, [successRegisterUserQuestions, loadingRegisterUserQuestions]);

  useEffect(() => {
    if (errorRegisterUserQuestions && !loadingRegisterUserQuestions) {
      message.error('Ha ocurrido un error al guardar las respuestas');
    }
  }, [errorRegisterUserQuestions, loadingRegisterUserQuestions]);

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setOnboardingSuccess(true);
    }
  }, [user]);

  if (loadingRegisterUserQuestions) {
    return <Spinner />;
  }

  const questionResponse = responseQuestion?.registerUserQuestionsWithAnswer;

  return (
    <Stack
      sx={{
        boxSizing: 'border-box',
        width: '100%',
        minHeight: '100vh',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <SignInSignUpLayout questionResult={true}>
        <Stack
          sx={{
            boxSizing: 'border-box',
            borderRadius: '24px',
            padding: '24px',
            position: 'relative',
            zIndex: 4,
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '2rem',
            marginTop: '6rem',
          }}
          width={{ xs: '90%', sm: '40%', md: '40%' }}
          // marginTop="clamp(1.375rem, 14.3617rem + -21.2766vw, 1rem)"
          maxWidth={maxWidthContainer}>
          <Box>
            <IconsRiskProfile
              sx={{
                width: 'clamp(5.1875rem, 1.4375rem + 16vw, 10.4375rem);',
              }}
              src={`/assets/images/stepRisk/${userRisk.toLowerCase()}.svg`}
            />
          </Box>
          <Stack
            sx={{
              textAlign: 'center',
              color: '#fff',
              marginTop: '1rem',
              gap: 1,
            }}>
            <TitlePrincipalMuseo
              variant='h2'
              fontSize='clamp(1.6rem, -0.0714rem + 4.5714vw, 2.5rem);'>
              {capitalize(user.name)}
            </TitlePrincipalMuseo>
            <TitlePrincipalMuseo variant='h6'>Tu perfil de inversionista es</TitlePrincipalMuseo>
            <TitlePrincipalMuseo
              variant='h4'
              sx={{
                color: '#5AC4B1',
              }}>
              {capitalize(userRisk)}
            </TitlePrincipalMuseo>

            {questionResponse && (
              <TitlePrincipalMuseo
                sx={{
                  fontFamily: 'Poppins',
                }}>
                {user['custom:profile'].toLowerCase() === 'agresivo' && contentAgresivo}

                {user['custom:profile'].toLowerCase() === 'conservador' && contentConservador}

                {user['custom:profile'].toLowerCase() === 'moderado' && contentModerado}
              </TitlePrincipalMuseo>
            )}
          </Stack>
          <Stack direction={{ md: 'row', xs: 'column' }} gap={3} marginTop={4} width={'100%'}>
            {!onboardingSuccess && (
              <Button
                onClick={() => navigate('/completeregistration')}
                fullWidth
                sx={{
                  background: '#5AC4B1',
                  color: '#1e22aa',
                  textTransform: 'none',
                  padding: '12px 20px',
                  '&:hover': {
                    backgroundColor: '#5AC4B1',
                  },
                }}>
                Completar mi registro
              </Button>
            )}

            <Button
              onClick={() => navigate('/portfolio')}
              fullWidth
              sx={{
                background: '#1e22aa',
                color: '#fff',
                outline: '1px solid #fff',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#1e22aa',
                },
              }}>
              Ir a simular inversiones
            </Button>
          </Stack>
        </Stack>
      </SignInSignUpLayout>
    </Stack>
  );
};

export default ProfileResultQuestions;
