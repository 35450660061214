import { Button, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const TextDescription = styled(Typography)({
  textAlign: 'center',
  variant: 'body1',
  color: '#182849',
  fontWeight: 300,
  fontSize: '16px',
});

export const ErrorStepSign = ({ validationFailed }) => {
  const navigate = useNavigate();

  const onClickConditionSign = () => {
    return validationFailed ? navigate('/completeregistration') : navigate('/myprofilerisk');
  };

  return (
    <Stack
      textAlign={'center'}
      width={'90%'}
      gap={1}
      justifyContent={'center'}
      alignItems={'center'}>
      <Stack width={'100px'} height={'100px'}>
        <img
          src='/assets/images/error.gif'
          alt='error'
          style={{ width: '100%', height: '100%', display: 'block' }}
        />
      </Stack>
      <Typography component='h3' sx={{ fontSize: '1.9rem', fontWeight: 600, color: '#f44336' }}>
        ¡Oh no! Parece que algo salió mal
      </Typography>

      {validationFailed === 'validation_failed' ? (
        <TextDescription>
          El código ingresado parece ser inválido, por favor reintentalo
        </TextDescription>
      ) : (
        <TextDescription>
          Hemos tenido problemas para validar tu información, por favor escribenos un email a{' '}
          <Typography variant='inherit' component={'span'} sx={{ fontWeight: '500' }}>
            contacto@xcala.com
          </Typography>{' '}
          para poder solucionar este inconveniente
        </TextDescription>
      )}

      <Stack width={{ md: '80%', xs: '100%' }} marginTop={3}>
        <Button
          variant='contained'
          disableElevation
          sx={{
            padding: '12px 32px',
            textTransform: 'none',
          }}
          fullWidth
          onClick={() => onClickConditionSign()}>
          {validationFailed === 'validation_failed'
            ? 'Volver a Solicitar codigo'
            : ' Volver a inicio'}
        </Button>
      </Stack>
    </Stack>
  );
};
