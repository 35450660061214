import React from 'react';
import useRegisterUserFinfolio from '../hooks/useRegisterUserFinfolio';

export const withRegisterUserFinfolio = WrappedComponent => {
  return props => {
    const [doRegisterUserFinfolio, { data, error, loading }] = useRegisterUserFinfolio();

    return (
      <WrappedComponent
        {...props}
        successRegisterUserFinfolio={data?.registerUserFinfolio}
        errorRegisterUserFinfolio={error}
        loadingRegisterUserFinfolio={loading}
        doRegisterUserFinfolio={doRegisterUserFinfolio}
      />
    );
  };
};

export default withRegisterUserFinfolio;
