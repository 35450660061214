import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { numberWithPoint } from '../../utils/numbers';
import { ButtonsPrimary } from '../ComponentApp/buttoms';
import ModalWithdrawal from '../XcalaComponents/Notifications/ModalWithdrawal';

const BoxSectionPyment = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  //width: '178px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '5px 0px',
  boxSizing: 'border-box',
  //height: '37.48px',
});
const BoxContent = styled(Stack)({
  backgroundColor: '#D7FCF4',
  display: 'flex',
  borderRadius: '8px',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  width: '32px',
  height: '32px',
  margin: '0px',
});
const AtomoGraficaIconCaja = styled('img')({
  width: '24px',
  height: '24px',
  //position: 'absolute',
});
const MuntPymenValue = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(90, 196, 177, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});

const SelectRescueWithdrawal = ({
  balanceClient,
  amountRescue,
  valueSelect,
  handleAmountRescue,
  handleChange,
  clientBankDetail,
  handleOpenModalBank,
  handleOpenModal,
  isSubmitting,
}) => {
  const [showHelperText, setShowHelperText] = useState(false);

  const checkAmountRescue = () => {
    if (Number.parseInt(amountRescue) < 500) {
      setShowHelperText(true);
    } else {
      setShowHelperText(false);
    }
  };

  useEffect(() => {
    checkAmountRescue();
  }, [amountRescue]);

  return (
    <form style={{ width: '100%' }}>
      <FormControl sx={{ width: '100%' }} variant='standard'>
        <RadioGroup
          sx={{
            color: '#848484',
            '& .MuiSvgIcon-root': {
              color: '#1E22AA',
            },
          }}
          aria-labelledby='demo-error-radios'
          name='quiz'
          onChange={handleAmountRescue}
          value={valueSelect}>
          <FormControlLabel
            sx={{ color: '#848484' }}
            value={'50'}
            control={<Radio />}
            label='Retiro parcial'
          />
          <FormControlLabel
            sx={{ color: '#848484' }}
            value={'100'}
            control={<Radio />}
            label='Retiro total'
          />
        </RadioGroup>

        <TextField
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
          disabled={valueSelect === '100'}
          hiddenLabel
          id='filled-hidden-label-normal'
          variant='standard'
          value={numberWithPoint(amountRescue)}
          onChange={handleChange}
        />
        {showHelperText && (
          <FormHelperText error>El monto mínimo de retiro es de $500</FormHelperText>
        )}

        <BoxSectionPyment flexDirection={'row'} gap={0.5}>
          <BoxContent
            sx={{
              backgroundColor: '#rgba(90, 196, 177, 1)',
            }}>
            <AtomoGraficaIconCaja src='./assets/images/cashBox.gif' />
          </BoxContent>
          <MuntPymenValue
            sx={{
              color: '#rgba(90, 196, 177, 1)',
            }}
            fontSize={'1rem'}
            fontWeight={700}>{`$${numberWithPoint(
            balanceClient.balance,
          )} Disponible`}</MuntPymenValue>
        </BoxSectionPyment>

        {clientBankDetail?.getUserBankDetail?.bank?.bank_name ? (
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            alignItems={'center'}
            justifyContent={'space-around'}
            gap={{ md: 2, xs: 1 }}>
            <Stack>
              <Typography
                sx={{
                  mt: 1,
                  color: '#000000',
                  fontWeight: '300',
                  fontSize: '14px',
                }}>
                {`${clientBankDetail?.getUserBankDetail?.bank?.bank_name}`}
                {` - N° ${clientBankDetail?.getUserBankDetail?.account_number}`}
              </Typography>
            </Stack>
            <Stack>
              <Button
                onClick={() => handleOpenModalBank()}
                sx={{
                  marginTop: 1,
                  color: 'rgba(30, 34, 170, 1)',
                  ':hover': { backgroundColor: '#fff' },
                }}
                startIcon={<EditIcon />}>
                Editar Cuenta
              </Button>
            </Stack>
          </Stack>
        ) : (
          <ButtonsPrimary name='Agregar Cuenta' onClick={() => handleOpenModalBank()} mb='5px' />
        )}
      </FormControl>

      <Stack width={'100%'} justifyContent={'center'} alignItems={'center'} marginTop={3}>
        <Stack width={{ md: '50%', xs: '100%' }}>
          <ButtonsPrimary
            name='Retirar fondos de caja'
            onClick={() => handleOpenModal()}
            disabled={
              amountRescue === '' ||
              amountRescue <= 499 ||
              balanceClient.balance < Number.parseInt(amountRescue.replace(/\D/g, '')) ||
              amountRescue[0] === '0' ||
              clientBankDetail?.getUserBankDetail == null ||
              isSubmitting
            }
          />
        </Stack>
      </Stack>
    </form>
  );
};

export default SelectRescueWithdrawal;
