import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useModal from '../../hooks/custom/useModal';
import { onRefresh } from '../../utils/refreshPage';
import { numberWithPoint } from '../../utils/utils';
import { ButtonsPrimary } from '../ComponentApp/buttoms';
import Spinner from '../Spinner';
import ModalErrorFetch from '../UI/ModalErrorFetch';
import ChartDoughnutXcala from '../XcalaComponents/ChartDoughnutXcala';
import { ContributionsSectionTitleForm } from './SimulationComponents/ContributionsSectionTitleForm';
import {
  ChartComponent,
  ContainerForm,
  ContentFormContributions,
  TextStyled,
} from './SimulationComponents/SimulationMuiComponents';

const miOptions = {
  maintainAspectRatio: true, // Para permitir dimensiones personalizadas
  plugins: {
    legend: {
      display: false,
    },
  },
};

const SimulatorForm = ({
  isSimulatorFund,
  riskPortfolio,
  dataChart,
  isLoadingSimulation,
  formData,
  handleSubmit,
  send,
  errorSimulation,
}) => {
  const { isShowing, toggle } = useModal();

  let resultChartData;
  if (isSimulatorFund) {
    resultChartData = { labels: '', datasets: '' };
  } else {
    resultChartData = dataChart.dataChart;
  }

  const { labels, datasets } = resultChartData;

  const initialValues = {
    initialInvestment: '' || formData.initialInvestment,
    monthlyInvestment: '' || formData.monthlyInvestment,
    months: '' || formData.months,
  };

  const validationSchema = Yup.object({
    initialInvestment: Yup.string().required('El campo es requerido'),
    monthlyInvestment: Yup.string(),
    months: Yup.string().required('El campo es requerido'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validate: values => {
      const errors = {};
      if (isSimulatorFund) {
        if (
          !values.initialInvestment ||
          Number.parseInt(values.initialInvestment.replace(/\D/g, '')) < 10000 ||
          values.initialInvestment[0] === '0'
        ) {
          errors.initialInvestment = 'El monto debe ser mayor a $10.000';
        }
        if (Number.parseInt(values.monthlyInvestment?.replace(/\D/g, '')) < 10000) {
          errors.monthlyInvestment = 'El aporte mensual debe ser mayor a $10.000';
        }
      } else if (
        !values.initialInvestment ||
        Number.parseInt(values.initialInvestment.replace(/\D/g, '')) < 200000 ||
        values.initialInvestment[0] === '0'
      ) {
        errors.initialInvestment = 'El monto debe ser mayor a $200.000';
      }
      if (
        !isSimulatorFund &&
        Number.parseInt(values.monthlyInvestment?.replace(/\D/g, '')) < 200000
      ) {
        errors.monthlyInvestment = 'El aporte mensual debe ser mayor a $200.000';
      }

      return errors;
    },
  });

  const optionsYears = [
    isSimulatorFund ? { value: '6 meses' } : undefined,
    { value: '1 año' },
    isSimulatorFund ? { value: '2 años' } : undefined,
    { value: '3 años' },
    isSimulatorFund ? undefined : { value: '5 años' },
  ];

  if (isLoadingSimulation) {
    return (
      <>
        <Spinner />;
      </>
    );
  }

  if (errorSimulation) {
    return (
      <ModalErrorFetch
        message='Lo sentimos, algo no salio bien acá....'
        error={errorSimulation}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  }

  return (
    <ContentFormContributions
      sx={{
        maxWidth: !isSimulatorFund ? '1080px' : '720px',
        marginBottom: '32px',
      }}>
      <ContainerForm
        sx={{
          flexDirection: !isSimulatorFund ? { md: 'row', xs: 'column' } : 'column',
          alignItems: 'center',
        }}>
        {!isSimulatorFund && (
          <Box
            sx={{
              width: { md: 'calc(40% - 16px)' },
              boxSizing: 'border-box',
              display: 'flex',
              gap: '16px',
              flexDirection: 'column',
            }}>
            <ContributionsSectionTitleForm
              textTitle={
                isSimulatorFund
                  ? 'Simula tu inversión en el fondo'
                  : ' Simula tu inversión en el portfolio'
              }
            />
            <ChartComponent>
              <ChartDoughnutXcala dataChart={{ labels, datasets }} />
            </ChartComponent>
          </Box>
        )}
        <Box
          sx={{
            width: {
              md: isSimulatorFund ? '100%' : 'calc(60% - 16px)',
              sm: '100%',
            },
            boxSizing: 'border-box',
            display: 'flex',
            gap: '32px',
            flexDirection: 'column',
            margin: { md: ' 0 auto ' },
            justifyContent: 'space-between',
          }}>
          {isSimulatorFund && (
            <ContributionsSectionTitleForm
              textTitle={
                isSimulatorFund
                  ? 'Simula tu inversión en el fondo'
                  : ' Simula tu inversión en el portfolio'
              }
            />
          )}
          <Box>
            <TextField
              name='initialInvestment'
              label={
                <Typography fontSize={{ md: '18px', xs: '14px' }}>Inversión Inicial</Typography>
              }
              variant='standard'
              fullWidth
              helperText={formik.touched.initialInvestment && formik.errors.initialInvestment}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              error={Boolean(formik.touched.initialInvestment && formik.errors.initialInvestment)}
              value={formik.values.initialInvestment}
              onBlur={formik.handleBlur}
              onChange={e => {
                const value = e.target.value;
                const validAmount = value.replace(/\D/g, '');
                if (validAmount.toString().length <= 8) {
                  const format = numberWithPoint(validAmount);
                  formik.setFieldValue('initialInvestment', format);
                }
              }}
            />
            <TextStyled
              sx={{
                width: '100%',
              }}>
              {isSimulatorFund
                ? 'Inversión mínima del fondo es de $10.000'
                : 'Inversión mínima del portfolio es de $200.000'}
            </TextStyled>
            <TextField
              sx={{
                mb: '22px',
              }}
              name='monthlyInvestment'
              label={
                <Typography fontSize={{ md: '18px', xs: '14px' }}>
                  Aporte Mensual (Opcional)
                </Typography>
              }
              variant='standard'
              fullWidth
              error={Boolean(formik.touched.monthlyInvestment && formik.errors.monthlyInvestment)}
              onBlur={formik.handleBlur}
              helperText={formik.touched.monthlyInvestment && formik.errors.monthlyInvestment}
              onChange={e => {
                const value = e.target.value;
                const validAmount = value.replace(/\D/g, '');
                if (validAmount.toString().length <= 8) {
                  const format = numberWithPoint(validAmount);
                  formik.setFieldValue('monthlyInvestment', format);
                }
              }}
              value={formik.values.monthlyInvestment}
            />
            <Box sx={{ width: '100%' }}>
              <FormControl variant='standard' sx={{ width: '100%' }}>
                <InputLabel id='demo-simple-select-standard-label'>
                  Plazo estimado de inversión (horizonte)
                </InputLabel>
                <Select
                  label='Age'
                  sx={{
                    width: '100%',
                    fontSize: '20px',
                    color: '#808080',
                  }}
                  id='months'
                  value={formik.values.months}
                  variant='standard'
                  onChange={e => {
                    formik.setFieldValue('months', e.target.value);
                  }}>
                  {optionsYears
                    ?.filter(item => item !== undefined)
                    .map(item => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.value}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {/* <Select
                label="Age"
                sx={{
                  width: "100%",
                  fontSize: "20px",
                  color: "#808080",
                }}
                id="months"
                value={formik.values.months}
                variant="standard"
                onChange={(e) => {
                  formik.setFieldValue("months", e.target.value);
                }}
              >
                {optionsYears
                  ?.filter((item) => item !== undefined)
                  .map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
              </Select> */}
              {formik.touched.months && formik.errors.months ? (
                <Box
                  sx={{
                    color: 'red',
                    ml: 1,
                  }}>
                  {formik.errors.months}
                </Box>
              ) : null}
            </Box>
            <Box
              sx={{
                display: ' flex',
                justifyContent: { md: 'start', xs: 'center' },
              }}>
              <ButtonsPrimary
                sx={{
                  marginTop: '16px',
                  width: '100%',
                  fontSize: '17px',
                  justifyContent: 'center',
                }}
                name='Calcular'
                disableElevation
                disabled={!(formik.dirty && formik.isValid)}
                onClick={() => handleSubmit(formik.values, riskPortfolio)}
                fullWidth
              />
            </Box>
          </Box>
        </Box>
      </ContainerForm>
    </ContentFormContributions>
  );
};

export default SimulatorForm;
