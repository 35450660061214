import React from 'react';
import useSignFetchTransactions from '../hooks/useSignFetchTransactions';

export const withSignFetchTransactions = WrappedComponent => {
  return props => {
    const [doSignFetchTransactions, { data, error, loading }] = useSignFetchTransactions();

    return (
      <WrappedComponent
        {...props}
        successSignFetchTransactions={data?.SignFetchTransactions}
        errorSignFetchTransactions={error}
        loadingSignFetchTransactions={loading}
        doSignFetchTransactions={doSignFetchTransactions}
      />
    );
  };
};

export default withSignFetchTransactions;
