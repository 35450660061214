import { Box, Container, Stack, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonsPrimary,
  ButtonsSecundary,
} from '../../../components/ComponentApp/buttoms/ButtonsXcala';
import { HeaderMyAccount } from '../../../components/Layout/myNewAccount/HeaderMyAccount';
import Spinner from '../../../components/Spinner';
import { NotificationsXcala } from '../../../components/XcalaComponents/Notifications/NotificationsXcala';
import { FormBankSection } from '../../../components/myAccount/FormBankSection';
import { FormDatas } from '../../../components/myAccount/FormDatas';
import useModal from '../../../hooks/custom/useModal';
import useGetUserBank from '../../../hooks/useGetUserBank';
import useRegisterUserBank from '../../../hooks/useRegisterUserBank';
import useRegisterUserDataRegistration from '../../../hooks/useRegisterUserDataRegistration';
import { currentUserInfo, resetCurrentUserInfo, updateUserAtributes } from '../../../redux/auth';
import { onRefresh } from '../../../utils/refreshPage';

const ComponentPages = styled(Stack)({
  width: { md: '60%', xs: '100%' },
  maxWidth: '1380px',
  flexDirection: 'row',
  margin: '0 auto',
  gap: '24px',
  flexWrap: 'wrap',
});

const TitleDatos = styled(Typography)({
  border: '1px solid red',
  textAlign: 'start',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(255, 255, 255, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '48px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0 auto',
});

const MyNewAccount = () => {
  const dispatch = useDispatch();

  const { user, loadingCurrentUserInfo } = useSelector(state => state.auth);

  const [isEditPersonalData, setIsEditPersonalData] = useState(false);

  const [isEditContactData, setIsEditContactData] = useState(false);

  const [isEditBankData, setIsEditBankData] = useState(false);

  const { isShowing, toggle } = useModal();

  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  const {
    name: nameUser,
    email: emailUser,
    'custom:dni': userDni,
    'custom:paternal-surname': lastNameUser,
  } = user;

  const [
    doRegisterUserDataRegistration,
    {
      data: dataRegisterUserDataRegistration,
      error: errorRegisterUserDataRegistration,
      loading: loadingRegisterUserDataRegistration,
    },
  ] = useRegisterUserDataRegistration();

  const successRegisterUserDataRegistration =
    dataRegisterUserDataRegistration?.registerUserDataRegistration;

  const { data: dataUserBank, error: errorUserBank, loading: loadingUserBank } = useGetUserBank();
  const successUserBankDetail = dataUserBank?.getUserBankDetail;

  const [
    doRegisterUserBank,
    { data, error: errorRegisterUserBank, loading: loadingRegisterUserBank },
  ] = useRegisterUserBank();

  const [personalFields, setPersonalField] = useState({
    gender: user?.gender ? { name: user.gender } : { name: '' },
    estado_civil: user?.['custom:civil-status']
      ? { name: user['custom:civil-status'] }
      : { name: '' },
    Nacionalidad: user?.['custom:nationality']
      ? { name: user['custom:nationality'] }
      : { name: '' },
    Profesion: user?.['custom:profession'] ? { name: user['custom:profession'] } : { name: '' },
    Ingresos_mensuales: user?.['custom:money-income']
      ? { name: user['custom:money-income'] }
      : { name: '' },
    Origen_de_fondos: user?.['custom:money-origin']
      ? { name: user['custom:money-origin'] }
      : { name: '' },
  });

  const handleOpenEditPersonalData = () => {
    setIsEditPersonalData(prev => !prev);
  };

  const handleChangePersonalFields = e => {
    const { name, value } = e.target;
    setPersonalField({ ...personalFields, [name]: value });
  };

  const [contactField, setcontactField] = useState({
    email: user?.email ? user.email : '',
    telephone: user?.phone_number ? user.phone_number : '',
    address: user?.address ? user.address : '',
  });

  const [errorPhone, setErrorPhone] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);
  const [accountNumberError, setAccountNumberError] = useState(false);

  const handleOpenEditContactData = () => {
    setIsEditContactData(prev => !prev);
  };

  const handleChangeContactFields = e => {
    if (e.target.name === 'email') {
      setcontactField(contactField => ({
        ...contactField,
        email: e.target.value,
      }));
    } else if (e.target.name === 'telephone') {
      const telefonoRegex = /^\+569[0-9]{8}$/;
      setcontactField(contactField => ({
        ...contactField,
        telephone: e.target.value,
      }));
      if (telefonoRegex.test(e.target.value)) {
        setErrorPhone(false);
      } else {
        setErrorPhone(true);
      }
    } else if (e.target.name === 'address') {
      setcontactField(contactField => ({
        ...contactField,
        address: e.target.value,
      }));

      if (e.target.value.trim() === '' || e.target.value.length < 15) {
        setErrorAddress(true);
      } else {
        setErrorAddress(false);
      }
    }
  };

  const [isBankData, setIsBankData] = useState(false);
  const [bankField, setBankField] = useState({
    bankSelected: user?.bank ? { id: user.bank.bank_id, name: user.bank.bank_name } : '',
    accountTypeSelected: user?.bankType
      ? { id: user.bankType.bank_type_id, name: user.bankType.bank_type_name }
      : '',
    number: user?.account_number ? user.account_number : '',
  });

  const handleEditWithoutBankData = () => {
    setIsBankData(prev => !prev);
    setIsEditBankData(prev => !prev);
  };

  const handleOpenEditBankData = () => {
    if (isBankData) {
      setIsEditBankData(prev => !prev);
    }
  };

  // const handleChangeBankFields = (e) => {
  //   if (e.target.name === "bankSelected") {
  //     setBankField((bankField) => {
  //       return {
  //         ...bankField,
  //         bankSelected: e.target.value,
  //       };
  //     });
  //   } else if (e.target.name === "accountTypeSelected") {
  //     setBankField((bankField) => {
  //       return {
  //         ...bankField,
  //         accountTypeSelected: e.target.value,
  //       };
  //     });
  //   } else if (e.target.name === "number") {
  //     const inputValue = e.target.value;
  //     const numericValue = inputValue.replace(/[^0-9]/g, "").slice(0, 20);
  //     setBankField((bankField) => {
  //       return {
  //         ...bankField,
  //         number: numericValue,
  //       };
  //     });
  //   }
  // };

  const handleChangeBankFields = e => {
    if (e.target.name === 'bankSelected') {
      setBankField(bankField => ({
        ...bankField,
        bankSelected: e.target.value,
      }));
    } else if (e.target.name === 'accountTypeSelected') {
      setBankField(bankField => ({
        ...bankField,
        accountTypeSelected: e.target.value,
      }));
    } else if (e.target.name === 'number') {
      const inputValue = e.target.value;
      const numericValue = inputValue.replace(/[^0-9]/g, '').slice(0, 20);

      // Verificar si el número de cuenta tiene más de 8 caracteres
      const isAccountNumberValid = numericValue.length >= 8;

      // Establecer el estado de error según la validación
      setAccountNumberError(!isAccountNumberValid);

      setBankField(bankField => ({
        ...bankField,
        number: numericValue,
      }));
    }
  };

  const [copyText, setCopyText] = useState('Copiar mi link para referir');
  const host = process.env.REACT_APP_HOST;
  const encodedEmail = window.btoa(user?.email);

  const handleCopyLink = () => {
    try {
      const link = `${host}/signup/${encodedEmail}`;
      navigator.clipboard.writeText(link);
      setCopyText('Copiado!');
      setTimeout(() => {
        setCopyText('Copiar mi link para referir');
      }, 1000);
      toast.success('Enlace copiado con éxito!');
    } catch (error) {
      toast.error('Ups parece que algo salio mal');
    }
  };

  const handleDefaultData = () => {
    if (isEditPersonalData) {
      setPersonalField({
        gender: user?.gender ? { name: user.gender } : { name: '' },
        estado_civil: user?.['custom:civil-status']
          ? { name: user['custom:civil-status'] }
          : { name: '' },
        Nacionalidad: user?.['custom:nationality']
          ? { name: user['custom:nationality'] }
          : { name: '' },
        Profesion: user?.['custom:profession'] ? { name: user['custom:profession'] } : { name: '' },
        Ingresos_mensuales: user?.['custom:money-income']
          ? { name: user['custom:money-income'] }
          : { name: '' },
        Origen_de_fondos: user?.['custom:money-origin']
          ? { name: user['custom:money-origin'] }
          : { name: '' },
      });
      setIsEditPersonalData(prev => !prev);
    }
    if (isEditContactData) {
      setcontactField({
        email: user?.email ? user.email : '',
        telephone: user?.phone_number ? user.phone_number : '',
        address: user?.address ? user.address : '',
      });
      setIsEditContactData(prev => !prev);
    }
    if (isEditBankData) {
      setBankField({
        bankSelected: user.bank ? { id: user.bank.bank_id, name: user.bank.bank_name } : {},
        accountTypeSelected: user.bankType
          ? {
              id: user.bankType.bank_type_id,
              name: user.bankType.bank_type_name,
            }
          : {},
        number: user.account_number ? user.account_number : '',
      });
      setIsEditBankData(prev => !prev);
    }
  };

  const handleUpdateData = async () => {
    try {
      if (isEditPersonalData) {
        const attributes = [];
        attributes.push({ Name: 'gender', Value: personalFields.gender.name });
        attributes.push({
          Name: 'custom:civil-status',
          Value: personalFields.estado_civil.name,
        });
        attributes.push({
          Name: 'custom:nationality',
          Value: personalFields.Nacionalidad.name,
        });
        attributes.push({
          Name: 'custom:profession',
          Value: personalFields.Profesion.name,
        });
        attributes.push({
          Name: 'custom:money-income',
          Value: personalFields.Ingresos_mensuales.name,
        });
        attributes.push({
          Name: 'custom:money-origin',
          Value: personalFields.Origen_de_fondos.name,
        });
        const data = {
          registration_type_id: 6,
          detail: attributes,
        };
        await doRegisterUserDataRegistration({
          variables: { data },
        });
      }
      if (isEditContactData) {
        const attributes = [];
        attributes.push({
          Name: 'phone_number',
          Value: contactField.telephone,
        });
        attributes.push({ Name: 'address', Value: contactField.address });
        const data = {
          registration_type_id: 6,
          detail: attributes,
        };

        await doRegisterUserDataRegistration({
          variables: { data },
        });
      }
      if (isEditBankData) {
        const data = {
          bankId: bankField.bankSelected.id,
          accountTypeId: bankField.accountTypeSelected.id,
          accountNumber: bankField.number,
        };
        await doRegisterUserBank({
          variables: { data },
        });
      }
      toast.success('Los datos fueron actualizados con éxito');
    } catch {
      toast.error('Ups parece que ocurrio un error');
    } finally {
      if (isEditPersonalData) {
        setIsEditPersonalData(prev => !prev);
      }
      if (isEditContactData) {
        setIsEditContactData(prev => !prev);
      }
      if (isEditBankData) {
        setIsEditBankData(prev => !prev);
      }
    }
  };

  useEffect(() => {
    dispatch(resetCurrentUserInfo());
    dispatch(currentUserInfo());
  }, []);

  useEffect(() => {
    if (successUserBankDetail && !loadingUserBank) {
      dispatch(updateUserAtributes(successUserBankDetail));
      setIsBankData(user.bank ? true : !isBankData);
    }
  }, [successUserBankDetail, loadingUserBank]);

  if (loadingCurrentUserInfo) {
    return <Spinner />;
  }

  if (errorRegisterUserDataRegistration || errorUserBank || errorRegisterUserBank) {
    return (
      <NotificationsXcala
        activation={!isShowing}
        message='Lo sentimos, ha ocurrido un error inesperado....'
        nameBtnTwo='Enviar error'
        toggle={toggle}
        refresh={onRefresh}
        error={errorRegisterUserDataRegistration || errorUserBank || errorRegisterUserBank}
      />
    );
  }

  return (
    <>
      <HeaderMyAccount
        userRisk={userRisk}
        nameUser={nameUser}
        emailUser={emailUser}
        userDni={userDni}
        lastNameUser={lastNameUser}
        handleDefaultData={handleDefaultData}
        handleUpdateData={handleUpdateData}
        isEditPersonalData={isEditPersonalData || isEditContactData || isEditBankData}
        loadingUpdateData={loadingRegisterUserBank || loadingRegisterUserDataRegistration}
        errorPhone={errorPhone}
      />

      <Box marginTop='-124px'>
        <Container
          maxWidth='1380px'
          sx={{
            marginTop: '0',
            alignItems: 'center',
            background: '#F2F2F2',
            paddingY: '60px',
          }}>
          <ComponentPages>
            <FormDatas
              contactField={contactField}
              handleChangeContactFields={handleChangeContactFields}
              handleOpenEditContactData={handleOpenEditContactData}
              isEditContactData={isEditContactData}
              errorPhone={errorPhone}
              errorAddress={errorAddress}
              isEditPersonalData={isEditPersonalData}
              handleChangePersonalFields={handleChangePersonalFields}
              personalFields={personalFields}
              handleOpenEditPersonalData={handleOpenEditPersonalData}
              errorRegisterUserDataRegistration={errorRegisterUserDataRegistration}
              loadingRegisterUserDataRegistration={loadingRegisterUserDataRegistration}
            />

            <FormBankSection
              isEditBankData={isEditBankData}
              bankField={bankField}
              loadingUserBank={loadingUserBank}
              isBankData={isBankData}
              handleEditWithoutBankData={handleEditWithoutBankData}
              handleOpenEditBankData={handleOpenEditBankData}
              handleChangeBankFields={handleChangeBankFields}
              errorRegisterUserBank={errorRegisterUserBank}
              loadingRegisterUserBank={loadingRegisterUserBank}
              //copyText={copyText}
              //handleCopyLink={handleCopyLink}
              accountNumberError={accountNumberError}
            />
          </ComponentPages>

          <Stack
            sx={{
              //display: isEditPersonalData  ? "flex" : "none",
              width: { md: '35%' },
              margin: 'auto',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              gap: '24px',
              marginTop: '54px',
            }}>
            {(isEditPersonalData || isEditBankData || isEditContactData) && (
              <>
                <ButtonsSecundary
                  name='Descartar'
                  onClick={handleDefaultData}
                  fullWidth
                  sx={{ width: { md: '100%', xs: '100%' } }}
                />

                <ButtonsPrimary
                  name='Guardar cambios'
                  onClick={handleUpdateData}
                  disabled={errorPhone || errorAddress || accountNumberError}
                  fullWidth
                  sx={{
                    textTransform: 'none',
                    width: { md: '100%', xs: '100%' },
                    whiteSpace: 'nowrap',
                  }}
                />
              </>
            )}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default MyNewAccount;
