import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { numberWithPoint } from '../../../utils/utils';
import ModalRescueDuplicate from '../../UI/ModalRescueDuplicate';
import {
  BtnContainer,
  IconsWarnning,
  ModalContainer,
  MsnAlert,
  MuntCashContiner,
  MuntContainer,
  MuntContext,
  ParrafoAlert,
  TextImportante,
  TitleAlert,
  ValueMunt,
  WarningAmber,
} from '../../products/rescueFund/ReceivedRescueFunds';
import { RescueContentContent } from './RescueComponent';

const ReceivedRescuePortfolio = ({
  responseErrorRescue = 'error',
  cuotas = '2555',
  rescueAmount = '',
  days = '12',
  date = '12',
  investmentSelected = '454',
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Stack
        sx={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          alignItems: 'center',
          mb: '25px',
        }}>
        <RescueContentContent
          width={{ md: '100%', sx: '80%' }}
          height={{ md: 'auto', sx: 'auto' }}
          minWidth={{ md: '740px', sx: '' }}
          mb={9}>
          <ModalContainer>
            <Stack direction={'row'} alignItems={'center'} alignSelf={'center'}>
              <Stack width={'70px'} height={'70px'}>
                <img
                  src='/assets/images/sucess-gif.gif'
                  alt='success'
                  style={{ width: '100%', height: '100%', display: 'block' }}
                />
              </Stack>
              <Typography sx={{ color: '#49a197', fontSize: '1.3rem', fontWeight: 600 }}>
                ¡Hemos recibido tu solicitud!
              </Typography>
            </Stack>

            <MuntCashContiner>
              <MuntContainer>
                <MuntContext
                  sx={{
                    fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                  }}>
                  Monto solicitado
                </MuntContext>
                <ValueMunt
                  sx={{
                    fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                  }}>
                  $ {rescueAmount}
                </ValueMunt>
              </MuntContainer>

              {/* <MuntContainer sx={{ marginTop: 2 }}>
                <MuntContext
                  sx={{
                    fontSize: "clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)",
                  }}
                >
                  Equivalente en cuotas:
                </MuntContext>
                <ValueMunt
                  sx={{
                    fontSize: "clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)",
                  }}
                >
                  {cuotas}
                </ValueMunt>
              </MuntContainer> */}
            </MuntCashContiner>

            <MuntCashContiner sx={{ background: '#fff' }}>
              <MuntContainer sx={{ marginTop: 2 }}>
                <MuntContext
                  sx={{
                    fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                    fontWeight: 300,
                    color: '#182849',
                  }}>
                  Plazo de rescate:
                </MuntContext>
                <ValueMunt
                  sx={{
                    fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                    fontWeight: 300,
                    color: '#182849',
                  }}>
                  {days}
                </ValueMunt>
              </MuntContainer>
              <MuntContainer sx={{ marginTop: 2 }}>
                <MuntContext
                  sx={{
                    fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                    fontWeight: 300,
                    color: '#182849',
                  }}>
                  Fecha y hora de solicitud:
                </MuntContext>
                <ValueMunt
                  sx={{
                    fontSize: 'clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)',
                    fontWeight: 300,
                    color: '#182849',
                  }}>
                  {date}
                </ValueMunt>
              </MuntContainer>
            </MuntCashContiner>

            <MsnAlert sx={{ marginTop: 3, marginBottom: 3 }}>
              <TitleAlert>
                <WarningAmber>
                  <IconsWarnning />
                </WarningAmber>
                <TextImportante>Importante</TextImportante>
              </TitleAlert>
              <ParrafoAlert
                sx={{
                  fontSize: 'clamp(0.75rem, 0.5673rem + 0.7692vw, 1rem)',
                  width: '100%',
                  margin: '0 auto',
                }}>
                Te informaremos por correo cuando el rescate esté disponible en tu caja.
              </ParrafoAlert>
            </MsnAlert>
            <BtnContainer
              gap={2}
              mt={50}
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
              }}>
              <Button
                sx={{
                  width: { md: '55%' },
                  padding: '16px',
                  textTransform: 'none',
                }}
                variant='contained'
                disableElevation
                onClick={() => navigate('/myprofilerisk')}>
                Volver al inicio
              </Button>
            </BtnContainer>
          </ModalContainer>
        </RescueContentContent>
      </Stack>
    </>
  );
};

export default ReceivedRescuePortfolio;
