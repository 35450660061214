import { Button, IconButton, Stack, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { numberWithPoint } from '../../utils/numbers';
import { ButtonsPrimary } from '../ComponentApp/buttoms';
import Spinner from '../Spinner';
import AvatarDashboard from './AvatarDashboard';
import WalletNoAmountCard from './WalletNoAmountCard';

const ContainerWallet = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  minHeight: '315px',
  maxWidth: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 16px',
  boxSizing: 'border-box',
});

const MenuHeader = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});

const Menu = styled(IconButton)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '4px',
  boxSizing: 'border-box',
  borderRadius: '4px',
});

const Icons = styled('img')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  height: '16px',
  width: '16px',
});
const TitleWallet = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'colunm',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  height: '100%',
  width: '100%',
  maxHeight: '20px',
  maxWidth: '100%',
});

const AvailableBalanceTitle = styled(Typography)({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(24, 40, 73, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '16px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
  margin: '0px',
});

const AvailableBalance = styled(Typography)({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(30, 34, 170, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '20px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
  margin: '0px',
});

const ContainerWalletActions = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '90px',
  width: '100%',
});

const BtnLoadWallet = styled(Button)({
  backgroundColor: 'rgba(30, 34, 170, 1)',
  color: '#fff',
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  padding: '12px 8px',
  boxSizing: 'border-box',
  height: '31px',
  width: '120px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#4449D8',
  },
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '10px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});

const BtnUpdateWallet = styled(Button)({
  textDecoration: 'underline',
  textTransform: 'none',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '12px',
  letterSpacing: '0px',
});

export const WalletDashboard = ({ perfil, balanceData, loadingBalance }) => {
  const { user } = useSelector(state => state.auth);

  const [accessWallet, setAccessWallet] = useState(false);
  const navigate = useNavigate();

  const handleRedirect = () => {
    if (!accessWallet) {
      return navigate('/completeregistration');
    }
    if (cashBalance?.marketValue === 0) {
      return navigate('/payments');
    }
    if (cashBalance?.marketValue >= 1) {
      return navigate('/wallet');
    }

    return navigate('/payments');
  };

  const handleRedirectToWallet = () => {
    if (!accessWallet) {
      return navigate('/completeregistration');
    }

    return navigate('/wallet');
  };

  const cashBalance = balanceData?.getCashBalancePortfolio?.totalAmount;
  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setAccessWallet(true);
    }
  }, [user]);

  if (loadingBalance) {
    return <Spinner />;
  }

  return (
    <ContainerWallet onClick={handleRedirectToWallet} sx={{ cursor: 'pointer' }}>
      <MenuHeader sx={{ marginTop: '20px' }} />
      <AvatarDashboard riskProfile={user['custom:profile'].toLowerCase()} />

      {cashBalance && cashBalance?.marketValue !== null ? (
        <>
          <TitleWallet>
            <AvailableBalanceTitle>Caja Total</AvailableBalanceTitle>
            <AvailableBalance>{`$ ${numberWithPoint(Math.floor(cashBalance))}`}</AvailableBalance>
          </TitleWallet>
          <ContainerWalletActions>
            <ButtonsPrimary
              name='Carga aquí tu caja'
              onClick={() => handleRedirect()}
              size='xs'
              fontSize={{ xs: '14px' }}
            />
          </ContainerWalletActions>
        </>
      ) : cashBalance && cashBalance.marketValue === 0 ? (
        <WalletNoAmountCard handleRedirect={handleRedirect} />
      ) : (
        <WalletNoAmountCard handleRedirect={handleRedirect} />
      )}
    </ContainerWallet>
  );
};
