import { Box, Stack, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import Spinner from '../../Spinner';
import {
  ContentTitleTable,
  TitleTableName,
} from '../../portfolio/portolioInvestment/PortfolioInvestmentComponents';
import ChartnvestmentContainerProducts from './ChartnvestmentContainerProducts';
import DividendDropDownProducts from './DividendDropDownProducts';
import SectionGeneralCondition from './SectionGeneralCondition';
import TableInvertedProducts from './TableInvertedProducts';

const colores = {
  'HMC Balanceado Global': '#8612FF',
  'HMC Deuda Privada Pesos': '#160BE3',
  'HMC Renta Global Pesos': '#0060FA',
  'HMC Global Real Estate Income': '#0BBAE3',
  'HMC US Venture Opportunities': '#0DFBBD',
  'HMC Deuda Privada Global': '#D98DEB',
  'HMC Renta Global USD': '#FFAF9C',
  Caja: 'rgba(30, 34, 170, 1)',
  // ...asocia cada security con un color
};

const BodyInvertedProducts = ({
  dataCharts,
  dividends,
  myInvestment,
  valorCuota,
  onChangeMonths,
  active,
  loadingGetSecurityPriceRange,
  successGetSecurityTransactions,
  loadingGetSecurityTransactions,
  statusDividendFinfolio,
  refetch,
  handleClose,
  visible,
}) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const completedTransactions = successGetSecurityTransactions
    .filter(item => item.status === 4)
    .sort((a, b) => new Date(b.tradeDate) - new Date(a.tradeDate));

  const pendingTransactions = successGetSecurityTransactions
    .filter(item => item.status === 2 || item.Status === 1)
    .sort((a, b) => new Date(b.tradeDate) - new Date(a.tradeDate));

  if (loadingGetSecurityTransactions) {
    return <Spinner />;
  }

  return (
    <Stack
      sx={{
        width: '90%',
        marginTop: '48px',
        borderRadius: '16px',
        position: 'relative',
        marginX: 'auto',
        isolation: 'isolate',
        marginBottom: '4rem',
      }}
      gap={4}>
      {visible && (
        <DividendDropDownProducts
          investment={myInvestment}
          statusFilter={statusDividendFinfolio}
          handleClose={handleClose}
          refetch={refetch}
        />
      )}
      <Stack sx={{ width: '100%' }} gap={2} flexDirection={{ md: 'row', xs: 'column' }}>
        <Stack
          flex={'1 0.5 auto'}
          sx={{
            width: '100%',
            borderRadius: '8px',
          }}>
          <ChartnvestmentContainerProducts
            dataCharts={dataCharts}
            dividends={dividends}
            onChangeMonths={onChangeMonths}
            active={active}
            loadingGetSecurityPriceRange={loadingGetSecurityPriceRange}
          />
        </Stack>
        <Stack
          flex={'1 1 auto'}
          sx={{
            backgroundColor: '#fff',
            width: '100%',
            height: '100%',
            minWidth: { md: '400px', xs: '100%' },
          }}>
          <SectionGeneralCondition valorCuota={valorCuota} myInvestment={myInvestment} />
        </Stack>
      </Stack>

      <Stack
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: '8px',
          display: 'flex',
          position: 'relative',
          isolation: 'isolate',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '32px',
          boxSizing: 'border-box',
          boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
        }}>
        <ContentTitleTable>
          <TitleTableName variant='h2'>Cartola de movimientos</TitleTableName>
        </ContentTitleTable>
        <Box sx={{ width: '100%', my: 2 }}>
          <Tabs
            onChange={handleChange}
            value={value}
            aria-label='Tabs where selection follows focus'
            selectionFollowsFocus>
            <Tab sx={{ flex: '1 1 auto' }} label='Realizados' />
            <Tab sx={{ flex: '1 1 auto' }} label='Pendientes' />
          </Tabs>
        </Box>
        {value === 0 && (
          <TableInvertedProducts transactions={completedTransactions} colores={colores} />
        )}

        {value === 1 && (
          <TableInvertedProducts transactions={pendingTransactions} colores={colores} />
        )}
      </Stack>
    </Stack>
  );
};

export default BodyInvertedProducts;
