import { Box, Typography } from '@mui/material';
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from '@mui/x-data-grid';
import React from 'react';
import { FormatCurrency } from '../../utils/numbers';
import { formatChileanDate } from '../../utils/utils';

const csvOptions = { delimiter: ';' };

function CustomExportButton(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem rintOptions={{ fileName: 'Descargar informe' }} options={csvOptions} />
    </GridToolbarExportContainer>
  );
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props}>
      <CustomExportButton />
    </GridToolbarContainer>
  );
}

const TabletGridWallet = ({ sortedArrayDateStatusTransactions }) => {
  const column = [
    {
      field: 'tradeDate',
      headerName: 'Fecha',
      width: 120,
      flex: '0.5 1 120px',
      hide: true,
      resizable: true,
      renderCell: params => {
        return (
          <div>
            <Typography variant='subtitle2'>{formatChileanDate(params.formattedValue)}</Typography>
          </div>
        );
      },
    },
    {
      field: 'subTypeDetail',
      headerName: 'Movimientos',
      flex: '0.6 2 80px',
      minWidth: 250,
      cellClassName: 'super-app-theme--cell',
      resizable: true,
    },
    {
      field: 'amount',
      headerName: 'Monto',
      flex: '0.5 1 60',
      width: 120,
      resizable: true,
      renderCell: params => {
        return (
          <div>
            <Typography variant='subtitle2'>
              {params.formattedValue === '' ? '----------' : FormatCurrency(params.formattedValue)}
            </Typography>
          </div>
        );
      },
      sortComparator: (v1, v2) => v1 - v2,
    },
  ];
  const transactions = sortedArrayDateStatusTransactions.map(transaction => {
    // const tradeDate = new Date(transaction.tradeDate);

    // const formattedDate = `${tradeDate.getDate()}/${
    //   tradeDate.getMonth() + 1
    // }/${tradeDate.getFullYear()}`;

    let amountValue;

    if (transaction.subType === 'WTHD') {
      amountValue = -Math.abs(transaction.amount);
    } else {
      amountValue = Math.abs(transaction.amount);
    }

    return {
      accountId: transaction.accountId,
      // amount: FormatCurrency(transaction.amount),
      amount: amountValue,
      id: transaction.id,
      note: transaction.note,
      price: transaction.price,
      securityId: transaction.securityId,
      securityName: transaction.securityName,
      status: transaction.status,
      subType: transaction.subType,
      subTypeDetail: transaction.subTypeDetail,
      tradeDate: transaction.tradeDate,
      units: transaction.units,
      // status: transaction.status === 4 ? "Completado" : "Pendiente",
    };
  });

  return (
    <Box
      sx={{
        height: '400px',
        width: { md: '100%' },
        '& .font-tabular-nums': {
          fontVariantNumeric: 'tabular-nums',
        },
      }}>
      <DataGrid
        rowHeight={50}
        columns={column}
        rows={transactions}
        {...transactions}
        initialState={{
          ...transactions.initialState,
          pagination: {
            ...transactions.initialState?.pagination,
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        sx={{
          '.MuiDataGrid-columnHeaders': {
            backgroundColor: '#1E22AA',
            color: '#fff',
            fontSize: { md: '16px', xs: '12px' },
          },
          '.MuiDataGrid-toolbarContaine, .css-128fb87-MuiDataGrid-toolbarContainer': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          },
          '.MuiDataGrid-toolbarContainer>button': {
            color: '#475569',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root': {
            borderRadius: '8px',
          },
          '.MuiDataGrid-row': {
            fontSize: { md: '16px', xs: '12px' },
            textAlign: 'left',
            justifyContent: 'center',
          },
          '& .super-app-theme--cell': {
            width: '100%',
            color: '#1E22AA',
            fontWeight: '600',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          },
          '.MuiDataGrid-footerContainer': {
            fontSize: { md: '16px', xs: '12px' },
            color: '#ddd',
          },
          '.MuiButtonBase-root': {
            color: '#fff',
          },
          '.MuiDataGrid-footerContainer  .MuiButtonBase-root ': {
            color: '#2141aa',
            width: { xs: '30px' },
            '&.Mui-disabled': {
              color: '#6c6c6c94',
            },
          },
        }}
        localeText={{
          toolbarDensity: 'Size',
          toolbarDensityLabel: 'Size',
          toolbarDensityCompact: 'Small',
          toolbarDensityStandard: 'Medium',
          toolbarDensityComfortable: 'Large',
          toolbarExportCSV: 'Exportar a CSV',
          toolbarExport: 'exportar',
        }}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          pagination: { labelRowsPerPage: 'Filas por página' },
        }}
      />
    </Box>
  );
};

export default TabletGridWallet;
