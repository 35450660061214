import emailjs from '@emailjs/browser';
import { Button, Checkbox, Divider, Stack, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFormattedDateDDMMYY } from '../../../utils/dates';
import { getFullDate } from '../../../utils/utils';
import { ButtonsPrimary, ButtonsTertiary } from '../../ComponentApp/buttoms';
import ModalErrorFetch from '../../UI/ModalErrorFetch';
import ReceivedRescuePortfolio from './ReceivedRescuePortfolio';

export const RescueSolutionSection = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '16px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  marginTop: '-75px',
});
export const RsContent = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  flex: '1',
  margin: '0px',
  width: '100%',
});
export const RescueDescription = styled(Stack)({
  background:
    'linear-gradient(-4.239999999999995deg, rgba(241, 244, 249, 1) -1.5822148034538395%, rgba(241, 244, 249, 0) 135.1821148520685%)',
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  gap: '1rem',
  width: '100%',
});
export const InstallmentNumbers = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});

export const RescuePeriod = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  width: '100%',
});

export const TitleItemCards = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(24, 40, 73, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});
export const ResItemCards = styled(Typography)({
  textAlign: 'end',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(24, 40, 73, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});

export const WarningMessage = styled(Stack)({
  backgroundColor: '#FFF1F1',
  boxShadow: '0px 0px 18px rgba(61, 64, 75, 0.06)',
  boxSizing: 'border-box',
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '24px 32px',
  alignSelf: 'stretch',
  margin: '26px 0px 0px 0px',
  gap: '0.51rem',
  width: '100%',
});
export const WarninTitle = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});
export const WarningAmber = styled('img')({
  width: '24px',
  height: '24px',
  margin: '0px',
});

export const ImportanText = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(209, 45, 53, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '16px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: '115.5px',
  margin: '0px 0px 0px 16px',
});

export const DescriptionText = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(24, 40, 73, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '14px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
});
export const DividerCustom = styled(Divider)({
  border: '1px dashed rgba(191, 191, 191, 0.6)',
  alignSelf: 'stretch',
  margin: '26px 0px 0px 0px',
  width: '100%',
});

export const TermsAndConditions = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '26px 0px 0px 0px',
  width: '100%',
});

export const ContentCheckBox = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});

export const CheckBoxOutlineBlank = styled(Checkbox)({
  width: '24px',
  height: '24px',
  margin: '0px',
});
export const TextDeclaration = styled(Typography)({
  whiteSpace: 'pre-wrap',
  color: 'rgba(128, 128, 128, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '16px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
});
export const DeclarationSpan = styled(Typography)({
  whiteSpace: 'pre-wrap',
  color: 'rgba(73, 161, 151, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '16px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
});

export const ButtonGroup = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '26px 0px 0px 0px',
  width: { md: '60%', xs: '80%' },
  gap: '1rem',
  alignSelf: 'stretch',
});
export const NotNowButton = styled(Button)({
  backgroundColor: '#F4F4FB',
  width: '302px',
  height: '60px',
  margin: '0px',
  color: '#1E22AA',
});
export const ConfirmationButton = styled(Button)({
  width: '302px',
  height: '60px',
  background: '#1E22AA',
  '&:hover': {
    backgroundColor: '#1E22AA',
  },
});

export const ButtonBack = styled(Button)({
  height: '39px',
  width: '128px',
});

export const TextBtnBack = styled(Typography)({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(30, 34, 170, 1)',
  fontStyle: 'normal',
  fontFamily: 'Museo',
  fontWeight: '300',
  fontSize: '16px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});

const CardsResultRescue = ({
  handleSubmitRescue,
  amountRescue,
  selectedRescueType,
  portfolioFileAsComplete,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [receivedRequest, setreceivedRequest] = useState(true);

  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);

  const dateDDMMYY = getFormattedDateDDMMYY();
  const days = 'Según reglamento fondos';
  const date = getFullDate();

  const handleChangeCheckBox = e => {
    setIsChecked(e.target.checked);
  };

  const openTC = () => {
    const { REACT_APP_CONTRACT_PORTFOLIO } = process.env;
    const win = window.open(REACT_APP_CONTRACT_PORTFOLIO, '_blank');
    win.focus();
  };

  const completeName = `${user.name} ${user['custom:paternal-surname']}`;

  const currentUrl = window.location.hostname;
  const emailEnv = currentUrl === 'app.xcala.com' ? 'template_4t7di9e' : 'template_7jqe0wp';

  const handleConfirmRescue = () => {
    setSubmitSuccess(true);
    emailjs
      .send(
        'service_7utjird',
        emailEnv,
        {
          portfolioName: portfolioFileAsComplete?.fileAsComplete,
          rescueAmount: `$ ${amountRescue}`,
          rescueType: selectedRescueType,
          RutCliente: user['custom:dni'],
          dateRescue: dateDDMMYY,
          from_name: completeName,
          reply_to: user?.email,
          name: completeName,
          date: dateDDMMYY,
          portfolio: portfolioFileAsComplete?.fileAsComplete,
          amount: `$ ${amountRescue}`,
          typeRescue: selectedRescueType,
          emailUser: user?.email,
        },
        'EFAYuCkTVHLBFWEFQ',
      )
      .then(
        result => {
          setreceivedRequest(prevState => !prevState);
        },
        error => {
          toast.error('Ups ha ocurrido un error');
        },
      );
  };

  return (
    <>
      {/* descomentar modal fetch de error al construir servicio de rescate de portfolio */}
      {/* {showModal && (
        <ModalErrorFetch
          message="Lo sentimos, algo no salio bien acá...."
          open={showModal}
          onClose={setShowModal}
          refresh={onRefresh}
        />
      )} */}

      {receivedRequest ? (
        <Stack
          sx={{
            margin: '0px auto',
            width: '90%',
            maxWidth: '1380px',
            marginTop: '30px',
          }}>
          <RescueSolutionSection sx={{ padding: 'clamp(1rem, -0.875rem + 8vw, 2.125rem)' }}>
            <RsContent>
              <RescueDescription
                sx={{
                  padding: '24px clamp(1rem, -0.6667rem + 7.1111vw, 2rem)',
                }}>
                <InstallmentNumbers>
                  <TitleItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    Monto solicitado:
                  </TitleItemCards>
                  <ResItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    $ {amountRescue}
                  </ResItemCards>
                </InstallmentNumbers>
                <RescuePeriod>
                  <TitleItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    Fecha de solicitud:
                  </TitleItemCards>
                  <ResItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    {dateDDMMYY}
                  </ResItemCards>
                </RescuePeriod>
                <RescuePeriod>
                  <TitleItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    Plazo de rescate:
                  </TitleItemCards>
                  <ResItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    Según reglamento fondos
                  </ResItemCards>
                </RescuePeriod>
              </RescueDescription>
              <WarningMessage>
                <WarninTitle>
                  <WarningAmber src='/assets/images/perfiles/warning-amber.svg' />
                  <ImportanText>Importante:</ImportanText>
                </WarninTitle>
                <ul style={{ margin: '0px', padding: '0px 18px' }}>
                  <li>
                    <DescriptionText>
                      El plazo de rescate comienza a correr el día hábil siguiente si tu solicitud
                      fue realizada antes de las 4:00 PM y el día hábil subsiguiente si la realizas
                      luego de esa hora.
                    </DescriptionText>
                  </li>
                  <li>
                    <DescriptionText>
                      Este depósito es en Dólares, por lo que el monto solicitado será transformado
                      a pesos el día del depósito de acuerdo al tipo de cambio correspondiente a la
                      fecha.
                    </DescriptionText>
                  </li>
                  <li>
                    <DescriptionText>
                      El monto final a ser rescatado puede variar según el valor cuota de los fondos
                      que componen el portfolio al momento de procesar el rescate.
                    </DescriptionText>
                  </li>
                </ul>
              </WarningMessage>
              <DividerCustom variant='inset' />
              <TermsAndConditions>
                <ContentCheckBox gap={1}>
                  <Stack marginTop={{ xs: '8px', md: '0px' }}>
                    <CheckBoxOutlineBlank checked={isChecked} onChange={handleChangeCheckBox} />
                  </Stack>
                  <TextDeclaration>
                    Declaro entender los{' '}
                    <DeclarationSpan
                      variant='span'
                      component={'span'}
                      sx={{ cursor: 'pointer' }}
                      onClick={openTC}>
                      términos y condiciones del rescate
                    </DeclarationSpan>{' '}
                    que estoy a punto de solicitar
                  </TextDeclaration>
                </ContentCheckBox>
              </TermsAndConditions>
              <Stack
                justifyContent={'center'}
                direction={{ md: 'row', xs: 'column' }}
                width={{ md: '60%', xs: '90%' }}
                gap={2}
                sx={{ margin: '26px 0px 0px 0px' }}>
                <ButtonsTertiary
                  fullWidth
                  onClick={() => navigate('/myprofilerisk')}
                  name={'No por ahora'}
                />
                <ButtonsPrimary
                  disabled={!isChecked || submitSuccess}
                  fullWidth
                  onClick={() => handleConfirmRescue()}
                  name={submitSuccess ? 'Solicitando' : ' Confirmar'}
                />
              </Stack>
            </RsContent>
          </RescueSolutionSection>
          <Stack
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '5rem',
              marginBottom: '2rem',
            }}>
            <ButtonBack>
              <TextBtnBack onClick={() => handleSubmitRescue()}> Volver Atrás </TextBtnBack>
            </ButtonBack>
          </Stack>
        </Stack>
      ) : (
        <ReceivedRescuePortfolio
          rescueAmount={amountRescue}
          dateDDMMYY={dateDDMMYY}
          days={days}
          date={date}
        />
      )}
    </>
  );
};

export default CardsResultRescue;
