import { Typography } from '@mui/material';
import { Button } from 'antd';
import React, { useState } from 'react';

const TruncatedText = ({ text, limit, seeMore = 'Ver más', seeLess = 'Ver menos' }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const truncatedText = expanded ? text : `${text.slice(0, limit)}...`;

  return (
    <>
      <Typography variant='body1' component='p'>
        {truncatedText}

        {!expanded && text.length > limit && (
          <Typography
            variant='body1'
            component='span'
            color='primary'
            onClick={toggleExpanded}
            style={{ cursor: 'pointer' }}>
            {seeMore}
          </Typography>
        )}
        {expanded && (
          <Typography
            variant='body1'
            component='span'
            color='primary'
            onClick={toggleExpanded}
            style={{ cursor: 'pointer' }}>
            {seeLess}
          </Typography>
        )}
      </Typography>
    </>
  );
};

export default TruncatedText;
