import { Box, Button, IconButton, Stack, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonsPrimary } from '../ComponentApp/buttoms/ButtonsXcala';

const BtnContainer = styled(Stack)({
  width: '100%',
  flexDirection: 'column',
  paddingTop: '5px',
  alignItems: 'center',
  paddingBottom: '60px',
});

const BtnGroup = styled(Stack)({
  flexDirection: 'row',
  width: '100%',
  maxWidth: '600px',
  justifyContent: 'center',
});

const Spiner = styled('img')({
  height: '45px',
  width: '45px',
});

const BtnGroupWallet = ({
  refetchBalanceData,
  refetchTransactions,
  transactionsData,
  balanceClient,
}) => {
  const route = useNavigate();

  const refetch = () => {
    refetchBalanceData();
    refetchTransactions();
  };

  useEffect(() => {
    refetchBalanceData();
    refetchTransactions();
  }, [balanceClient, refetchBalanceData, refetchTransactions, transactionsData]);

  return (
    <Stack
      position={{ xs: 'none', md: '' }}
      left={'0'}
      right={'0'}
      sx={{ transition: 'ease-in-out', transitionDuration: 2 }}>
      <BtnContainer display={{ xs: 'flex', md: 'flex' }}>
        <BtnGroup gap={2}>
          <ButtonsPrimary name='Recargar' onClick={() => route('/payments')} fullWidth />
          <ButtonsPrimary name='Retirar' onClick={() => route('/withdrawal')} fullWidth />
        </BtnGroup>

        <Box
          sx={{
            '& > button': { m: 0 },
            marginTop: '-85px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '100%',
            '& > button:hover': { backgroundColor: '#fff' },
          }}>
          <IconButton
            color='primary'
            size='large'
            sx={{
              width: '100%',
              padding: '32px 32px',
              width: '32px',
              height: '32px',
              backgroundColor: '#fff',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => refetch()}>
            <Spiner src='./assets/images/spiner.svg' loading='lazy' alt='spiner' />
          </IconButton>
        </Box>
      </BtnContainer>
    </Stack>
  );
};

export default BtnGroupWallet;
