import { Typography } from '@mui/material';
import { numberWithPoint } from '../../../utils/utils';
import { TitleHeadResult } from './SimulationMuiComponents';

export const TitleResultSimulator = ({ fullInvesment, formData }) => {
  return (
    <TitleHeadResult>
      <Typography
        sx={{
          color: 'rgba(24, 40, 73, 1)',
          fontStyle: 'ligth',
          fontFamily: 'Poppins',
          fontWeight: '300',
          fontSize: 'clamp(0.475rem, 0.3333rem + 4.4444vw, 1.1rem)',
          textTransform: 'uppercase',
        }}>
        Resultado de tu simulación
      </Typography>
      <Typography
        sx={{
          color: '#1E22AA',
          fontStyle: 'normal',
          fontFamily: 'Poppins',
          fontWeight: '500',
          fontSize: 'clamp(0.475rem, 0.3333rem + 4.4444vw, 1.1rem)',
        }}>
        Si inviertes{' '}
        <Typography variant='span' sx={{ fontWeight: 'bold' }}>
          {`${numberWithPoint(fullInvesment)} `}
        </Typography>
        en{' '}
        <Typography variant='span' sx={{ fontWeight: 'bold' }}>
          {`${formData?.months}`}
        </Typography>
      </Typography>
    </TitleHeadResult>
  );
};
