import { Box, Stack, Typography } from '@mui/material';
import { hideNumbers } from '../../utils/hideNumbers';
import WrapperBackgroundBanner from '../XcalaComponents/WrapperBackgroundBanner';
import WrapperContentApp from '../XcalaComponents/WrapperContentApp';
import AvatarSection from './AvatarSection';
import { HeadTitle } from './MyNewTransactionsMuiComponents';

const $minHeigth = '407px';

const fileAs = {
  currency: 'pesos',
  fileAs: 'file as',
  fileAsComplete: 'file as complete',
  risk: 'agresivo',
  profit: '10',
};

export const HeaderTransactions = ({ nameUser, emailUser, userDni, lastNameUser, userRisk }) => {
  return (
    <WrapperBackgroundBanner $minHeigth={$minHeigth}>
      <WrapperContentApp
        $minHeigth={$minHeigth}
        fileAs={fileAs}
        direction={{ md: 'row', xs: 'column' }}
        justifyContent='center'
        padding={'40px 0'}>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          justifyContent={'center'}
          alignItems={{ md: 'center', xs: 'center' }}
          sx={{ width: '100%', maxWidth: '1380px' }}
          gap={1}>
          <AvatarSection userRisk={userRisk} />
          <Box>
            <HeadTitle textAlign={{ md: 'left', xs: 'center' }} fontSize={'26px'}>
              {`${nameUser[0]?.toUpperCase() + nameUser.slice(1)} ${
                lastNameUser[0]?.toUpperCase() + lastNameUser.slice(1)
              } `}
            </HeadTitle>
            <Typography
              color={'#fff'}
              fontWeight={'100'}
              textAlign={{ md: 'left', xs: 'center' }}
              fontSize={'18px'}>
              {emailUser}
            </Typography>
            <Typography
              color={'#fff'}
              fontWeight={'100'}
              textAlign={{ md: 'left', xs: 'center' }}
              fontSize={'18px'}>
              {hideNumbers(userDni)}
            </Typography>
          </Box>
        </Stack>
      </WrapperContentApp>
    </WrapperBackgroundBanner>
  );
};
