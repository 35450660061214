import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

function Footer() {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <Box
      sx={{
        backgroundColor: '#1E22AA',
        padding: '16px 20px',
        marginTop: '16px',
        //position: "absolute",
        bottom: 0,
        height: '106px',
        width: '100%',
      }}>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={2}
        sx={{
          marginTop: '20px',
        }}>
        <img src='/assets/images/footer-logo.svg' alt='footer-logo' />
        <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
          <Typography
            sx={{
              marginTop: '10px',
              color: '#fff',
              textAlign: 'center',
              fontWeight: '600',
              verticalAlign: 'center',
            }}>
            © {currentYear}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Footer;
