import { Box, Stack, styled } from '@mui/material';

export const BodyObjetivesInterest = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '16px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '32px 24px',
  boxSizing: 'border-box',
  minHeight: '486px',
  width: '100%',
  maxWidth: '1200px',
});

export const CardPerfilInversor = styled(Box)({
  boxShadow: '0px 1px 5px 1px rgba(0, 0, 0, 0.15)',
  borderRadius: '8px',
  padding: '18px 20px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
