import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonsPrimary, ButtonsSecundary } from '../ComponentApp/buttoms/ButtonsXcala';
import { ButtonXcalaBlue, ButtonXcalaOutlineSecondary } from '../UI/ButtonXcala';

const CarouselItemDashboard = ({ item }) => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [access, setAccess] = useState(false);
  const { user } = useSelector(state => state.auth);

  const handleRedirect = () => {
    const url = item.url;
    const url2 = item.url2;
    const regex = /\/([^\/]+)$/;
    const match = url2?.match(regex)[1];

    if (url.includes('https')) {
      window.open(url, '_blank');
    } else {
      navigate(access ? `${url}/${match}` : '/completeregistration');
    }
  };
  const handleRedirectTwo = () => {
    const url = item?.url2;

    if (url.includes('https')) {
      window.open(url, '_blank');
    } else {
      navigate(access ? url : '/completeregistration');
    }
  };

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setAccess(true);
    }
  }, [user]);
  useEffect(() => {
    const checkWindowWidth = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkWindowWidth);
    checkWindowWidth();

    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  return (
    <Stack
      justifyContent={{ md: 'center', xs: 'start' }}
      height='100%'
      minHeight={{ md: '315px', sm: '315px', xs: '450px' }}
      borderRadius='8px'
      width='100%'
      sx={{
        backgroundImage: isMobile ? `url(${item.mobileImg})` : `url(${item.desktopImg})`,
        backgroundPosition: 'bottom 0px right 0px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        objectFit: 'cover',
      }}>
      {isMobile ? (
        <Stack spacing={1} justifyContent='start' width='90%' margin='0 auto'>
          <Typography
            variant='h4'
            sx={{
              color: '#182849',
              fontWeight: '700',
              fontFamily: 'Poppins',
              marginTop: 2,
            }}>
            {item.title}
          </Typography>
          <Typography
            sx={{
              color: '#182849',
              fontWeight: '400',
              fontFamily: 'Poppins',
            }}>
            {item.description}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack spacing={1} direction={{ md: 'row', sm: 'column', xs: 'column' }}>
              <ButtonXcalaBlue endIcon={<KeyboardArrowRightIcon />} onClick={handleRedirect}>
                {item.buttonText}
              </ButtonXcalaBlue>
              {item.buttonText2 && (
                <ButtonXcalaOutlineSecondary
                  endIcon={<KeyboardArrowRightIcon />}
                  onClick={handleRedirectTwo}>
                  {item.buttonText2}
                </ButtonXcalaOutlineSecondary>
              )}
            </Stack>
          </Box>
        </Stack>
      ) : (
        <Stack width='80%' height='100%' margin='0 auto'>
          <Stack
            spacing={{ md: 0.5, lg: 1.5 }}
            width='55%'
            height='100%'
            direction='column'
            justifyContent='center'
            alignItems='flex-start'>
            <Typography
              component='h2'
              sx={{
                color: '#182849',
                fontWeight: '700',
                fontFamily: 'Poppins',
                textAlign: 'left',
                fontSize: 'clamp(18px, 2vw, 28px)',
              }}>
              {item.title}
            </Typography>

            <Typography
              sx={{
                color: '#182849',
                fontWeight: '400',
                fontFamily: 'Poppins',
                textAlign: 'start',
                fontSize: { md: '0.8rem', lg: '1.2rem' },
                lineHeight: { md: '115%', lg: '125%' },
              }}>
              {item.description}
            </Typography>
            <Stack direction='row' spacing={1}>
              <ButtonsPrimary
                name={item.buttonText}
                onClick={handleRedirect}
                endIcon={<KeyboardArrowRightIcon />}
                fontSize='14px'
                sx={{ padding: '8px 16px', whiteSpace: 'nowrap' }}
              />
              {item.buttonText2 && (
                <ButtonsSecundary
                  name={item.buttonText2}
                  onClick={handleRedirectTwo}
                  endIcon={<KeyboardArrowRightIcon />}
                  fontSize='14px'
                  sx={{ padding: '8px 16px', whiteSpace: 'nowrap' }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default CarouselItemDashboard;
