import { Typography, styled } from '@mui/material';

export const ReantabilityTitle = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '0px',
});
export const RentabilityValue = styled(Typography)({
  whiteSpace: 'pre-wrap',
  color: 'rgba(90, 196, 177, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '22px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
});
