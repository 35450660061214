import { gql, useQuery } from '@apollo/client';

const GET_INVESTED_AND_MARKET_VALUE = gql`
  query GetInvestedAndMarketValue($data: investedAndMarketValue!) {
    getInvestedAndMarketValue(data: $data) {
      CumulativeNetInvested
      MarketValue
      Date
    }
  }
`;
{
  /*Trae la inversión acumulada, el valor de mercado en diferentes intervalos de tiempo*/
}
export const useGetInvestedAndMarketValue = variables => {
  return useQuery(GET_INVESTED_AND_MARKET_VALUE, { variables }, { fetchPolicy: 'no-cache' });
};
