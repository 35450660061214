import { Button, Stack, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { initGetInvestments, setMyInvestmentSelected } from '../../redux/investments';
import { setPortfolioSelected } from '../../redux/portfolio';
import { numberWithPoint } from '../../utils/numbers';
import { formatLossGainValue } from '../../utils/utils';
import { portfolioNameAndDetails } from '../../utils/portfolioNameAndDetails';
import Spinner from '../Spinner';
import CardChartsDashboard from './CardChartsDashboard';
import GoToCompleteObAndInvestment from './GoToCompleteObAndInvestment';
import PaymentCardPortfolio from './PaymentCardPortfolio';
import PaymentCards from './PaymentCards';
import {
  AmountText,
  BtnFilterFound,
  Card,
  CardBody,
  CardBodyText,
  Divider,
  Icons,
  IteractionAssetsFilt,
  ListProduct,
  Title,
  TitleSection,
  TitleSectionContainer,
} from './SectionInteractionComponent';
import SectionNoMarketValue from './SectionNoMarketValue';
import SectionNoProductComponent from './SectionNoProduct';

const SectionInteraction = ({
  loadingProfit,
  successGetClientProfit,
  successGetClientInvestments,
  loadingGetClientInvestments,
  loadingBalance,
  balanceData,
  successDataInvestmentPortfolio,
  loadingInvestmentPortfolio,
}) => {
  const [selectedFilter, setSelectedFilter] = useState('fondos');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  const handleShowProducts = filterType => {
    setSelectedFilter(filterType);
  };
  const handleDetails = item => {
    dispatch(setMyInvestmentSelected(item));
    navigate('/myinvestmentdetail');
  };

  const handleDetailsInvestmentPortfolio = item => {
    dispatch(setPortfolioSelected(item));
    navigate('/details-investment-portfolio');
  };

  const [access, setAccess] = useState(false);

  const handleRedirect = () => {
    const url = 'https://www.blog.xcala.com/';

    if (url.includes('https')) {
      window.open(url, '_blank');
    } else {
      navigate(access ? url : '/completeregistration');
    }
  };

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setAccess(true);
    }
  }, [user]);
  useEffect(() => {
    if (
      successGetClientInvestments?.length >= 1 &&
      successGetClientInvestments
        ?.filter(item => item.name !== 'Caja')
        ?.every(item => Number.parseFloat(Number.parseInt(item.marketValue)) <= 0)
    ) {
      setSelectedFilter('portfolio');
    } else {
      setSelectedFilter('fondos');
    }
  }, [successGetClientInvestments]);

  const convertirAMonto = texto => {
    let soloNumeros = texto.replace(/[^0-9,]/g, '');
    let valorNumero = Number.parseFloat(soloNumeros.replace(',', '.'));
    return valorNumero;
  };

  return (
    <Stack
      width={'100%'}
      borderRadius={'8px'}
      height={'100%'}
      minHeight={'284px'}
      gap={2}
      flexDirection={{ xs: 'column', sm: 'column', lg: 'row', md: 'row' }}>
      <Stack
        sx={{
          width: { ls: '60%', md: '80%' },
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.42)',
          minHeight: '414px',
          backgroundColor: '#fff',
          boxShadow: '0px 0px 12px rgba(61, 64, 75, 0.28)',
          borderRadius: '8px',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {loadingGetClientInvestments ? (
          <Spinner />
        ) : successGetClientInvestments.length === 0 ? (
          <GoToCompleteObAndInvestment loadingBalance={loadingBalance} balanceData={balanceData} />
        ) : (
          <CardChartsDashboard />
        )}
      </Stack>
      <Stack
        sx={{
          width: { lg: '40%', md: '100%' },
          backgroundColor: 'rgba(255, 255, 255, 0.52)',
        }}
        gap={1}>
        <TitleSectionContainer>
          <TitleSection variant='h6'>Mi Inversión </TitleSection>
        </TitleSectionContainer>

        {loadingProfit ? (
          <Spinner />
        ) : (
          <Stack gap={2} flexDirection={'row'}>
            <Card>
              <CardBody gap={0.1}>
                <Icons alt='Icons Monety' src='/assets/images/dashboard/iconsMoney.svg' />
                <CardBodyText>
                  <Title>Total invertido</Title>
                  <AmountText fontSize={{ md: '24px', xs: '18px' }}>
                    ${' '}
                    {successGetClientProfit === 0
                      ? '0'
                      : numberWithPoint(
                          Math.round(Number.parseFloat(successGetClientProfit?.marketValue)),
                        )}
                  </AmountText>
                </CardBodyText>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Icons
                  alt='Icons Rentability'
                  src='/assets/images/dashboard/IconsRentability.svg'
                />
                <CardBodyText>
                  <Title>Ganancia / Pérdida</Title>
                  <AmountText fontSize={{ md: '24px', xs: '18px' }}>
                    {successGetClientProfit === 0
                      ? '0'
                      : formatLossGainValue(successGetClientProfit?.capitalAppreciation)}
                  </AmountText>
                </CardBodyText>
              </CardBody>
            </Card>
          </Stack>
        )}

        <TitleSectionContainer sx={{ marginTop: '4px' }}>
          <TitleSection variant='h6' sx={{ flexGrow: 1 }}>
            Mis Activos
          </TitleSection>
          <IteractionAssetsFilt>
            <BtnFilterFound
              sx={{
                color: selectedFilter === 'fondos' ? 'rgba(148, 191, 225, 1) ' : '#fff',
              }}
              onClick={() => handleShowProducts('fondos')}>
              Fondos
            </BtnFilterFound>
            <Divider>|</Divider>
            <BtnFilterFound
              sx={{
                color: selectedFilter === 'portfolio' ? 'rgba(148, 191, 225, 1) ' : '#fff',
              }}
              onClick={() => handleShowProducts('portfolio')}>
              Portfolio
            </BtnFilterFound>
          </IteractionAssetsFilt>
        </TitleSectionContainer>
        {selectedFilter === 'fondos' ? (
          loadingGetClientInvestments ? (
            <Spinner />
          ) : successGetClientInvestments.length >= 1 &&
            successGetClientInvestments
              ?.filter(item => item.name !== 'Caja')
              ?.every(item => Number.parseFloat(Number.parseInt(item.marketValue)) <= 0) ? (
            <SectionNoMarketValue
              tap={selectedFilter}
              handleRedirect={() => navigate('/products')}
            />
          ) : successGetClientInvestments && successGetClientInvestments.length > 1 ? (
            <ListProduct gap={0.5} overflow={'hidden'} maxHeight={'180px'}>
              {successGetClientInvestments
                .filter(item => item.id !== process.env.REACT_APP_CASH_ID && item.marketValue >= 1)
                .map((investment, index) => {
                  const custom = JSON.parse(investment.custom);
                  const riskProfile = custom.preferences.riesgo.description;

                  return (
                    <PaymentCards
                      key={index}
                      investment={investment}
                      handleDetails={handleDetails}
                      riskProfile={riskProfile}
                    />
                  );
                })}
            </ListProduct>
          ) : (
            <SectionNoProductComponent handleRedirect={handleRedirect} />
          )
        ) : loadingInvestmentPortfolio ? (
          <Spinner />
        ) : successDataInvestmentPortfolio.length >= 1 &&
          successDataInvestmentPortfolio
            ?.filter(item => item.account !== '' && item.account.includes('@') === false)
            ?.map(item => Number.parseFloat(Number.parseInt(item.marketValue.replace(/\$/g, ''))))
            ?.every(item => item <= 0) ? (
          <SectionNoMarketValue
            tap={selectedFilter}
            handleRedirect={() => navigate('/portfolio')}
          />
        ) : successDataInvestmentPortfolio && successDataInvestmentPortfolio.length > 1 ? (
          <ListProduct gap={0.5} overflow={'hidden'} maxHeight={'180px'}>
            {successDataInvestmentPortfolio
              .filter(
                portfolio =>
                  portfolio.account !== '' &&
                  portfolio.account.includes('@') === false &&
                  Number.parseFloat(
                    portfolio.marketValue.replace(/[^0-9,]/g, '').replace(',', '.'),
                  ) >= 1,
              )
              .map(portfolio => {
                const { account, marketValue, id: portfolioId } = portfolio;
                const marketValueformated = marketValue.replace(/[^0-9,]/g, '').replace(',', '.');
                const fileAs = portfolioNameAndDetails(account);
                return (
                  <PaymentCardPortfolio
                    key={portfolioId}
                    handleDetailsInvestmentPortfolio={handleDetailsInvestmentPortfolio}
                    fileAs={fileAs}
                    account={fileAs?.fileAsComplete.slice(10)}
                    marketValue={marketValueformated}
                    portfolioId={portfolioId}
                  />
                );
              })}
          </ListProduct>
        ) : (
          <SectionNoProductComponent handleRedirect={handleRedirect} />
        )}
      </Stack>
    </Stack>
  );
};

export default SectionInteraction;
