import { Stack, Typography, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import capitalize from '../../utils/capitalize';

const TitleDashboard = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '12px 0px 0px 0px',
  boxSizing: 'border-box',
  width: '100%',
});

const TitleType = styled(Typography)({
  textAlign: 'left',
  whiteSpace: '`pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(19, 41, 75, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',

  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
  margin: '0px',
});

const NameSection = styled(Typography)({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(68, 73, 216, 1)',
  fontStyle: 'normal',
  fontFamily: 'Museo-Regular',
  fontWeight: '400',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126.49999856948853%',
  textTransform: 'none',
  WebkitTextStroke: '1.2413792610168457px rgba(68, 73, 216, 1)',
  margin: '32px  0px',
  paddingTop: '32px',
});

export const GreetingDashboard = () => {
  const { user } = useSelector(state => state.auth);

  return (
    <TitleDashboard>
      <TitleType sx={{ fontSize: 'clamp(24px, 3vw, 28px)' }}>
        Hola{' '}
        <NameSection sx={{ fontSize: 'clamp(24px, 3vw, 28px)' }} component='span'>
          {capitalize(user.name)},
        </NameSection>{' '}
        que bueno verte de vuelta
      </TitleType>
    </TitleDashboard>
  );
};
