import { gql, useLazyQuery, useQuery } from '@apollo/client';

const GET_CLIENT_INVESTMENTS_PORTFOLIO_QUERY = gql`
  query GetClientInvestmentsPortfolios {
    getClientInvestmentsPortfolios {
      id
      account
      marketValue
    }
  }
`;
{
  /*Muestra los detalles de los portfolios del cliente o un mensaje indicando que no hay portfolios disponibles*/
}
export default () => useQuery(GET_CLIENT_INVESTMENTS_PORTFOLIO_QUERY, { fetchPolicy: 'no-cache' });
