import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Stack, Typography } from '@mui/material';
import NewPasswordInput from './NewPasswordInput';

const ConditionsPassword = ({
  password,
  mustContainData,
  validatePassword,
  confirmPassword,
  handleConfirmPassword,
}) => {
  return (
    <>
      <Stack
        sx={{
          background: 'linear-gradient(to bottom, #6080d0, #5070c7, #4160be, #3151b4, #2141aa);',
          width: '90%',
          height: { md: '210px', xs: '280px' },
          borderRadius: '12px',
          mb: '50px',
        }}
        justifyContent={'center'}
        alignItems={'center'}>
        <Stack width={'95%'}>
          <Box sx={{ display: 'flex' }}>
            <Box>
              <img src='/assets/images/perfiles/info.svg' alt='' />
            </Box>

            <Typography
              sx={{
                paddingLeft: '10px',
              }}
              color={'#fff'}>
              Asegúrate de que tu contraseña tenga:
            </Typography>
          </Box>

          {mustContainData.map((item, key) => {
            if (item[1] === false || item[2] === false) {
              return (
                <Box key={key}>
                  <Box
                    key={key}
                    sx={{
                      display: 'flex',
                      paddingY: '7px',
                    }}>
                    {password.length === 0 ? (
                      <Box height={'20px'}>
                        <img alt='' src='/assets/images/blankpassword.png' />
                      </Box>
                    ) : (
                      <CloseIcon sx={{ padding: 0, margin: 0, color: '#D12D35' }} />
                    )}

                    <Typography
                      sx={{
                        fontSize: '16px',
                        paddingLeft: password.length === 0 ? '10px' : '5px',
                      }}
                      color={'#fff'}>
                      {item[0]}
                    </Typography>
                  </Box>
                </Box>
              );
            } else {
              return (
                <Box key={key}>
                  <Box
                    sx={{
                      display: 'flex',
                      paddingY: { md: '7px', xs: '2px' },
                    }}>
                    <DoneIcon style={{ color: '#49A197' }} />

                    <Typography sx={{ fontSize: '16px', paddingLeft: '5px' }} color={'#fff'}>
                      {item[0]}
                    </Typography>
                  </Box>
                </Box>
              );
            }
          })}
        </Stack>
      </Stack>
      <Box sx={{ width: '90%', mb: '50px' }}>
        <NewPasswordInput
          title={'Nueva contraseña'}
          password={password}
          validatePassword={validatePassword}
        />
      </Box>
      <Box sx={{ width: '90%', mb: '50px' }}>
        <NewPasswordInput
          title={'Repetir contraseña'}
          password={confirmPassword}
          validatePassword={handleConfirmPassword}
        />
      </Box>
    </>
  );
};

export default ConditionsPassword;
