import { Button, Stack, styled } from '@mui/material';
import {
  ButtonsPrimary,
  ButtonsSecundary,
  ButtonsTertiary,
} from '../ComponentApp/buttoms/ButtonsXcala';

const CardsButtonContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  gap: '8px',
  paddingBottom: '8px',
  height: '100%',
});

const BtnGroup = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
  gap: '8px',
});
const BtnPrimary = styled(Button)({
  flex: '1',
  height: '50px',
  backgroundColor: 'rgba(30, 34, 170, 1)',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(10, 14, 150, 1)',
  },
});

const BtnSecundary = styled(Button)({
  flex: '1',
  height: '50px',
  margin: '0px',
  color: 'rgba(30, 34, 170, 1)',
  borderColor: 'rgba(30, 34, 170, 1)',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(30, 34, 170, 1)',
    color: 'rgba(244, 244, 251, 1)',
  },
});

const BtnInformation = styled(Button)({
  backgroundColor: 'rgba(244, 244, 251, 1)',
  color: 'rgba(30, 34, 170, 1)',
  flex: '1',
  alignSelf: 'stretch',
  minHeight: '50px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(234, 234, 241, 1)',
  },
});

export const CardsButtomGroup = ({ goToProductDetail, goToSimulator, goToInvestment }) => {
  return (
    <CardsButtonContainer>
      <BtnGroup>
        <ButtonsSecundary name='Simular' onClick={goToSimulator} sx={{ flex: '1' }} />

        <ButtonsPrimary name='Invertir' onClick={goToInvestment} sx={{ flex: '1' }} />
      </BtnGroup>
      <ButtonsTertiary name='Más Información' onClick={goToProductDetail} fullWidth />
    </CardsButtonContainer>
  );
};
