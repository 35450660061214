const riskProfile = {
  1: 'conservador',
  2: 'moderado',
  3: 'agresivo',
};

export const getRiskWarning = (userRisk, productRisk) => {
  let userRiskId = -1;
  Object.keys(riskProfile).forEach(key => {
    if (riskProfile[key] === userRisk.toLowerCase()) {
      userRiskId = key;
    }
  });
  return productRisk > userRiskId ? true : false;
};
