import HelpIcon from '@mui/icons-material/Help';
import { Box, Button, Link, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LayoutProfile from '../../../components/Layout/InternalLayout/LayoutProfile';
import Spinner from '../../../components/Spinner';
import DialogBox from '../../../components/UI/modal';
import { NotificationsXcala } from '../../../components/XcalaComponents/Notifications/NotificationsXcala';
import SelectorImput from '../../../components/profile/SelectorImput';
import useModal from '../../../hooks/custom/useModal';
import { resetUpdateUserAttributesSubmit, updateUserAttributesSubmit } from '../../../redux/auth';
import capitalize from '../../../utils/capitalize';
import { contentAgresivo, contentConservador, contentModerado } from '../../../utils/constants';
import { onRefresh } from '../../../utils/refreshPage';

export const IconsRiskProfile = styled('img')({
  objectFit: 'cover',
});

const ProfileSelect = () => {
  const {
    loadingUpdateUserAttributes,
    successUpdateUserAttributes,
    errorUpdateUserAttributes,
    user,
  } = useSelector(state => state.auth);

  const [selectedTitle, setSelectedTitle] = useState(
    user['custom:profile'] ? capitalize(user['custom:profile']) : null,
  );
  const [selectedContent, setSelectedContent] = useState(null);
  const [profile, setProfile] = useState('');
  const { isShowing, toggle } = useModal();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelect = (title, content) => {
    setSelectedTitle(title);
    setSelectedContent(content);
  };

  const handleRiskProfile = riskProfile => {
    if (riskProfile === 'conservador') return '/assets/images/dashboard/perfiles/conservador.svg';
    if (riskProfile === 'moderado') return '/assets/images/dashboard/perfiles/moderado.svg';
    if (riskProfile === 'agresivo') return '/assets/images/dashboard/perfiles/agresivo.svg';
  };

  const pepModal = {
    className: 'profile-tab-popup',
    title: 'Antes de continuar...',
    content: 'Declaro entender y aceptar lo que implica identificarse con ese perfil de riesgo',
    btnContent: 'Entendido',
    btnContent2: 'Cancelar',
  };

  const profileContentMap = {
    conservador: contentConservador,
    moderado: contentModerado,
    agresivo: contentAgresivo,
  };

  const userCustomProfile = user['custom:profile'] ? user['custom:profile'].toLowerCase() : null;

  const userProfileContent = profileContentMap[userCustomProfile];

  const handleGoToQuestions = () => {
    navigate('/questions');
  };

  const handleClickOpen = value => {
    setProfile(value);
    setOpen(true);
  };

  const handleBtn = () => {
    setOpen(false);
    dispatch(updateUserAttributesSubmit({ name: 'custom:profile', value: profile }));
  };

  useEffect(() => {
    if (successUpdateUserAttributes && !loadingUpdateUserAttributes) {
      dispatch(resetUpdateUserAttributesSubmit());
      navigate('/myprofilerisk');
    }
  }, [loadingUpdateUserAttributes, successUpdateUserAttributes]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onboarding profile',
        path: '/profile',
        link: {
          url: '/profile',
          title: 'onboarding',
        },
      },
    });
  }, []);

  if (loadingUpdateUserAttributes) {
    return <Spinner />;
  }
  if (errorUpdateUserAttributes) {
    return (
      <NotificationsXcala
        activation={!isShowing}
        message='Lo sentimos, ha ocurrido un error inesperado....'
        nameBtnTwo='Enviar error'
        toggle={toggle}
        refresh={onRefresh}
        error={errorUpdateUserAttributes}
      />
    );
  }

  return (
    <>
      <DialogBox
        setOpen={setOpen}
        open={open}
        className={pepModal.className}
        modal_title={pepModal.title}
        modal_content={pepModal.content}
        action_btn2={pepModal.btnContent2}
        action_btn={pepModal.btnContent}
        handleBtn={handleBtn}
      />

      <Stack
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          minHeight: '100vh',
          position: 'relative',
          overflow: 'hidden',
          padding: 0,
          margin: 0,
        }}>
        {user['custom:profile'] ? (
          <LayoutProfile
            user={user}
            accordionProfile={true}
            selectedContent={selectedContent || userProfileContent}>
            <Stack
              sx={{
                width: { md: '60%' },
                mx: 'auto',
                boxSizing: 'border-box',
                flexDirection: 'column',
                padding: 'clamp(1rem, -1.8571rem + 12.1905vw, 2rem);',
                bgcolor: '#fff',
                borderRadius: '1rem',
                boxShadow: '0px 0px 1rem 0px rgba(0, 0, 0, 0.1)',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '10rem',
              }}
              gap={1}>
              <Box>
                <IconsRiskProfile
                  sx={{
                    width: 'clamp(5.1875rem, 1.4375rem + 16vw, 10.4375rem);',
                  }}
                  src={`/assets/images/${selectedTitle.toLowerCase()}.svg`}
                />
              </Box>
              <Stack
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  gap: 2,
                  marginBottom: '1rem',
                }}>
                <SelectorImput
                  TitleContent='Conservador'
                  contentProfile={contentConservador}
                  selected={selectedTitle === 'Conservador'}
                  onSelect={handleSelect}
                />
                <SelectorImput
                  TitleContent='Moderado'
                  contentProfile={contentModerado}
                  selected={selectedTitle === 'Moderado'}
                  onSelect={handleSelect}
                />
                <SelectorImput
                  TitleContent='Agresivo'
                  contentProfile={contentAgresivo}
                  selected={selectedTitle === 'Agresivo'}
                  onSelect={handleSelect}
                />
                <Stack
                  direction={'row'}
                  sx={{
                    marginTop: '1rem',
                    color: '#1E22AA',
                  }}>
                  <HelpIcon />
                  <Link
                    component='button'
                    sx={{
                      fontSize: '1rem',
                      marginLeft: '6px',
                      fontWeight: '600',
                    }}
                    onClick={handleGoToQuestions}>
                    No estoy seguro de mi perfil, ayúdenme a descubrirlo
                  </Link>
                </Stack>
                <Stack
                  sx={{
                    width: '80%',
                    margin: '0 auto',
                    marginTop: '2rem',
                  }}>
                  <Button
                    fullWidth
                    disabled={selectedTitle === null}
                    type='button'
                    className='button-primary'
                    onClick={() => handleClickOpen(selectedTitle)}>
                    Es mi perfil
                  </Button>
                </Stack>
              </Stack>
              <Link component='button' variant='body2' onClick={() => navigate(-1)}>
                Volver
              </Link>
            </Stack>
          </LayoutProfile>
        ) : (
          <LayoutProfile user={user} accordionProfile={true} selectedContent={selectedContent}>
            <Stack
              sx={{
                width: { md: '60%' },
                mx: 'auto',
                boxSizing: 'border-box',
                flexDirection: 'column',
                padding: 'clamp(1rem, -1.8571rem + 12.1905vw, 2rem);',
                bgcolor: '#fff',
                borderRadius: '1rem',
                boxShadow: '0px 0px 1rem 0px rgba(0, 0, 0, 0.1)',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '10rem',
              }}
              gap={1}>
              <Box>
                <IconsRiskProfile
                  sx={{
                    width: 'clamp(5.1875rem, 1.4375rem + 16vw, 10.4375rem);',
                  }}
                  src={
                    selectedContent === null
                      ? '/assets/images/perfil-noDefined.svg'
                      : handleRiskProfile(selectedTitle.toLowerCase())
                  }
                />
              </Box>
              <Stack
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  gap: 2,
                  marginBottom: '1rem',
                }}>
                <SelectorImput
                  TitleContent='Conservador'
                  contentProfile={contentConservador}
                  selected={selectedTitle === 'Conservador'}
                  onSelect={handleSelect}
                />
                <SelectorImput
                  TitleContent='Moderado'
                  contentProfile={contentModerado}
                  selected={selectedTitle === 'Moderado'}
                  onSelect={handleSelect}
                />
                <SelectorImput
                  TitleContent='Agresivo'
                  contentProfile={contentAgresivo}
                  selected={selectedTitle === 'Agresivo'}
                  onSelect={handleSelect}
                />
                <Stack
                  direction={'row'}
                  sx={{
                    marginTop: '1rem',
                    color: '#1E22AA',
                  }}>
                  <HelpIcon />
                  <Link
                    component='button'
                    sx={{
                      fontSize: '1rem',
                      marginLeft: '6px',
                      fontWeight: '600',
                    }}
                    onClick={handleGoToQuestions}>
                    No estoy seguro de mi perfil, ayúdenme a descubrirlo
                  </Link>
                </Stack>
                <Stack
                  sx={{
                    width: '80%',
                    margin: '0 auto',
                    marginTop: '2rem',
                  }}>
                  <Button
                    fullWidth
                    disabled={selectedTitle === null}
                    type='button'
                    className='button-primary'
                    onClick={() => handleClickOpen(selectedTitle)}>
                    Es mi perfil
                  </Button>
                </Stack>
              </Stack>
              <Link component='button' variant='body2' onClick={() => navigate(-1)}>
                Volver
              </Link>
            </Stack>
          </LayoutProfile>
        )}
      </Stack>
    </>
  );
};

export default ProfileSelect;
