import { Box, Modal, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonXcalaBlue } from './ButtonXcala';
import ImageCustom from './ImageCustom';

const ModalRescueDuplicate = ({ open, onClose }) => {
  const navigate = useNavigate();

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          minWidth: '350px',
          maxWidth: '650px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <Paper elevation={12} sx={{ borderRadius: 2, padding: 3, width: '100%' }}>
          <Box flexDirection='row'>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              spacing={2}
              width='100%'
              alignItems='center'>
              <Box
                sx={{
                  width: '25%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <ImageCustom
                  imageSrc={'/assets/images/warning2.svg'}
                  sx={{
                    width: '100%',
                  }}
                />
              </Box>
              <Box sx={{ width: '100%', textAlign: { xs: 'center', md: 'left' } }}>
                <Typography color='textSecondary' sx={{ mt: 1 }}>
                  Estamos procesando un rescate de este mismo fondo en este momento. Por favor
                  espera a que este rescate quede procesado para generar otro.
                </Typography>

                <ButtonXcalaBlue
                  maxWidth='60%'
                  marginTop={2}
                  paddingX={2}
                  paddingY='6px'
                  onClick={() => {
                    onClose();
                    navigate('/profilerisk');
                  }}>
                  ¡Aceptar!
                </ButtonXcalaBlue>
              </Box>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ModalRescueDuplicate;
