import { Button } from '@mui/material';
import React from 'react';

export const ButtonXcalaGray = ({
  children,
  onClick,
  disabled,
  paddingX,
  paddingY,
  fullWidth,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      size='large'
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onClick}
      sx={{
        maxWidth: '100%',
        paddingX: paddingX,
        paddingY: paddingY,
        borderRadius: '8px',
        borderColor: '#1e22aa',
        color: '#1e22aa',
        border: 'none',
        background: '#F4F4FB',
        fontWeight: '600',
        textTransform: 'none',
        fontSize: '16px',
        ':hover': {
          background: '#F4F4FB',
        },
      }}>
      {children}
    </Button>
  );
};

export const ButtonXcalaBlue = ({
  children,
  onClick,
  disabled,
  paddingX,
  paddingY,
  fullWidth,
  marginTop,
  maxWidth,
  fontWeight = 'normal',
  ...rest
}) => {
  return (
    <Button
      {...rest}
      fullWidth={fullWidth}
      variant='contained'
      disabled={disabled}
      onClick={onClick}
      sx={{
        marginTop: marginTop,
        maxWidth: maxWidth,
        paddingX: paddingX,
        paddingY: paddingY,
        fontWeight: fontWeight,
        borderRadius: '4px',
        color: '#fff',
        border: 'none',
        background: '#1e22aa',
        textTransform: 'none',
        fontSize: '16px',
        ':hover': {
          background: '#1e22aa',
        },
      }}>
      {children}
    </Button>
  );
};

export const ButtonXcalaLink = ({ children, onClick, disabled }) => {
  return (
    <Button
      variant='outlined'
      disabled={disabled}
      onClick={onClick}
      sx={{
        borderRadius: '4px',
        color: 'gray',
        border: 'none',
        textTransform: 'none',
        fontSize: '16px',
        ':hover': {
          color: '#1e22aa',
          border: 'none',
          backgroundColor: 'transparent',
        },
      }}>
      {children}
    </Button>
  );
};

export const ButtonXcalaOutline = ({
  children,
  onClick,
  disabled,
  paddingX,
  paddingY,
  fullWidth,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      fullWidth={fullWidth}
      variant='outlined'
      disabled={disabled}
      onClick={onClick}
      sx={{
        paddingX: paddingX,
        paddingY: paddingY,
        borderRadius: '4px',
        color: '#1E22AA',
        border: '1px solid #1E22AA',
        fontWeight: '600',
        textTransform: 'none',
        fontSize: '16px',
        ':hover': {
          background: 'none',
          border: '1px solid #1E22AA',
        },
      }}>
      {children}
    </Button>
  );
};

export const ButtonXcalaOutlineSecondary = ({
  children,
  onClick,
  disabled,
  paddingX,
  paddingY,
  fullWidth,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      fullWidth={fullWidth}
      variant='contained'
      disabled={disabled}
      onClick={onClick}
      sx={{
        paddingX: paddingX,
        paddingY: paddingY,
        borderRadius: '4px',
        color: '#1E22AA',
        border: '1px solid #1E22AA',
        fontWeight: '600',
        textTransform: 'none',
        fontSize: '16px',
        background: 'none',
        ':hover': {
          background: 'none',
          border: '1px solid #1E22AA',
        },
      }}>
      {children}
    </Button>
  );
};
