import { Grid, Stack, styled, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setProfitability, setSecuritySelected } from '../../../redux/simulation';
import { getFirstElement } from '../../../utils/getFirstCustom';
import { CardProductsComponent } from '../../XcalaComponents';
import { BodyCardsContainer } from './BodyCardsContainer';
import { HeadContainerCards } from './HeadContainerCards';

export const CardsFound = ({
  successGetProfitability,
  security,
  typeCurrency,
  rentabilityFound,
  fundName,
  foundTerm,
  fundPerfile,
  funDescription,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);

  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';
  const custom = JSON.parse(security.Custom);
  const risk = custom.preferences.riesgo.description;
  const motivations = getFirstElement(custom.preferences.motivaciones);
  const interests = getFirstElement(custom.preferences.intereses);
  const motivationsId = custom.preferences.motivaciones[0].id;
  const interestsId = custom.preferences.intereses[0].id;

  const isMediumScreen = useMediaQuery('(max-width:1407px) and (min-width:900px)');

  const goToProductDetail = () => {
    dispatch(setProfitability(successGetProfitability));
    dispatch(setSecuritySelected(security));
    navigate('/productdetail');
  };

  const goToSimulator = () => {
    dispatch(setSecuritySelected(security));
    navigate('/simulator');
  };

  return (
    <Grid item xs={12} sm={12} md={isMediumScreen ? 6 : 4} style={{ maxWidth: '100%' }}>
      <CardProductsComponent>
        <HeadContainerCards
          fundName={fundName}
          fundPerfile={fundPerfile}
          risk={risk}
          userRisk={userRisk}
        />
        <BodyCardsContainer
          intereses={interests.split(',')[0]}
          motivation={motivations.split('-')[0]}
          typeCurrency={typeCurrency}
          profileCards={risk}
          rentabilityFound={rentabilityFound}
          foundTerm={foundTerm}
          funDescription={funDescription}
          motivationsId={motivationsId}
          interestsId={interestsId}
          goToProductDetail={goToProductDetail}
          goToSimulator={goToSimulator}
        />
      </CardProductsComponent>
    </Grid>
  );
};
