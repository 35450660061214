import { Divider, Stack, styled } from '@mui/material';
import React from 'react';
import { FormatCurrency } from '../../utils/numbers';
import WalletAmount from './WalletAmount';

const ContentFloating = styled(Stack)({
  position: 'relative',
  margin: '0 auto',
  bottom: '-50px',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '8px',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 24px',
  boxSizing: 'border-box',
  overflow: 'hidden',
  height: '89px',
  width: '100%',
  gap: '8px',
  animation: 'ease-in-out',
});

const CoinFloating = ({ balanceClient, marginTop }) => {
  return (
    <>
      <ContentFloating
        marginTop={marginTop}
        maxWidth={{ xs: '90%', sm: '30%' }}
        minWidth={{ xs: '360px', sm: '600px' }}>
        <WalletAmount
          coinValue={FormatCurrency(balanceClient?.balance)}
          direction={'start'}
          title='Caja Disponible'
          textAlign={'left'}
        />
        <Divider orientation='vertical' color='rgba(24, 40, 73, 0.12)' />
        <WalletAmount
          direction='end'
          title='Dinero en Tránsito'
          coinValue={FormatCurrency(balanceClient?.transitAmount)}
          textAlign={'right'}
        />
      </ContentFloating>
    </>
  );
};

export default CoinFloating;
