import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useModal from '../../hooks/custom/useModal';
import { useGetInvestedAndMarketValue } from '../../hooks/useGetInvestedAndMarketValue';
import { onRefresh } from '../../utils/refreshPage';
import Spinner from '../Spinner';
import ModalErrorFetch from '../UI/ModalErrorFetch';
import ChartsDashboard from './ChartsDashboard';

const CardChartsDashboard = () => {
  const [startDate, setStartDate] = useState('01/01/2000');
  const { isShowing, toggle } = useModal();
  const [active, setActive] = useState(200);
  const [balanceMarketValue, setBalanceMarketValue] = useState();
  const {
    data: dataInvestedAndMarketValue,
    loading: loadingDataInvestedAndMarketValue,
    error: errorDataInvestedAndMarketValue,
  } = useGetInvestedAndMarketValue({
    data: {
      startDate,
    },
  });
  const handleChartDataByMonths = (months, chartData) => {
    const copyBalanceMarketValue = [...chartData];
    setBalanceMarketValue(copyBalanceMarketValue.slice(-months));
  };
  useEffect(() => {
    if (dataInvestedAndMarketValue) {
      handleChartDataByMonths(active, dataInvestedAndMarketValue?.getInvestedAndMarketValue);
    }
  }, [loadingDataInvestedAndMarketValue, active]);

  if (errorDataInvestedAndMarketValue) {
    return (
      <ModalErrorFetch
        message='Lo sentimos, algo no salio bien acá....'
        error={errorDataInvestedAndMarketValue}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  }

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        boxShadow: 'none',
        padding: '8px',
      }}>
      <CardHeader
        title={
          <Typography
            sx={{
              fontSize: '24px',
            }}>
            Evolución de la Inversión
          </Typography>
        }
      />

      <CardContent>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent='space-between'
          width='70%'>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={1}>
            <Box
              style={{
                width: '7px',
                height: '7px',
                background: '#49A197',
                display: 'inline',
                borderRadius: '100%',
              }}
            />
            <Typography
              component='p'
              sx={{
                color: '#182849',
                fontWeight: '600',
                fontSize: '16px',
              }}>
              Balance
            </Typography>
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={1}>
            <Box
              style={{
                width: '7px',
                height: '7px',
                background: '#7F56D9',
                display: 'inline',
                borderRadius: '100%',
              }}
            />
            <Typography
              component='p'
              sx={{
                color: '#182849',
                fontWeight: '600',
                fontSize: '16px',
              }}>
              Depositado
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardContent
        sx={{
          padding: '4px',
        }}>
        <Box display='flex' flexDirection='row' sx={{ ml: 1 }}>
          <ButtonGroup
            sx={{
              width: '100%',
              '& .MuiButton-outlined': {
                borderColor: '#1E22AA',
                '&:hover': {
                  borderColor: '#1E22AA',
                },
                '&:focus': {
                  borderColor: '#1E22AA',
                },
              },
            }}
            variant='outlined'
            aria-label='outlined primary button group'
            size='small'>
            <Button
              onClick={() => setActive(200)}
              style={{
                backgroundColor: active === 200 ? '#1E22AA' : 'initial',
                color: active === 200 ? 'white' : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              todo
            </Button>
            <Button
              onClick={() => setActive(12)}
              style={{
                backgroundColor: active === 12 ? '#1E22AA' : 'initial',
                color: active === 12 ? 'white' : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              12 meses
            </Button>
            <Button
              onClick={() => setActive(3)}
              style={{
                backgroundColor: active === 3 ? '#1E22AA' : 'initial',
                color: active === 3 ? 'white' : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              3 meses
            </Button>
            <Button
              onClick={() => setActive(1)}
              style={{
                backgroundColor: active === 1 ? '#1E22AA' : 'initial',
                color: active === 1 ? 'white' : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              1 mes
            </Button>
          </ButtonGroup>
        </Box>
      </CardContent>
      {loadingDataInvestedAndMarketValue ? (
        <Spinner />
      ) : (
        <ChartsDashboard data={balanceMarketValue} serieName={'Saldo'} showLabels={true} />
      )}
    </Card>
  );
};
export default CardChartsDashboard;
