import { Stack, Typography, styled } from '@mui/material';
import { portfolioNameAndDetails } from '../../utils/portfolioNameAndDetails';

const HeadCards = styled(Stack)({
  backgroundColor: 'rgba(30, 34, 170, 1)',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '16px 32px',
  boxSizing: 'border-box',
  flex: '1',
  margin: '0px',
  width: '100%',
});
const HeadChips = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  height: '22.9px',
  width: '100%',
});

const GeneralMotivations = styled(Stack)({
  backgroundColor: 'rgba(231, 243, 255, 1)',
  borderRadius: '14px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2.5px 8px',
  boxSizing: 'border-box',
  height: '22px',
  margin: '0px',
  width: '42px',
});
const HmcText = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(30, 34, 170, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '10px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});

const StarContainer = styled(Stack)({
  backgroundColor: 'rgba(90, 196, 177, 1)',
  borderRadius: '17px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 7px',
  boxSizing: 'border-box',
  margin: '0px 0px 0px 8px',
  height: '22px',
  width: '36px',
});

const StarIcons = styled('img')({
  height: '16px',
  width: '16px',
  margin: '0px',
});
const HeadTitle = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(231, 243, 255, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '26px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '119.49999332427979%',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '18px 0px 0px 0px',
});

const SectionInversion = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '2px 0px',
  boxSizing: 'border-box',
  margin: '8px 0px 0px 0px',
  height: '27px',
});

const InversionPeymen = styled('img')({
  margin: '0px',
  width: '24px',
  height: '24px',
  paddingRight: '8px',
});

export const HeadContainerCardPorfolio = ({
  userRisk,
  size = '26px',
  version = 'start',
  textAlination = 'left',
  typePeyment,
  detailsStyleDashboard,
  iconAlineation = 'start',
  portfolioDetailsFileAs,
}) => {
  return (
    <>
      <HeadCards
        alignItems={version}
        style={{
          marginBottom: detailsStyleDashboard === 12 ? '16px' : '0',
        }}>
        <HeadChips sx={{ justifyContent: { md: 'start', xs: iconAlineation } }}>
          <GeneralMotivations>
            <HmcText>HMC</HmcText>
          </GeneralMotivations>

          {portfolioDetailsFileAs?.risk &&
            portfolioDetailsFileAs?.risk.toLowerCase() === userRisk && (
              <StarContainer>
                <StarIcons src='/assets/images/start-icons.svg' alt='start-icons' />
              </StarContainer>
            )}
        </HeadChips>
        <HeadTitle textAlign={textAlination} fontSize={size}>
          {portfolioDetailsFileAs?.fileAsComplete}
        </HeadTitle>

        {typePeyment && (
          <SectionInversion>
            <InversionPeymen
              src={`/assets/images/perfiles/icon-${portfolioDetailsFileAs.currency.toLowerCase()}.svg`}
              alt={typePeyment}
            />
            <Typography
              variant={'body1'}
              color={'#fff'}>{`Inversiones en ${portfolioDetailsFileAs.currency}`}</Typography>
          </SectionInversion>
        )}
      </HeadCards>
    </>
  );
};
