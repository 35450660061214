import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateMyInteresets } from '../../redux/myinteresets';
import getIcon from '../../utils/iconByName';
import { CardPerfilInversor } from './ObjectivesInterestsMuiComponents';

const NewDetailItem = ({ id, title, icon_name, checked }) => {
  const dispatch = useDispatch();

  const handleCheckbox = id => {
    dispatch(updateMyInteresets(id));
  };

  return (
    <Stack
      sx={{
        width: '100%',
        margin: '0 auto',
        boxSizing: 'border-box',
      }}>
      <CardPerfilInversor
        sx={{
          padding: { md: '18px 20px', xs: '10px 11px' },
        }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {getIcon(icon_name, 'dark-green')}
          <Typography sx={{ fontWeight: '600', fontSize: { md: '18px', xs: '14px' } }}>
            {title}
          </Typography>
        </Box>

        <Stack sx={{ boxSizing: 'border-box' }} alignItems={'center'} justifyContent={'center'}>
          <Checkbox
            sx={{ boxSizing: 'border-box', padding: '0' }}
            checked={checked}
            onChange={e => {
              e.stopPropagation();
              handleCheckbox(id);
            }}
          />
        </Stack>
      </CardPerfilInversor>
    </Stack>
  );
};

export default NewDetailItem;
