import styled from '@emotion/styled';
import {
  Accordion,
  AccordionSummary,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  ListItem,
  IconButton,
  MenuItem,
  Button,
  List,
} from '@mui/material';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import MoodOutlinedIcon from '@mui/icons-material/MoodOutlined';

const drawerWidth = 300;

const typographyStyles = menuItemName => ({
  color: menuItemName === 'Referidos' ? '#1E22AA' : '#fff',
  textAlign: 'center',
  fontSize: '16px',
  marginLeft: '6px',
});

export const TypographyReferrals = styled(Typography)(({ menuItem }) => ({
  fontSize: '16px',
  ...(menuItem.name === 'Referidos'
    ? {
        textAlign: 'center',
        width: '100%',
      }
    : typographyStyles(menuItem.name)),
}));

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(17.5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(17.5)} + 1px)`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      padding: '0px 24px 24px 24px',
      background:
        'linear-gradient(-174.87deg, rgba(112, 151, 217, 1) -36.71897846239968%, rgba(30, 34, 170, 1) 103.47570081720376%)',
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      padding: '0px 24px 24px 24px',
      background:
        'linear-gradient(-174.87deg, rgba(112, 151, 217, 1) -36.71897846239968%, rgba(30, 34, 170, 1) 103.47570081720376%)', // Ejemplo, si necesitas aplicar estilos diferentes
    },
  }),
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: '80px',
  backgroundColor: '#1E22AA',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const IconButtonToolbar = styled(IconButton)(({ open }) => ({
  marginRight: 5,
  display: open && 'none',
}));

export const BoxContainer = styled(Box)({
  display: 'flex',
  height: '100vh',
  overflow: 'hidden',
});

export const AccordionWrapper = styled(Box)({
  width: '100%',
  marginTop: '6px',
});

export const IconArrowRight = styled(ChevronRightIcon)({
  height: '40px',
  width: '40px',
  marginLeft: '24px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
  },
});

export const IconArrowLeft = styled(ChevronLeftIcon)({
  color: '#fff',
  marginTop: '24px',
  height: '40px',
  width: '40px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
  },
});

export const IconButtonArrow = styled(IconButton)({
  padding: 0,
  margin: 0,
});

export const LogoImage = styled('img')({
  height: '96px',
  marginTop: '-22px',
  cursor: 'pointer',
});

export const BoxLogo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100px',
  width: '100%',
  marginTop: '2rem',
});

export const ListItemButtonMenu = styled(ListItemButton)({
  minHeight: '54px',
  justifyContent: 'initial',
  maxWidth: '247px',
  px: 2.5,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  borderRadius: '4px',
  marginLeft: '0px',
  '&:hover': {
    backgroundColor: '#1E22AA',
  },
});

export const ListItemIconMenu = styled(ListItemIcon)({
  minWidth: 40,
  display: 'flex',
  justifyContent: 'start',
  transition: 'none',
});

export const ListItemIcons = styled(ListItemIcon)({
  minWidth: 0,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  left: '20px',
});

export const IconDashboard = styled(GridViewOutlinedIcon)({
  height: '26px',
  width: '26px',
  color: 'rgba(255, 255, 255, 1)',
  transition: 'none',
  position: 'relative',
  display: 'block',
  marginLeft: '3px',
});

export const IconTransactions = styled(ReceiptOutlinedIcon)({
  height: '26px',
  width: '26px',
  color: 'rgba(255, 255, 255, 1)',
  marginLeft: '3px',
});

export const ArrowRight = styled(KeyboardArrowRightIcon)({
  color: '#fff',
  marginLeft: '-10px',
});

export const ListItemTextMenu = styled(ListItemText)(({ open }) => ({
  marginLeft: '10px',
  whiteSpace: 'pre-wrap',
  opacity: open ? 1 : 0,
  '& .MuiTypography-root': {
    fontSynthesis: 'none',
    fontStyle: 'normal',
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '18px',
    letterSpacing: '0px',
    lineHeight: '28px',
    textDecoration: 'none',
    textTransform: 'none',
    color: 'rgba(255, 255, 255, 1)',
  },
}));

export const CircleBullet = styled(Box)({
  height: 8,
  width: 8,
  borderRadius: '50%',
  backgroundColor: '#fff',
  marginRight: 4,
});

export const Circle = styled(Box)(({ theme }) => ({
  height: 8,
  width: 8,
  borderRadius: '50%',
  backgroundColor: '#0B0D17',
  marginRight: 4,
  transition: 'background-color 0.1s',
  color: 'inherit',
}));

export const AccordionMenu = styled(Accordion)({
  minHeight: 48,
  px: 2.5,
  width: '100%',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  border: 'none',
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root': {
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  padding: 0,
});

export const BoxSpinner = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

export const MenuItemMiniDrawer = styled(MenuItem)(({ subItem }) => ({
  borderRadius: '4px !important',
  justifyContent: `${
    subItem.btnNameSub === 'Caja Disponible' || subItem.btnNameSub === 'Dinero en Tránsito'
      ? 'center'
      : 'flex-start'
  }`,
  color: 'inherit',
  '&:hover': {
    borderRadius: '8px',
    backgroundColor: '#4449D8',
    color: '#fff',
    '& .circle-bullet': {
      backgroundColor: '#fff',
    },
  },
}));

export const AccordionSummaryMenu = styled(AccordionSummary)({
  backgroundColor: '#1E22AA',
  width: '100%',
  borderRadius: '4px',
  minHeight: '30px',
  '&.MuiAccordionSummary-root': {
    minHeight: '30px',
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    minHeight: '30px',
    padding: '0px',
  },
  '&.Mui-expanded': {
    minHeight: '30px',
    margin: 0,
  },
  padding: '0px 20px',
});

export const StackAcountItem = styled(Stack)({
  width: '100%',
  height: '100%',
  backgroundColor: 'rgb(256,256,256,0.08)',
  marginTop: '0px',
  justifyContent: 'end',
  alignItems: 'center',
});

export const BoxChildren = styled(Box)({
  marginTop: '80px',
  height: 'calc(100% - 80px)',
  transition: 'all 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  position: 'relative',
  top: '0px',
  right: '0px',
});

export const ListItemWrapper = styled(ListItem)({
  display: 'block',
  width: '100%',
  marginTop: '-10px',
  marginBottom: '-10px',
});

export const ListItemWallet = styled(ListItem)({
  marginTop: '8px',
  marginBottom: '6px',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  borderRadius: '8px',
  height: '57px',
  gap: '10px',
});

export const ListItemButtonWallet = styled(ListItemButton)({
  textTransform: 'none',
  justifyContent: 'center',
  alignItems: 'flex-start',
  px: 2.5,
  height: '100%',
  width: '50%',
  backgroundColor: '#f0f0f0',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
});

export const ListItemButtonAccordion = styled(ListItemButton)(({ menuItem }) => ({
  textTransform: 'none',
  justifyContent: 'flex-start',
  px: 2.5,
  borderRadius: '4px',
  marginTop: '6px',
  ...(menuItem.name === 'Referidos' && {
    justifyContent: 'center',
    alignItems: 'center',
    mt: '10px',
    mb: '4px',
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    textTransform: 'none',
  }),
  '&:hover': {
    backgroundColor:
      menuItem.name === 'Referidos' ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.1)',
  },
  '& .circle-bullet': {
    backgroundColor: 'red',
    color: 'green',
  },
}));

export const TypographyWallet = styled(Typography)({
  color: '#1E22AA',
  fontSize: '10px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const TypographyAccordion = styled(Typography)({
  color: '#fff',
  marginLeft: '50px',
  fontSize: '16px',
});

export const TypographyWalletAmount = styled(Typography)({
  fontWeight: 'bold',
  color: '#21130d',
  fontSize: '16px',
});

export const StackMiniDrawer = styled(Stack)({
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  spacing: 0.5,
});

export const BoxMenu = styled(Box)({
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
});

export const BoxMenuItem = styled(Box)(({ subItem = {} }) => ({
  marginRight: '4px',
  marginLeft: '2px',
  '&:hover': {
    borderRadius: '4px',
    backgroundColor: '#4449D8',
    color: '#fff',
    '& .circle-bullet': {
      backgroundColor: '#fff',
    },
  },
}));

export const BoxItem = styled(Box)({
  marginRight: '4px',
  marginLeft: '2px',
  '&:hover': {
    borderRadius: '4px',
  },
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const MenuItems = styled(MenuItem)({
  borderRadius: '4px !important',
  marginLeft: '8px',
  marginRight: '6px',
  marginTop: '8px',
  marginBottom: '8px',
  color: 'inherit',
  fontSize: '16px',
  '&:hover': {
    borderRadius: '8px',
    backgroundColor: '#4449D8',
    color: '#fff',
    '& .circle-bullet': {
      backgroundColor: '#fff',
    },
  },
});

export const TypographyMenuItem = styled(Typography)(({ marginLeft }) => ({
  fontSize: '16px',
  marginLeft: marginLeft || '0px',
  color: 'inherit',
}));

export const TypographyMenuItemAmount = styled(Typography)({
  fontSize: '16px',
  mt: 0.5,
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'inherit',
});

export const Icon = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  width: '40px',
  height: '40px',
  margin: '0px',
  marginRight: '2px',
});

export const Avatar = styled('img')({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
});

export const TooltipContent = styled(Box)({
  margin: '8px 2px',
  padding: '6px 6px',
  backgroundColor: '#fff',
  borderRadius: '4px',
  '& .tooltip-text': {
    color: '#21130d',
  },
});

export const TextUserTooltip = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: '#21130d',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '14px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '20px',
  '&.tooltip-text': {
    color: '#21130d',
  },
});

export const TextEmailTooltip = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: '#21130d',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '10px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '20px',
  textTransform: 'none',
  '&.tooltip-text': {
    color: '#21130d',
  },
});

export const ButtonAcount = styled(IconButton)({
  width: '40px',
  height: '40px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
});

export const TextSection = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
});

export const Text = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(255, 255, 255, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '14px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '20px',
  textTransform: 'none',
  margin: '0px',
});

export const SupportingText = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(242, 244, 247, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '8px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '20px',
  textTransform: 'none',
  margin: '0px',
});

export const BoxContainerMobile = styled(Box)(({ theme }) => ({
  background:
    'linear-gradient(-175.2deg, rgba(112, 151, 217, 1) -36.92771575933705%, rgba(30, 34, 170, 1) 103.56996838617438%)',
}));

export const ListContainer = styled(List)({
  padding: '48px 16px',
  paddingBottom: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

export const ContentTitleSection = styled(Button)({
  background: '#1E22AA',
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px 12px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  width: '100%',
  gap: '8px',
  justifyContent: 'space-between',
  display: 'flex',
  justifyContent: 'space-between',
  '&:hover': {
    background: '#1A1E8F',
  },
});

export const MyInvestment = styled(Typography)({
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(231, 243, 255, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '14px',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126%',
  textTransform: 'none',
  marginLeft: '6px',
});

export const DownArrowIcon = styled(KeyboardArrowDownOutlinedIcon)({
  color: '#fff',
});

export const UpArrowIcon = styled(KeyboardArrowUpOutlinedIcon)({
  color: '#fff',
});

export const Footer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  marginTop: '0px',
  width: '100%',
  gap: '2px',
});

export const Internal = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'end',
  alignItems: 'flex-end',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
});

export const ContentActions = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  width: '100%',
  gap: '2px',
});

export const CardContainer = styled(Stack)({
  backgroundColor: '#fff',
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  flex: '1',
  height: '38px',
  width: '100%',
  margin: '8px 0px',
});

export const IconMoodOutlined = styled(MoodOutlinedIcon)({
  color: '#fff',
  width: '24px',
  height: '24px',
  margin: '0px',
});

export const ButtonReferrals = styled(Button)({
  height: '100%',
  with: '100%',
  textTransform: 'none',
  fontSize: '14px',
});

export const BoxNavigation = styled(Box)({
  position: 'fixed',
  bottom: 0,
  marginLeft: 0,
  marginRight: 0,
  zIndex: 1100,
});
