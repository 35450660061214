import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import { HeaderProducts } from '../../../components/XcalaComponents';
import { NotificationsXcala } from '../../../components/XcalaComponents/Notifications/NotificationsXcala';
import BodyDetailProduct from '../../../components/products/productsDetails/BodyDetailProduct';
import useModal from '../../../hooks/custom/useModal';
import { useGetExchangeRate } from '../../../hooks/useGetExchangeRate';
import useGetPriceRange from '../../../hooks/useGetPriceRange';
import useGetSecurityDividends from '../../../hooks/useGetSecurityDividends';
import { AddCurrency } from '../../../utils/addCostEffectivemess';
import { getRiskWarning } from '../../../utils/getRiskWarning';
import { onRefresh } from '../../../utils/refreshPage';
import {
  getCurrentDateFormatYYYYMMDD,
  getFirstElement,
  numberWithPoint,
  setMonthsDateFormatYYMMDD,
} from '../../../utils/utils';

const ProductsDetails = () => {
  const [active, setActive] = useState(12);
  const [currency, setCurrency] = React.useState('dolares');
  const [chartData, setChartData] = React.useState([]);
  const { isShowing, toggle } = useModal();
  const navigate = useNavigate();
  const { securitySelected } = useSelector(state => state.simulation);
  const user = useSelector(state => state.auth.user);

  const custom = JSON.parse(securitySelected.Custom);
  const risk = custom.preferences.riesgo.description;
  const motivations = getFirstElement(custom.preferences.motivaciones);
  const interests = getFirstElement(custom.preferences.intereses);
  const motivationsId = custom.preferences.motivaciones[0].id;
  const interestsId = custom.preferences.intereses[0].id;
  const description = custom.fund_details.description_fund;
  const riskID = custom.preferences.riesgo;
  const showRiskWarning = getRiskWarning(user['custom:profile'], riskID.id);

  const onlyNumber = custom.fund_details.min_investment_amount.replace(/[^\d]/g, '');

  const conditions = [];
  if (custom.fund_details.min_investment_amount) {
    conditions.push({
      data: 'Monto mínimo de inversión:',
      value: `$${numberWithPoint(Number.parseInt(onlyNumber))}`,
    });
  }
  if (custom.fund_details.Currency) {
    conditions.push({
      data: 'Moneda:',
      value: custom.fund_details.Currency,
    });
  }
  if (custom.fund_details.exit_fee) {
    conditions.push({
      data: 'Plazo de aporte:',
      value: custom.fund_details.exit_fee,
    });
  }
  if (custom.fund_details.dividend_payment) {
    conditions.push({
      data: 'Paga dividendos:',
      value: custom.fund_details.dividend_payment,
    });
  }
  if (custom.fund_details.plazo_rescates) {
    conditions.push({
      data: 'Plazo de rescate:',
      value: `${custom.fund_details.plazo_rescates}`,
    });
  }

  const documents = [];

  const documentTypes = {
    Fact_Sheet: 'Brochure',
    Reglamento: 'Reglamento',
    Folleto_Regulatorio: 'Folleto Informativo',
  };

  if (custom.documents) {
    for (const [key, value] of Object.entries(custom.documents)) {
      const documentType = documentTypes[key];
      if (documentType) {
        documents.push({
          data: documentType,
          value,
        });
      }
    }
  }

  const [
    doGetPriceRange,
    { data: dataPriceRange, error: errorGetPriceRange, loading: loadingGetPriceRange },
  ] = useGetPriceRange();
  const successGetPriceRange = dataPriceRange?.getPriceRange || [];
  const [doGetSecurityDividends, { data: dataSecurityDividends }] = useGetSecurityDividends();
  const successGetSecurityDividends = dataSecurityDividends?.getSecurityDividends;

  const { data: dataGetExchangeRate, loading: loadingGetExchangeRate } = useGetExchangeRate();
  const successGetExchangeRate = dataGetExchangeRate?.getExchangeRate[0];

  const onChangeMonths = months => {
    setActive(months);
    doGetPriceRange({
      variables: {
        data: {
          securityId: securitySelected.ID,
          from: setMonthsDateFormatYYMMDD(-months),
          to: getCurrentDateFormatYYYYMMDD(),
        },
      },
    });
  };

  const updateChartData = successGetPriceRange => {
    const convertedData = successGetPriceRange.map(value => {
      return {
        ...value,
        ClosePrice: value.ClosePrice / successGetExchangeRate?.Rate,
      };
    });
    setChartData(convertedData);
  };

  const handleInvestment = id => {
    navigate('/simulator');
    // const security = {
    //   ID: investmentSelected.id,
    //   Name: investmentSelected.name,
    //   Custom: investmentSelected.custom,
    // };
    // dispatch(setSecuritySelected(security));
    // navigate("/simulator");
  };

  const handleSimulation = id => {
    navigate('/simulator');
    // dispatch(setRescueType("Rescatartodo"));
    // dispatch(setMyInvestmentSelected(investmentSelected));
    // navigate("/rescue");
  };

  const fileAs = {
    currency: AddCurrency(securitySelected.Name),
    fileAs: 'FileAsName',
    fileAsComplete: securitySelected.Name.slice(4),
    risk: risk,
    motivation: motivations.split('-')[0],
    motivationId: motivationsId,
    interests: interests,
    interestsId: interestsId,
    products: true,
    profit: securitySelected.profit,
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    onChangeMonths(12);
    doGetSecurityDividends({
      variables: {
        securityId: securitySelected.ID,
      },
    });
    if (currency === 'dolares' && custom.fund_details.Currency === 'Dólar') {
      updateChartData(successGetPriceRange);
    }
  }, []);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (currency === 'dolares' && custom.fund_details.Currency === 'Dólar') {
      updateChartData(successGetPriceRange);
    }
  }, [successGetPriceRange, successGetExchangeRate]);

  if (successGetPriceRange.length < 0 || loadingGetExchangeRate) {
    return <Spinner />;
  }
  if (errorGetPriceRange) {
    return (
      <NotificationsXcala
        activation={!isShowing}
        message='Lo sentimos, ha ocurrido un error inesperado....'
        nameBtnTwo='Enviar error'
        toggle={toggle}
        refresh={onRefresh}
        error={errorGetPriceRange}
      />
    );
  }

  return (
    <>
      <HeaderProducts
        handleInvestMore={handleInvestment}
        handleRescueInvestment={handleSimulation}
        fileAs={fileAs}
        simulation='Simular'
        investmentMore='Invertir'
        // visible={visible}
        // statusDividendFinfolio={statusDividendFinfolio}
        // handleOpen={handleOpen}
        // handleClose={handleClose}
      />

      <BodyDetailProduct
        description={description}
        conditions={conditions}
        onChangeMonths={onChangeMonths}
        active={active}
        loadingGetPriceRange={loadingGetPriceRange}
        // data de chart detalles de productos
        currency={currency}
        custom={custom}
        chartData={chartData}
        dividends={successGetSecurityDividends}
        showLabels={true}
        successGetPriceRange={successGetPriceRange}
        setCurrency={setCurrency}
        updateChartData={updateChartData}
        documents={documents}
        showRiskWarning={showRiskWarning}
        handleInvestment={handleInvestment}
        handleSimulation={handleSimulation}
      />
    </>
  );
};

export default ProductsDetails;
