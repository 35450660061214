import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ModalErrorFetch from '../../../components/UI/ModalErrorFetch';
import { CompleteOmboardingFour } from '../../../components/completeOmborarding/CompleteOmboardingFour';
import { CompleteOmboardingOne } from '../../../components/completeOmborarding/CompleteOmboardingOne';
import { CompleteOmboardingTree } from '../../../components/completeOmborarding/CompleteOmboardingTree';
import { CompleteOmboardingTreeForeign } from '../../../components/completeOmborarding/CompleteOmboardingTreeForeign';
import { CompleteOmboardingTwo } from '../../../components/completeOmborarding/CompleteOmboardingTwo';
import useModal from '../../../hooks/custom/useModal';
import { onRefresh } from '../../../utils/refreshPage';

export const alreadyDone = (currentStep, newStep) => {
  return currentStep <= newStep ? true : false;
};

export const NewCompleteRegisterStepper = ({
  stepper,
  steppercolor,
  setSteppercolor,
  setstepper,
  foreign,
  setForeign,
}) => {
  const { isShowing, toggle } = useModal();
  // const [foreign, setForeign] = useState(false);
  const onBoardingData = useSelector(state => state.onboarding.data);
  // const [stepper, setstepper] = useState(props.step ? props.step : 1);
  // const [steppercolor, setSteppercolor] = useState({
  //   comp1: alreadyDone(1, props.step),
  //   comp2: alreadyDone(2, props.step),
  //   comp3: alreadyDone(3, props.step),
  //   comp4: alreadyDone(4, props.step),
  //   comp5: alreadyDone(5, props.step),
  // });
  const [errors, setErrors] = useState({
    title: '',
    subtitle: '',
    routeName: '',
  });

  const getValue = name => {
    const obj = onBoardingData?.detail?.find(item => item.Name === name);
    return obj?.Value ? obj.Value : '';
  };

  const messageCustom = (
    <Typography>
      Hemos tenido problemas para validar tu información, por favor escribenos un email a
      <Typography component='span' sx={{ color: '#1E23AA', fontWeight: '500' }}>
        {' '}
        contacto@xcala.com{' '}
      </Typography>
      para poder solucionar este inconveniente
    </Typography>
  );

  useEffect(() => {
    setForeign(getValue('custom:expiration'));
  }, [stepper]);

  const Error = (
    <ModalErrorFetch
      message={messageCustom}
      open={!isShowing}
      onClose={toggle}
      refresh={onRefresh}
    />
  );

  return (
    <>
      {stepper === 1 ? (
        <CompleteOmboardingOne
          setstepper={setstepper}
          stepper={stepper}
          setSteppercolor={setSteppercolor}
        />
      ) : (
        ''
      )}

      {stepper === 2 ? (
        <CompleteOmboardingTwo
          setstepper={setstepper}
          stepper={stepper}
          setSteppercolor={setSteppercolor}
        />
      ) : (
        ''
      )}

      {stepper === 3 && !foreign ? (
        <CompleteOmboardingTree
          setstepper={setstepper}
          stepper={stepper}
          setSteppercolor={setSteppercolor}
          setErrors={setErrors}
        />
      ) : (
        ''
      )}

      {stepper === 3 && foreign ? <CompleteOmboardingTreeForeign /> : ''}

      {stepper === 4 ? (
        <CompleteOmboardingFour
          setstepper={setstepper}
          stepper={stepper}
          setSteppercolor={setSteppercolor}
        />
      ) : (
        ''
      )}

      {stepper === 5 ? { Error } : ''}
    </>
  );
};
