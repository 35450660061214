import { Stack, styled } from '@mui/material';

const WrapperBackgroundBanner = ({ $minHeigth, children }) => {
  const BackgroundColor = styled(Stack)({
    zIndex: 0,
    backgroundColor: 'rgba(30, 34, 170, 1)',
    borderRadius: '0px 0px 16px 16px',
    position: 'relative',
    left: '0px',
    top: '0px',
    alignSelf: 'stretch',
    width: '100%',
    minWidth: '90%',
    minHeight: $minHeigth,
    padding: '0px',
    margin: '0px',
  });

  return (
    <>
      <BackgroundColor paddingBottom={{ xs: '2rem', md: '' }}>{children}</BackgroundColor>
    </>
  );
};

export default WrapperBackgroundBanner;
