import { Typography } from '@mui/material';
import { TituloContentt } from './SimulationMuiComponents';

export const ContributionsSectionTitleForm = ({ textTitle }) => {
  return (
    <TituloContentt>
      <Typography
        sx={{
          color: 'rgba(24, 40, 73, 1)',
          fontStyle: 'normal',
          fontFamily: 'Museo',
          fontWeight: '300',
          fontSize: 'clamp(0.875rem, 0.3333rem + 4.4444vw, 1.5rem)',
        }}>
        {textTitle}
      </Typography>
    </TituloContentt>
  );
};
