import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../hooks/custom/useModal';
import { useCreateRescueTransaction } from '../../../hooks/useCreateRescueTransaction';
import { getFormattedDateDDMMYY, getFullDate } from '../../../utils/dates';
import { onRefresh } from '../../../utils/refreshPage';
import { numberWithPoint } from '../../../utils/utils';
import { ButtonsPrimary, ButtonsTertiary } from '../../ComponentApp/buttoms';
import ModalErrorFetch from '../../UI/ModalErrorFetch';
import {
  ButtonBack,
  CheckBoxOutlineBlank,
  ContentCheckBox,
  DeclarationSpan,
  DescriptionText,
  DividerCustom,
  ImportanText,
  InstallmentNumbers,
  ResItemCards,
  RescueDescription,
  RescuePeriod,
  RescueSolutionSection,
  RsContent,
  TermsAndConditions,
  TextBtnBack,
  TextDeclaration,
  TitleItemCards,
  WarninTitle,
  WarningAmber,
  WarningMessage,
} from '../../portfolio/rescuePortfolio/CardsResultRescue';
import ReceivedRescueFunds from './ReceivedRescueFunds';

const CardsResultRescueFund = ({
  rescueAmount,
  cuotas,
  handleGoToConfirm,
  /*   investmentSelected, */
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [receivedRequest, setreceivedRequest] = useState(true);
  const [responseErrorRescue, setResponseErrorRescue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isShowing, toggle } = useModal();
  const { user } = useSelector(state => state.auth);
  const { investmentSelected, investmentData } = useSelector(state => state.investments);

  const navigate = useNavigate();
  const dateDDMMYY = getFormattedDateDDMMYY();
  const custom = JSON.parse(investmentSelected.custom);
  const days = custom.fund_details.plazo_rescates;
  const date = getFullDate();
  const [createRescueTransaction, { loading: loadingRescue, error: errorRescue }] =
    useCreateRescueTransaction();

  const OpenreceviedRequest = async () => {
    const data = {
      amount: Number.parseInt(investmentData.recueAmountValue),
      name: `${user.name} ${user['custom:paternal-surname']}`,
      securityId: investmentSelected.id,
      securityName: investmentSelected.name,
      units: +Number.parseFloat(investmentData.cuotasValue).toFixed(4),
    };
    try {
      if (!isSubmitting) {
        setIsSubmitting(true);
        const response = await createRescueTransaction({
          variables: {
            data: data,
          },
        });
        setResponseErrorRescue(response.data.createRescueTransaction);
        setIsSubmitting(false);
        setreceivedRequest(prevState => !prevState);
      }
    } catch (err) {
      setShowModal(!showModal);
    }
  };

  const handleChangeCheckBox = e => {
    setIsChecked(e.target.checked);
  };

  const openTC = () => {
    const { REACT_APP_CONTRACT_PORTFOLIO } = process.env;
    const win = window.open(REACT_APP_CONTRACT_PORTFOLIO, '_blank');
    win.focus();
  };

  return (
    <>
      {showModal && (
        <ModalErrorFetch
          message='Lo sentimos, algo no salio bien acá....'
          open={showModal}
          onClose={setShowModal}
          refresh={onRefresh}
        />
      )}

      {receivedRequest ? (
        <Stack
          sx={{
            margin: '0px auto',
            width: '90%',
            maxWidth: '1380px',
            marginTop: '30px',
          }}>
          <RescueSolutionSection sx={{ padding: 'clamp(1rem, -0.875rem + 8vw, 2.125rem)' }}>
            <RsContent>
              <RescueDescription
                sx={{
                  padding: '24px clamp(1rem, -0.6667rem + 7.1111vw, 2rem)',
                }}>
                <InstallmentNumbers>
                  <TitleItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    Monto solicitado:
                  </TitleItemCards>
                  <ResItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    $ {numberWithPoint(rescueAmount)}
                  </ResItemCards>
                </InstallmentNumbers>

                <InstallmentNumbers>
                  <TitleItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    Número de cuotas:
                  </TitleItemCards>
                  <ResItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    {cuotas}
                  </ResItemCards>
                </InstallmentNumbers>

                <RescuePeriod>
                  <TitleItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    Fecha de solicitud:
                  </TitleItemCards>
                  <ResItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    {dateDDMMYY}
                  </ResItemCards>
                </RescuePeriod>

                <RescuePeriod>
                  <TitleItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    Plazo de rescate:
                  </TitleItemCards>
                  <ResItemCards
                    sx={{
                      fontSize: 'clamp(0.875rem, 0.4583rem + 1.7778vw, 1.125rem)',
                    }}>
                    {days}
                  </ResItemCards>
                </RescuePeriod>
              </RescueDescription>

              <WarningMessage>
                <WarninTitle>
                  <WarningAmber src='/assets/images/perfiles/warning-amber.svg' />
                  <ImportanText>Importante:</ImportanText>
                </WarninTitle>
                <ul style={{ margin: '0px', padding: '0px 18px' }}>
                  <li>
                    <DescriptionText>
                      El plazo de rescate comienza a correr el día hábil siguiente si tu solicitud
                      fue realizada antes de las 4:00 PM y el día hábil subsiguiente si la realizas
                      luego de esa hora.
                    </DescriptionText>
                  </li>
                  <li>
                    <DescriptionText>
                      Este depósito es en Dólares, por lo que el monto solicitado será transformado
                      a pesos el día del depósito de acuerdo al tipo de cambio correspondiente a la
                      fecha.
                    </DescriptionText>
                  </li>
                  <li>
                    <DescriptionText>
                      El monto final a ser rescatado puede variar según el valor cuota del fondo al
                      momento de procesar el rescate.
                    </DescriptionText>
                  </li>
                </ul>
              </WarningMessage>

              <DividerCustom variant='inset' />

              <TermsAndConditions>
                <ContentCheckBox gap={1}>
                  <Stack marginTop={{ xs: '8px', md: '0px' }}>
                    <CheckBoxOutlineBlank checked={isChecked} onChange={handleChangeCheckBox} />
                  </Stack>
                  <TextDeclaration>
                    Declaro entender los{' '}
                    <DeclarationSpan
                      variant='span'
                      component={'span'}
                      sx={{ cursor: 'pointer' }}
                      onClick={openTC}>
                      términos y condiciones del rescate
                    </DeclarationSpan>{' '}
                    que estoy a punto de solicitar
                  </TextDeclaration>
                </ContentCheckBox>
              </TermsAndConditions>

              <Stack
                justifyContent={'center'}
                direction={{ md: 'row', xs: 'column' }}
                width={{ md: '60%', xs: '90%' }}
                gap={2}
                sx={{ margin: '26px 0px 0px 0px' }}>
                <ButtonsTertiary
                  fullWidth
                  onClick={() => navigate('/myprofilerisk')}
                  name={'No por ahora'}
                />
                <ButtonsPrimary
                  fullWidth
                  disabled={!isChecked || loadingRescue || isSubmitting}
                  onClick={OpenreceviedRequest}
                  name={isSubmitting ? 'Solicitando' : ' Confirmar'}
                />
              </Stack>
            </RsContent>
          </RescueSolutionSection>
          <Stack
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '5rem',
              marginBottom: '2rem',
            }}>
            <ButtonBack>
              <TextBtnBack onClick={() => handleGoToConfirm()}> Volver Atrás </TextBtnBack>
            </ButtonBack>
          </Stack>
        </Stack>
      ) : (
        <ReceivedRescueFunds
          responseErrorRescue={responseErrorRescue}
          cuotas={cuotas}
          rescueAmount={rescueAmount}
          investmentSelected={investmentSelected}
          dateDDMMYY={dateDDMMYY}
          days={days}
          date={date}
        />
      )}
    </>
  );
};

export default CardsResultRescueFund;
