import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Stack, Typography } from "@mui/material";
import React from "react";

const Helpertext = ({ message, errormessage }) => {
  if (errormessage) {
    return (
      <Stack
        justifyContent={"flex-start"}
        direction={"row"}
        alignItems={"center"}
        sx={{ color: "rgb(211, 47, 47)" }}
      >
        <CloseIcon sx={{ fontSize: "16px" }} />
        <Typography sx={{ fontSize: "14px", color: "inherit" }}>
          {errormessage}
        </Typography>
      </Stack>
    );
  }
  if (message) {
    return (
      <Stack
        justifyContent={"flex-start"}
        direction={"row"}
        alignItems={"center"}
        sx={{ color: "#49a197" }}
      >
        <DoneIcon sx={{ fontSize: "16px" }} />
        <Typography sx={{ fontSize: "14px", color: "inherit" }}>
          {message}
        </Typography>
      </Stack>
    );
  }
  return null;
};

export default Helpertext;
