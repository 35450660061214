import { Button, Stack, styled } from '@mui/material';
import { ButtonsPrimary, ButtonsSecundary } from '../../../ComponentApp/buttoms';

const BtnContent = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '50%',
  minWidth: '200px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  height: '50px',
});

const BtnPrimary = styled(Button)({
  flex: '1',
  height: '50px',
  backgroundColor: 'rgba(30, 34, 170, 1)',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(10, 14, 150, 1)',
  },
});

const BtnSecundary = styled(Button)({
  flex: '1',
  height: '50px',
  margin: '0px',
  color: 'rgba(30, 34, 170, 1)',
  borderColor: 'rgba(30, 34, 170, 1)',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(30, 34, 170, 1)',
    color: 'rgba(244, 244, 251, 1)',
  },
});

export const BtnGroup = ({ goToSimulator, goToInvestment }) => {
  return (
    <BtnContent gap={3}>
      <ButtonsSecundary name='Simular' onClick={goToSimulator} fullWidth />
      {/* <BtnSecundary onClick={goToSimulator} fullWidth variant='outlined'>
        Simular
      </BtnSecundary> */}
      <ButtonsPrimary name='Invertir' onClick={goToInvestment} fullWidth />
      {/* <BtnPrimary onClick={goToInvestment} fullWidth variant='contained'>
        Invertir
      </BtnPrimary> */}
    </BtnContent>
  );
};
