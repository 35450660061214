import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const InputSelectFiled = ({ nameFiled, selet, funtion, isEditPersonalData }) => {
  const genders = useSelector(state => state.constants.genders);
  const nationalities = useSelector(state => state.constants.nationalities);
  const moneyIncomes = useSelector(state => state.constants.moneyIncomes);
  const moneyOrigin = useSelector(state => state.constants.moneyOrigin);
  const civilStatus = useSelector(state => state.constants.civilStatus);
  const professions = useSelector(state => state.constants.professions);
  const [menuItems, setMenuItems] = useState([]);
  const [defaulSelect, setDefaulSelect] = useState('');

  useEffect(() => {
    try {
      if (nameFiled === 'Género') setMenuItems(genders);
      if (nameFiled === 'Nacionalidad') setMenuItems(nationalities);
      if (nameFiled === 'Ingresos mensuales') setMenuItems(moneyIncomes);
      if (nameFiled === 'Origen de fondos') setMenuItems(moneyOrigin);
      if (nameFiled === 'Estado civil') setMenuItems(civilStatus);
      if (nameFiled === 'Profesión') setMenuItems(professions);
    } finally {
      const aux = menuItems?.find(item => item.name === selet);
      setDefaulSelect(aux);
    }
  }, [selet, menuItems, nameFiled]);

  return (
    <FormControl
      variant='filled'
      sx={{
        width: { sm: '48%' },
        flex: { sm: '1 1 308px', xs: '1 1 100%' },
        boxSizing: 'border-box',
        bgcolor: 'rgba(255, 255, 255, 1)',
        'label[data-shrink=false]+.MuiInputBase-formControl .css-10botns-MuiInputBase-input-MuiFilledInput-input':
          {
            background: '#fff',
          },
      }}>
      {nameFiled === 'Género' ? (
        <>
          <InputLabel id='Genero'>{nameFiled}</InputLabel>
          <Select
            disabled={!isEditPersonalData}
            labelId='Genero'
            name='gender'
            value={menuItems.length > 0 && defaulSelect ? defaulSelect : ''}
            onChange={e => funtion(e)}>
            {menuItems?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : nameFiled === 'Nacionalidad' ? (
        <>
          <InputLabel id='Nacionalidad'>{nameFiled}</InputLabel>
          <Select
            disabled={!isEditPersonalData}
            labelId='Nacionalidad'
            name='Nacionalidad'
            value={menuItems.length > 0 && defaulSelect ? defaulSelect : ''}
            onChange={e => funtion(e)}>
            {menuItems?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : nameFiled === 'Ingresos mensuales' ? (
        <>
          <InputLabel id='Ingresos mensuales'>{nameFiled}</InputLabel>
          <Select
            disabled={!isEditPersonalData}
            labelId='Ingresos mensuales'
            name='Ingresos_mensuales'
            value={menuItems.length > 0 && defaulSelect ? defaulSelect : ''}
            onChange={e => funtion(e)}>
            {menuItems?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : nameFiled === 'Origen de fondos' ? (
        <>
          <InputLabel id='Origen de fondos'>{nameFiled}</InputLabel>
          <Select
            disabled={!isEditPersonalData}
            labelId='Origen de fondos'
            name='Origen_de_fondos'
            value={menuItems.length > 0 && defaulSelect ? defaulSelect : ''}
            onChange={e => funtion(e)}>
            {menuItems?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : nameFiled === 'Estado civil' ? (
        <>
          <InputLabel id='Estado civil'>{nameFiled}</InputLabel>
          <Select
            disabled={!isEditPersonalData}
            labelId='Estado civil'
            name='estado_civil'
            value={menuItems.length > 0 && defaulSelect ? defaulSelect : ''}
            onChange={e => funtion(e)}>
            {menuItems?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : nameFiled === 'Profesión' ? (
        <>
          <InputLabel id='Profesion'>{nameFiled}</InputLabel>
          <Select
            disabled={!isEditPersonalData}
            labelId='Profesion'
            name='Profesion'
            value={menuItems.length > 0 && defaulSelect ? defaulSelect : ''}
            onChange={e => funtion(e)}>
            {menuItems?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : null}
    </FormControl>
  );
};
