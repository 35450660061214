import { Stack } from '@mui/material';
import useModal from '../../hooks/custom/useModal';
import useGetBankAccountTypes from '../../hooks/useGetBankAccountTypes';
import useGetBanks from '../../hooks/useGetBanks';
import { onRefresh } from '../../utils/refreshPage';
import { ButtonsTertiary } from '../ComponentApp/buttoms/ButtonsXcala';
import Spinner from '../Spinner';
import ModalErrorFetch from '../UI/ModalErrorFetch';
import {
  ButtonsAcount,
  CardContent,
  DivisionSectionFrom,
  SectionInteractive,
  TextButtons,
  TextCuent,
  TitleSectionFrom,
} from '../XcalaComponents/MyNewAccount';
import { InputSelectBank } from './InputSelectBank';

export const FormBankSection = ({
  isEditBankData,
  bankField,
  isBankData,
  loadingUserBank,
  handleEditWithoutBankData,
  handleOpenEditBankData,
  handleChangeBankFields,
  errorRegisterUserBank,
  loadingRegisterUserBank,
  //copyText,
  //handleCopyLink,
  accountNumberError,
}) => {
  const { data, error: errorGetBanks, loading: loadingGetBanks } = useGetBanks();

  const {
    data: dataBankTypes,
    error: errorGetBankAccountTypes,
    loading: loadingGetBankAccountTypes,
  } = useGetBankAccountTypes();

  const successGetBankAccountTypes = dataBankTypes?.getBankAccountTypes;

  const successGetBanks = data?.getBanks;

  const { isShowing, toggle } = useModal();

  if (loadingUserBank || loadingGetBanks || loadingGetBankAccountTypes) {
    return <Spinner />;
  }

  if (errorGetBanks || errorGetBankAccountTypes || errorRegisterUserBank) {
    return (
      <ModalErrorFetch
        message='Me temo que tendras que hacerlo de nuevo.... algo salio mal'
        error={errorGetBanks || errorGetBankAccountTypes || errorRegisterUserBank}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  }

  return (
    <SectionInteractive sx={{ width: { xs: '90%', md: '30%' } }}>
      <CardContent>
        <DivisionSectionFrom
          isEdit={isEditBankData}
          handleOpenEdit={handleOpenEditBankData}
          textTitle={'Datos bancarios'}
        />

        {loadingRegisterUserBank ? (
          <Spinner />
        ) : isBankData ? (
          <Stack
            sx={{
              gap: '32px',
            }}>
            <InputSelectBank
              isEdit={isEditBankData}
              nameFiled={'Banco'}
              selet={bankField.bankSelected}
              dataBanks={successGetBanks}
              funtion={handleChangeBankFields}
            />
            <InputSelectBank
              isEdit={isEditBankData}
              nameFiled={'Tipo de cuenta'}
              selet={bankField.accountTypeSelected}
              dataBanks={successGetBankAccountTypes}
              funtion={handleChangeBankFields}
            />
            <InputSelectBank
              isEdit={isEditBankData}
              nameFiled={'No. de cuenta'}
              selet={bankField.number}
              funtion={handleChangeBankFields}
              accountNumberError={accountNumberError}
            />
          </Stack>
        ) : (
          <Stack
            sx={{
              gap: '32px',
            }}>
            <TextCuent>Aun no registras tu cuenta</TextCuent>
            <ButtonsAcount onClick={handleEditWithoutBankData}>
              <TextButtons>Agregar mi cuenta</TextButtons>
            </ButtonsAcount>
          </Stack>
        )}
      </CardContent>
      <CardContent gap={'32px'}>
        <TitleSectionFrom sx={{ textAlign: { md: 'left', xs: 'center' }, marginTop: '8px' }}>
          Referidos Xcala
        </TitleSectionFrom>
        <ButtonsTertiary /* name={copyText} */
          name='Próximamente' /* onClick={handleCopyLink} */
          fontSize='16px'
        />
      </CardContent>
    </SectionInteractive>
  );
};
