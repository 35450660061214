import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Spinner from '../Spinner';
import CarouselItemDashboard from './CarouselItemDashboard';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const CarouselDashboard = ({ carouselImg, isLoadingCarouselImg }) => {
  if (isLoadingCarouselImg) {
    return <Spinner />;
  }
  return (
    <Box width='100%' height='100%'>
      <Swiper
        className='swipper-dashboard'
        modules={[Autoplay, Pagination, Navigation]}
        effect='fade'
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}>
        {carouselImg.map(item => (
          <SwiperSlide key={item.buttonText2}>
            <CarouselItemDashboard item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default CarouselDashboard;
