import emailjs from '@emailjs/browser';
import { Box, Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import capitalize from '../../utils/capitalize';
import { onRefresh } from '../../utils/refreshPage';
import { ButtonsPrimary } from '../ComponentApp/buttoms';
import SignInSignUpLayout, {
  BackgroundImg,
  ImgGreen,
  ImgYellow,
} from '../Layout/SignInSignUpLayout/SignInSignUpLayout';
import { ButtonXcalaGray } from './ButtonXcala';

const FallbackCustom = ({ error }) => {
  const { user } = useSelector(state => state.auth);
  const backgroundQuery = 'clamp(55%, 65vh, 100%)';
  const maxWidthContainer = '800px';
  const [disabledButton, setDisabledButton] = useState(false);

  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const navigator = window.navigator.appVersion;
  const platform = window.navigator.platform;
  const paternalName = user?.['custom:paternal-surname'] || '';
  const maternalName = user?.['custom:maternal-surname'] || '';
  const fullName =
    user && `${capitalize(user.name)} ${capitalize(paternalName)} ${capitalize(maternalName)}`;
  const currentTime = new Date().toLocaleString();
  const navigate = useNavigate();
  const location = useLocation();

  const report = {
    typeError: 'error boundary',
    name: fullName || '',
    securityId: user?.username || 'usuario no registrado',
    email: user?.email || '',
    currentTime: currentTime,
    errorMessage: error?.message,
    location: location?.pathname,
    navigator: navigator,
    platform: platform,
  };

  const handleSubmitReport = () => {
    setDisabledButton(true);
    emailjs
      .send(
        'service_7utjird',
        'template_v0a6xsc',
        {
          message: JSON.stringify(report),
          from_name: fullName,
          reply_to: user?.email,
        },
        'EFAYuCkTVHLBFWEFQ',
      )
      .then(
        result => {
          toast.success('Reporte enviado con exito');
        },
        error => {
          toast.error('Ups ha ocurrido un error');
        },
      );
    setTimeout(() => {
      navigate('/profilerisk');
      onRefresh();
    }, 2000);
  };

  return (
    <>
      <Stack
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          minHeight: '100vh',
          position: 'relative',
          backgroundColor: { sm: '#1E22AA', md: '#fff' },
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Stack
          sx={{
            boxSizing: 'border-box',
            width: '100%',
            minHeight: '100vh',
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: {
              sm: '#1E22AA',
              md: '#fff',
            },
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Stack
            sx={{
              background: '#fff',
              boxSizing: 'border-box',
              borderRadius: '24px',
              padding: '24px',
              position: 'relative',
              boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.15)',
              zIndex: 4,
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '2rem',
            }}
            width={{ xs: '90%', sm: '80%', md: '80%' }}
            marginTop='clamp(1.375rem, 14.3617rem + -21.2766vw, 1rem)'
            maxWidth={maxWidthContainer}>
            <Stack width={'80%'}>
              <Typography
                sx={{
                  color: '#1E22AA',
                  fontSize: '24px',
                  fontWeight: 700,
                  textAlign: 'center',
                }}>
                Ups! ha ocurrido un error. Algo no ha funcionado bien y tu solicitud ha fallado
              </Typography>
            </Stack>
            <Stack marginTop={3}>
              <Box
                alt='Under development'
                component='img'
                src={'/assets/images/errorCustomFallback.svg'}
                sx={{
                  height: 'auto',
                  maxWidth: '100%',
                  width: 300,
                }}
              />
            </Stack>
            <Stack width={'80%'} marginTop={2}>
              <Typography sx={{ textAlign: 'center', fontSize: '16px', fontWeight: 400 }}>
                Por favor, reintenta o comunícate directamente con nosotros para contarnos qué te
                pasó, vía el chat o directamente a{' '}
                <Typography component={'span'} fontSize={'inherit'} fontWeight={600}>
                  contacto@xcala.com
                </Typography>
              </Typography>
            </Stack>

            <Stack marginTop={2}>
              <ButtonsPrimary
                disabled={disabledButton}
                name={'Continuar'}
                onClick={handleSubmitReport}
              />
            </Stack>
          </Stack>

          <BackgroundImg
            width={{ xs: '100%', sm: '100%', md: backgroundQuery }}
            borderRadius={{ md: '0px 24px 24px 0px' }}
          />
          <ImgYellow />
          <ImgGreen />
        </Stack>
      </Stack>
    </>
  );
};

export default FallbackCustom;
