import { gql, useQuery } from '@apollo/client';

const GET_CASH_BALANCE_PORTFOLIO = gql`
  query GetCashBalancePortfolio {
    getCashBalancePortfolio {
      totalAmount
      formattedAmount
      transitAmount
      balance
    }
  }
`;
{
  /*Muestra saldo en efectivo(monto total - monto en tránsito)*/
}
export const useGetCashBalancePortfolio = () => {
  return useQuery(GET_CASH_BALANCE_PORTFOLIO, { fetchPolicy: 'no-cache' });
};
