import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSimulationSelected } from '../../redux/simulation';
import { ButtonsPrimary } from '../ComponentApp/buttoms';

const Onboarding = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const navigate = useNavigate();

  React.useState(() => {
    dispatch(setSimulationSelected(null));
  }, []);

  if (
    user?.['custom:checkid-sign-doc'] &&
    user['custom:checkid-sign-doc'] === 'signature_success'
  ) {
    return null;
  } else {
    return (
      <Stack
        spacing={3}
        sx={{
          backgroundColor: '#eff6fc',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0px 0px 12px rgba(61, 64, 75, 0.18)',
        }}
        direction={{ md: 'row', xs: 'column' }}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Stack width={{ md: '60%', xs: '100%' }}>
          <Typography
            sx={{
              fontSize: {
                md: '1.9rem',
                xs: '1.3rem',
                color: '#1e22aa',
                fontFamily: 'Poppins',
              },
            }}>
            ¿Aún no has completado tu registro?
          </Typography>
          <Typography sx={{ mt: 2, fontSize: '18px', color: '#404040' }}>
            Antes de realizar tu primera inversión necesitamos conocer un poco más de ti. Completa
            tu registro y empieza a invertir ahora
          </Typography>
        </Stack>

        <Stack width={{ md: '20%', xs: '100%' }}>
          <ButtonsPrimary
            name={'Completar registro'}
            onClick={() => navigate('/completeregistration')}
          />
        </Stack>
      </Stack>
    );
  }
};

export default Onboarding;
