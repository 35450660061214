import { Chip, styled } from '@mui/material';
import { useEffect, useState } from 'react';

const motivationObj = [
  {
    label: 'Hacer crecer mi plata',
    icon: 'assets/images/Symbol/motivaciones/icon-hacer.svg',
  },
  {
    label: 'Ganar con propósito e impacto',
    icon: 'assets/images/Symbol/motivaciones/icons-ganar.svg',
  },
  {
    label: 'Generar ingreso extra',
    icon: 'assets/images/Symbol//motivaciones/icono-generar.svg',
  },
];

const ContentLabel = styled(Chip)({
  backgroundColor: 'rgba(231, 243, 255, 1)',
  display: 'flex',
  color: 'rgba(85, 118, 209, 1)',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2.421052932739258px 4.263158798217773px',
  height: '23px',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '12px',
  '&:hover': {
    backgroundColor: 'rgba(176, 194, 212, 1)',
    color: 'rgba(85, 118, 209, 1)',
  },
});

const AvatarLabel = styled('img')({
  width: '16px',
  height: '16px',
  objectFit: 'contain',
});

export const MotivationLabel = ({ motivation, motivationsId }) => {
  return (
    <>
      <ContentLabel
        label={motivation}
        avatar={
          <AvatarLabel
            src={`/assets/images/iconProducts/${motivationsId}.svg`}
            style={{ width: '16px', height: '16px' }}
            sx={{
              fontSize: '8px',
              lineHeight: '8px',
              marginTop: '2px',
            }}
          />
        }
      />
    </>
  );
};
